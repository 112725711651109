import React, {Fragment} from "react";

const AddButton = ({ id=0 , name = "Add Button", event}) => {

    return (
        
        <button id={id} onClick={event}>{name}</button>
        
    );
};

export default AddButton;

