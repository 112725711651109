import React,{useEffect, useState } from 'react';
// imports related to DND
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import {deletePageLink} from '../redux/actions/pageActions';
import {useDispatch} from 'react-redux';


const ListButtons = ({ ButtonList, pageid}) => {

   const dispatch = useDispatch();
   //const {pageId} = pageid;
   const [childValue, setChildValue] = useState(ButtonList);

   useEffect(() => {
      console.log('child changed')
      setChildValue(ButtonList)
      console.log(ButtonList)
    }, [ButtonList])

   // const handleDelete = (id) => () => {
   //    dispatch(deleteLink(id));
   //    console.log(id)
   //  }

   const [isClick, setIsClick] = useState(0);

   const [formData,setFormData] = useState({
      linkId:'',
      pageId: pageid,
   });


  const {linkId, pageId} =formData;
  const data = {linkId, pageId};

   useEffect(() => {
      // do your stuff here when the state of testState is updated
      console.log('formData',formData);
      console.log('data',data);
      //dispatch(deletePageLink(data));
      if(isClick==1) {
         dispatch(deletePageLink(data));
      }
   }, [formData])
 

    const handleClick = (e) => {
      e.preventDefault();
      //const linkId2 = e.currentTarget.getAttribute("data-value");
      const pageId2 = e.currentTarget.getAttribute("data-value2");
      const linkId2 = e.target.value;
      // let formData = new FormData();
      // formData.append('linkId', linkId);
      // formData.append('pageId', pageId2);

      setFormData({
         ...formData,
         linkId: linkId2,
         pageId: pageid,
     });
     setIsClick(1);

      console.log("linkId", linkId2);
      console.log("pageId", pageId2);
      // console.log('formData',formData);
      // console.log('data',data);
      //dispatch(deletePageLink(data));
    };

    const removeItem = (removeid) => {
      
      //const linkId2 = e.currentTarget.getAttribute("data-value");
      //const pageId2 = e.currentTarget.getAttribute("data-value2");
      const linkId2 = removeid;
      // let formData = new FormData();
      // formData.append('linkId', linkId);
      // formData.append('pageId', pageId2);
      setFormData({
         linkId: linkId2,
         pageId: pageid,
     });
      console.log("linkId", linkId2);
      console.log("pageId", pageid);
      console.log('formData',formData);
      console.log('data',data);
      dispatch(deletePageLink(data));
    };



    const getListStyle = isDraggingOver => ({
       background: isDraggingOver ? "darkgrey" : "darkgrey",
       width: '50%',
       margin: 'auto',
    });
    
    const getItemStyle = (isDragging, draggableStyle) => ({
       userSelect: "none",
       background: isDragging ? "darkgrey" : "white",
       color: isDragging ? "white" : "black",
       padding: isDragging ? '0%' : '2%',
       paddingLeft: '2%',
       margin: '0%',
       fontSize: '17px',
       borderBottom: '0.5px solid gray',
       // styles we need to apply on draggables
       ...draggableStyle
    });

    return (
       <div style={{ width: '100%', display: 'flex' }}>
            <Droppable droppableId="Buttons_drop_area"  >
             {(provided, snapshot) => (
                <div
                   {...provided.droppableProps}
                   ref={provided.innerRef}
                   style={getListStyle(snapshot.isDraggingOver)}
                >
                   <ul style={{ listStyleType: 'none', textAlign: 'left', padding: '0%', width: '100%' }} >
                      <h6 style={{ paddingLeft: '2%' }}>Links</h6>
                      {childValue &&
                      childValue.map((data, index) => (
                         <Draggable 
                           key={data._id} 
                           draggableId={`${data._id}${data.title}`} 
                           index={index}
                            >
                            {(provided, snapshot) => (
                               <li
                                  key={data._id}
                                  ref={provided.innerRef}
                                  //onClick={() => console.log(data._id)}
                                  //onClick={() => setFormData({linkId:data._id})}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                     snapshot.isDragging,
                                     provided.draggableProps.style
                                  )}
                               >
                                 <button
                                    type="button"
                                    className='btn btn-success'
                                 >
                                 {`${data.title}`}
                                 </button>
                                 
                              <button
                                    type="button"
                                    //key={index}
                                    //index={index}
                                    className='btn btn-danger'
                                    //onClick={() => removeItem(data._id)}
                                    data-value={data._id}
                                    value={data._id}
                                    data-value2={pageid}
                                    onClick={handleClick}
                                    //onClick={() => dispatch(deleteLink(data._id))}
                                    >
                                       <i className='far fa-trash-alt pr-1'></i>
                                       {data.url}
                                    </button>
                               </li>
                            )}
                         </Draggable>
                      ))}
                   </ul>
                  {provided.placeholder}
               </div>
            )}
         </Droppable>
        </div>
     )
 };


 const mapStateToProps = (state) => {
   return { links: state.links };
 };
 
 const mapDispatchToProps = dispatch => ({
   //deletePageLink: id => dispatch(deletePageLink(id))
 })
 
 
 export default connect(mapStateToProps, mapDispatchToProps)(ListButtons);