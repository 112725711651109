import React, {useState, useEffect, Fragment} from "react";
import { Link, useHistory } from "react-router-dom";
import {showErrorMsg,showSuccessMsg} from '../helpers/message';
import {showLoading} from '../helpers/loading';
import isEmpty from 'validator/lib/isEmpty';
import isUrl from 'validator/lib/isURL';
import { editpassword } from "../api/auth";
import { isAuthenticated } from "../helpers/auth";
import './Dashboard.css';
import Tooltip from './Tooltip';
import { Toast, Form } from 'react-bootstrap'
import ToastContainer from 'react-bootstrap/ToastContainer';

//redux
import {useSelector, useDispatch} from 'react-redux';
import {getLink,getLinks} from '../redux/actions/linkActions';


const UEditPassword = ({match}) => {

const dispatch = useDispatch();

let history = useHistory();

const {link} = useSelector(state=>state.links);

const [checkState,setCheckState] = useState(false);

//PARAMS
const linkId = match.params.linkId;

 //COMPONENT STATE PROPERTIES
 const [formData, setFormData] = useState({
    linkId:linkId,
    shortUrl:'',
    longUrl:'',
    title:'',
    password: [],
    userId:isAuthenticated()._id,
    isActive:false,
    pass:'',
    pass2:'',
    passid:'',
    successMsg: false,
    errorMsg: false,
    loading: false  
});


const {longUrl, shortUrl,title, userId, password, isActive, pass,pass2, passid, successMsg, errorMsg, loading } = formData;
//const [shortUrl, setShortUrl] = useState('');


 //LIFECYCLE METHODS

 useEffect(() => {
    
    if(!link){
        dispatch(getLink(linkId));
    } 
    else {
        
        setFormData({
            ...formData,
            shortUrl:link.shortUrl,
            longUrl:link.longUrl,
            title:link.title,
            password: link.password,
            
        });
        
    }

 }, [dispatch, linkId, link])

 

// useEffect(() => {
//     if(isAuthenticated() && isAuthenticated().role === 1) {
//         history.push('/admin/dashboard/');
//     } else if (isAuthenticated() && isAuthenticated().role === 0){
//         history.push('/user/dashboard/');
//     }
// },[history]);

useEffect(() => {
    password.map((p) => {
        const { isActive, pass, _id } = p;
        setFormData({
            ...formData,
            pass:pass,
            isActive:isActive,
            passid:_id,
        });
        setCheckState(isActive);
      });
}, [password])

useEffect(() => {
        setFormData({
            ...formData,
            isActive:checkState,
            
        });
}, [checkState])




 // COMPONENT GLOBAL STATE PROPERTIES 


const [clientSideErrorMsg,setClientSideErrorMsg] = useState('');
const [clientSideSurlErrorMsg,setClientSideSurlErrorMsg] = useState('');
const [clientSideSurlMsg,setClientSideSurlMsg] = useState('');
const [clientSideSucMsg,setClientSideSucMsg] = useState('');
const [modalState,setModalState] = useState(false);
const [showToast, setToast] = useState(false);


        // EVENT HANDLER

        const handleChange = (evt) => {
            //console.log(evt);
    
            

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            }  if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            }  if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            } 
            
        } ;

        const handlePass = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            

            if (isEmpty(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;



        const handleBlur = (evt) => {
            //console.log(evt.target.value.length);
            // setFormData({
            //     ...formData,
            //     [evt.target.name]: evt.target.value,
            //     errorMsg: '',
            //     successMsg: '',    
            // });

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            } else if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            } else if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            }
    
        } 

        const handleSubmit = async (evt) => {
            evt.preventDefault();

        // client-side validation

        

                editpassword(formData)
                        .then(response => {
                            if(response.data.successMessage){
                                                setFormData({
                                                    ...formData,
                                                    successMsg:response.data.successMessage
                                                })
                                                setTimeout(() => {
                                                    dispatch(getLinks(userId));
                                                    history.go("/user/dashboard/");
                                                }, 2000);
                                                
                                            }
                                        setClientSideSurlErrorMsg('');
                                        setClientSideSurlMsg('');
                                        setClientSideErrorMsg('');
                                    })
                                      
                .catch(err => {
                    console.log('edit password error:' , err);
                    setClientSideSurlMsg(err.response.data.errorMessage);
                    setClientSideSurlErrorMsg('false');
                }) 


        }


        const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};
        const copyToClipboard = (content) => {
            if (window.isSecureContext && navigator.clipboard) {
              navigator.clipboard.writeText(content);
            } else {
              unsecuredCopyToClipboard(content);
            }
          };

        const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        const baseurl = `${protocol}//${domain}${port? port : ""}`

        

        return (
            <Fragment>
            <header class="bg-surface-primary border-bottom pt-6">
            <div class="container-fluid">
                <div class="mb-npx">              
                    <div className='row'>
                    <div class="row mb-2">
                                            <div class=" ml-2">

                                            {isUrl(longUrl) && 
                                            <img src={`https://s2.googleusercontent.com/s2/favicons?domain=${longUrl}`}/>
                                            }
                                            {!isUrl(longUrl) && 
                                            <img src={``}/>
                                            }  
                                            
                                            <b> &nbsp; {title && title} </b>
                                            
                                            
                                            
                                                
                                            </div>
                        </div>
                    <div class="row">
                                            <div class="col ml-2 mb-2">
                                        
                                            
                                             <small>{longUrl && longUrl}</small>
                                                                                
                                            </div>
                        </div>
                    <div class="row">
                                            <div class="col">
                                            <Link to={{pathname: `${baseurl}/${shortUrl}`}} target="_blank" >{baseurl}/{shortUrl}</Link>
                                            <Tooltip content="Copy link" direction="top" delay="0">
                                                
                                                <i className="fas fa-clone ms-2" onClick={() => {copyToClipboard(`${baseurl}/${shortUrl}`);setToast(true)}}></i>
                                                
                                            </Tooltip>
                                            </div>
                                            <div class="col">
                                            </div>
                                    
                        </div>
                    </div>
                    
                    <ul class="nav nav-tabs mt-4 overflow-x border-0">
                        <li class="nav-item ">
                            <a href={`/edit/${linkId}`} class="nav-link font-regular">Title</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/alias/${linkId}`} class="nav-link font-regular">Alias Name</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/target/${linkId}`} class="nav-link font-regular">Target Url</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/mobiletarget/${linkId}`} class="nav-link font-regular">Mobile Redirect</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/password/${linkId}`} class="nav-link active">Password</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/utm/${linkId}`} class="nav-link font-regular">UTM Generator</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/expiration/${linkId}`} class="nav-link font-regular">Expiration</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/qr/${linkId}`} class="nav-link font-regular">QR Code</a>
                        </li>
                    </ul>
                </div>
            </div>
            </header>
            <main class="py-6 bg-surface-secondary">
                <div class="container-fluid max-w-screen-md vstack gap-6">
                
                <div class="card">
                <div class="card-body">
                <div class="mb-1">
                <h4 class="mb-1">Password Protection</h4><p class="text-sm text-muted">Protect your link with select a password</p>
                </div>

            <div class="modal-body">
                        <form onSubmit={handleSubmit} >
                        
                        <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={checkState}
                         onChange={() => setCheckState(!checkState)} />
                        <label class="form-check-label" for="flexSwitchCheckDefault">Password enabled</label>
                        </div>

                        <i className="fas fa-key"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp; Password:</label>
                        <div class="input-group mb-3">
                        {clientSideSurlErrorMsg==='false' ? 
                            ( <input type="text" class="form-control form-control-lg is-invalid" name="pass2" value={pass2} onChange={handlePass} aria-describedby="basic-addon3" />) 
                            : 
                            clientSideSurlErrorMsg==='true' ?  
                            ( <input type="text" class="form-control form-control-lg is-valid" name="pass2" value={pass2} onChange={handlePass} aria-describedby="basic-addon3"/>) 
                              : 
                              ( <input type="text" class="form-control" id="basic-url" name="pass2" value={pass2} onChange={handlePass} aria-describedby="basic-addon3"/>) 
                            }
                        
                        </div>
                        {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                        {successMsg && showSuccessMsg(successMsg)}
                    <div class="modal-footer pt-3 justify-content-between">
                        <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Save </button>
                    </div>
                    </form>
                    </div>
               
            </div>
            
            </div>

             {/* // Toast Container */}
             <div>
                        <ToastContainer className="p-3" position='bottom-end'>
                        <Toast
                            onClose={() => setToast(false)}
                            autohide
                            show={showToast}
                            delay={3000}
                        >
                            <Toast.Header>
                            <strong className="mr-auto">muud.ly</strong>
                            
                            </Toast.Header>
                            <Toast.Body>URL has been copied.</Toast.Body>
                        </Toast>
                        </ToastContainer>
             </div>

                            
                </div>
                    </main>
                
            </Fragment>
        );
        
    };

export default UEditPassword;