import React, {useState, useEffect, Fragment} from "react";
import { useHistory } from "react-router-dom";
import {showLoading} from '../helpers/loading';
import { verify } from "../api/auth";
import { isAuthenticated,setAuthentication } from "../helpers/auth";
import { useParams } from "react-router-dom";

const Verify = () => {

    let history = useHistory();

    const { token } = useParams();

    useEffect(() => {
        if(isAuthenticated() && isAuthenticated().role === 10) {
            setTimeout(() => { 
                history.push('/admin/dashboard/');
              }, 2000)
        } else if (isAuthenticated() && isAuthenticated().role === 0){
            setTimeout(() => { 
                history.push('/user/dashboard/');
              }, 2000)
        }
        else if (isAuthenticated() && isAuthenticated().role === 1){
            setTimeout(() => { 
                history.push('/user/dashboard/');
              }, 2000)
        }
    },[history]);

    const [formData, setFormData] = useState({
        successMsg: false,
        errorMsg: false,
        loading:true
    });

    const {successMsg, errorMsg, loading } = formData;


    //setFormData({...formData, loading: true});
    //console.log('recover api error:', token);

    useEffect(() => {
        // GET request using axios inside useEffect React hook
        verify(token)
        .then(response => {
            //console.log('verify success',response);
            setFormData({
                successMsg: response.data.successMessage,
                loading: false,
             });
             setAuthentication(response.data.token, response.data.user);

                    
        }).finally(() => {
            if(isAuthenticated() && isAuthenticated().role === 10) {
                setTimeout(() => { 
                    history.push('/admin/dashboard/');
                  }, 3000)
            } else if (isAuthenticated() && isAuthenticated().role === 0){
                setTimeout(() => { 
                    history.push('/user/dashboard/');
                  }, 3000)
            }
            else if (isAuthenticated() && isAuthenticated().role === 1){
                setTimeout(() => { 
                    history.push('/user/dashboard/');
                  }, 3000)
            }

        })
        .catch(err => {
            console.log('verify api error:', err);
            setFormData({
                ...formData,
                errorMsg: err.response.data.errorMessage,
                loading:false,
            });
        })
    
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);


            //WIEWS
            const showVerifyPage = () => (

                // <div className="signup-form">
                // <h2></h2>
                // <p></p>
                // {errorMsg && showErrorMsg(errorMsg)}
                // {successMsg && showSuccessMsg(successMsg)}
                // </div>

                    <div>
                    {successMsg ? ( 
                    <Fragment>
                    <div className="content-area login-form">
                    <div className="information-area text-centered">
                    <i className="far fa-check-circle fa-8x success-color"></i>
                    <h2 className="form-title">Success</h2>
                    <p>{successMsg}</p>
                    </div>
                    </div>
                </Fragment>
                ) : ( errorMsg &&

                    <Fragment>
                    <div className="content-area login-form">
                    <div className="information-area text-centered">
                    <i className="far fa-check-circle fa-8x error-color"></i>
                    <h2 className="form-title">Error</h2>
                    <p>{errorMsg}</p>
                </div>
                <div className="button-group">
                    <input type="button" name="" id="" value="Back to Home" />
                </div>
                </div>
                    </Fragment>

                )
               
                 }
                 </div>
        
            );

    
                // RENDER
                return (
        
                    <section class="centered">
                        {loading && <div className='text-center'>{showLoading()}</div>}
                        {showVerifyPage()}
                    </section>
            
                );


}


export default Verify;