import React, {useEffect} from 'react';
//REDUX
import {connect, useDispatch} from 'react-redux';
import {getNextUsers} from '../redux/actions/adminActions';


const PageItem = ({ limit, pageNumber, getNextUsers }) => {

    const dispatch = useDispatch();
    return (
    <li
      className="page-link"
      onClick={() => getNextUsers(limit,pageNumber)}
      id={pageNumber}
    >
      {pageNumber}
    </li>
  )
    };
  
  const mapStateToProps = state => {
    return {
      current_page: state.current_page
    };
  };
    const mapDispatchToProps = (dispatch) => ({
    getNextUsers: (limit, page) => dispatch(getNextUsers(limit, page))
});
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(PageItem);
  