import axios from 'axios';
import { START_LOADING, STOP_LOADING} from '../constants/loadingConstants';
import {SHOW_ERROR_MESSAGES, SHOW_SUCCESS_MESSAGES} from '../constants/messageConstants';
import {CREATE_PAGE,CREATE_LINK, DELETE_LINK, GET_PAGES, DELETE_PAGE, GET_PAGE, UPDATE_DND_LIST} from '../constants/pageConstants';


export const createPage = formData => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.post('/api/page', formData);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_SUCCESS_MESSAGES, payload: response.data.successMessage});
        dispatch({type: CREATE_PAGE, payload: response.data.page});
        
    } catch(err) {
        console.log('create page api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const addLink = formData => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.post('/api/page/addlink', formData);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_SUCCESS_MESSAGES, payload: response.data.successMessage});
        dispatch({type: CREATE_LINK, payload: response.data.pages});

    } catch(err) {
        console.log('create link api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const getPages = (userId) => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.get(`/api/page/all/${userId}`);
        dispatch({type: STOP_LOADING});
        dispatch({type: GET_PAGES, payload: response.data.pages});

    } catch(err) {
        console.log('get pages api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const getPagesByCount = () => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.get('/api/page/count');
        dispatch({type: STOP_LOADING});
        dispatch({type: GET_PAGES, payload: response.data.pages});

    } catch(err) {
        console.log('get pages api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const getPage = (pageId) => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.get(`/api/page/${pageId}`);
        dispatch({type: STOP_LOADING});
        dispatch({type: GET_PAGE, payload: response.data});

    } catch(err) {
        console.log('get page api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const deletePage = pageId => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.delete(`/api/page/${pageId}`);
        dispatch({type: STOP_LOADING});
        dispatch({type: DELETE_PAGE, payload: response.data});

    } catch(err) {
        console.log('delete page api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const deletePageLink = formData => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        console.log('page action formdata',formData);
        const config = {
            header: {
                'Content-Type': 'application/json'
            }
        }
        const response = await axios.post(`/api/page/deletelink`,formData, config);
        dispatch({type: STOP_LOADING});
        console.log('response:',response);
        dispatch({type: DELETE_LINK, payload: response.data});

    } catch(err) {
        console.log('delete link api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const updateDndList = formData => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const config = {
            header: {
                'Content-Type': 'application/json'
            }
        }
        const response = await axios.put(`/api/page/updatedndlist`,formData, config);
        dispatch({type: STOP_LOADING});
        console.log('update dnd list response:',response);
        dispatch({type: UPDATE_DND_LIST, payload: response.data});

    } catch(err) {
        console.log('delete link api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};