import React, {useState, useEffect, Fragment} from "react";
import { Link, useHistory } from "react-router-dom";
import {showErrorMsg,showSuccessMsg} from '../helpers/message';
import {showLoading} from '../helpers/loading';
import isEmpty from 'validator/lib/isEmpty';
import isUrl from 'validator/lib/isURL';
import isEmail from 'validator/lib/isEmail';
import equals from 'validator/lib/equals';
import { addmember, getteam } from "../api/auth";
import { isAuthenticated } from "../helpers/auth";
import './Dashboard.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';



//redux
import {useSelector, useDispatch} from 'react-redux';

const mongoose = require("mongoose");


const UPlan = ({match}) => {

const dispatch = useDispatch();

let history = useHistory();

const {link} = useSelector(state=>state.links);

//PARAMS
//const linkId = match.params.linkId;

 //COMPONENT STATE PROPERTIES
 const [formData, setFormData] = useState({
    name:'',
    email:'',
    password:'',
    password2:'',
    title:'',
    role:1,
    isVerified: true,
    _id: new mongoose.Types.ObjectId(),
    userId:isAuthenticated()._id,
    teamId: isAuthenticated().teamId,
    teamid: isAuthenticated().teamId,
    successMsg: false,
    errorMsg: false,
    loading: false  
});

const {name, password, password2,email, userId, _id, successMsg, errorMsg, loading, teamId,role, teamid, isVerified } = formData;
//const [shortUrl, setShortUrl] = useState('');
const [freetext,setFreeText] = useState('');
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);

const [teamData, setTeamData] = useState({
    team:[]
});

const {team } = teamData;

 //LIFECYCLE METHODS

 useEffect(() => {

    if(teamid) {
        getteam(teamid)
            .then(response => {
                            //console.log(response.data.team_array)
                setTeamData({
                    ...teamData,
                    team: response.data.obj_data
                    
                });             
            })
            .catch(err => {
                console.log('get team error:' , err);  
            }) 
    }

 }, [])

 

// useEffect(() => {
//     if(isAuthenticated() && isAuthenticated().role === 1) {
//         history.push('/admin/dashboard/');
//     } else if (isAuthenticated() && isAuthenticated().role === 0){
//         history.push('/user/dashboard/');
//     }
// },[history]);

useEffect(() => {

}, [])

 // COMPONENT GLOBAL STATE PROPERTIES 


const [clientSideErrorMsg,setClientSideErrorMsg] = useState('');
const [clientSideSurlErrorMsg,setClientSideSurlErrorMsg] = useState('');
const [clientSideSurlMsg,setClientSideSurlMsg] = useState('');
const [clientSideSucMsg,setClientSideSucMsg] = useState('');
const [modalState,setModalState] = useState(false);



        // EVENT HANDLER

        const handleTeam = () => {
            if(teamid) {
                getteam(teamid)
                    .then(response => {
                                    //console.log(response.data.team_array)
                        setTeamData({
                            ...teamData,
                            team: response.data.obj_data
                            
                        });             
                    })
                    .catch(err => {
                        console.log('get team error:' , err);  
                    }) 
            }
            
        } ;

        const handleChange = (evt) => {
            //console.log(evt);
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            // if (isEmpty(evt.target.value)){
            //     setClientSideSurlErrorMsg('false');
            // } 
            
        } ;

        const handlePaste = (evt) => {

            setClientSideSurlMsg('');

            if (isUrl(evt.target.value)){
                setClientSideSurlErrorMsg('true');
            }  if (!isUrl(evt.target.value)) {
                setClientSideSurlErrorMsg('false');
            }  if (isEmpty(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            
        } ;
        const handleTarget = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });

            setClientSideSurlMsg('');
            
            if (!isUrl(evt.target.value)) {
                setClientSideSurlErrorMsg('false');
            } 
            else if (isEmpty(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            else if (isUrl(evt.target.value)){
                setClientSideSurlErrorMsg('true');
            }
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                // setClientSideSurlMsg('');
                // setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleMessages = (evt) => {
            

            setClientSideSurlErrorMsg('');
            setClientSideSurlMsg('');
            setClientSideErrorMsg('');

            setModalState(false);
            setShow(false);
            setFormData({
                ...formData,
                name:'',
                email:''
            });

        };

        const handleBlur = (evt) => {

            
    
        } 

        const handleFreeText = (evt) => {

            //setFreeText(evt.target.value);
            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleMail = (evt) => {
            //console.log(evt);
    
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
                errorMsg: '',
                   
            });

            if (isEmpty(evt.target.value) || !isEmail(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            // if(isEmail(evt.target.value)) {
            //     setClientSideSurlErrorMsg('true');
            // }
            
            
        } ;

        const handleCreateClick = (evt) => {
            

            setModalState(true);
            setShow(true);


        };

        const handleSubmit = async (evt) => {
            evt.preventDefault();

        // client-side validation

        if(isEmpty(name) || isEmpty(email) || isEmpty(password) || isEmpty(password2)) {
            
            setFormData({
                ...formData, errorMsg: 'All fields are required',
            });

        } else if (!isEmail(email)){
            setFormData({
                ...formData, errorMsg: 'Invalid email',
            });  
        } else if (!equals(password,password2)) {
            setFormData({
                ...formData, errorMsg: 'Password do not match',
            });
        } else {

            const {name, email, password, _id, teamId, role} = formData;
            const data = {name, email, password, _id, teamId, role};

            setFormData({...formData, loading: true});

            addmember(data)
                .then(response => {
                    console.log('Axios signup success',response);
                    setFormData({
                        email: '',
                        name:'',
                        password: '',
                        password2: '',
                        loading: false,
                        successMsg: response.data.successMessage,
                     });

                     handleTeam();
                })
                .catch(err => {
                    console.log('axios signup error:' , err);
                    setFormData({...formData, loading: false, errorMsg: err.response.data.errorMessage});
                })

        }

        

        }


        const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        const baseurl = `${protocol}//${domain}${port? port : ""}`

        
    

        return (
            <Fragment>
            <header class="bg-surface-primary border-bottom pt-6">
            <div class="container-fluid">
                <div class="mb-npx">              
                <div class="row align-items-center">
                        <div class="col-sm-9 col-12 mb-4 mb-sm-0">
                            <h1 class="h2 mb-0 ls-tight">Settings</h1>
                        </div>
                        
                    </div>
                    
                    <ul class="nav nav-tabs mt-4 overflow-x border-0">
                        <li class="nav-item ">
                            <a href={`/e/settings`} class="nav-link font-regular">Profile</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/e/team/`} class="nav-link font-regular">Team Management</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/e/plan/`} class="nav-link active">Plan</a>
                        </li>
                       
                    </ul>
                </div>
            </div>
            </header>
            <main class="py-6 bg-surface-secondary">
            <div class="container-fluid max-w-screen-md vstack gap-6">
                
            <div className='row pb-3'>
            <div class="card mb-4 box-shadow">
                        <div class="card-header">
                            <h4 class="my-0 font-weight-normal">Enterprise Account</h4>
                        </div>
                        <div class="card-body">
                            
                            <ul class="list-unstyled mt-3 mb-4">
                            <li> <i className="fas fa-check-circle"></i> Unlimited Link</li>
                            <li><i className="fas fa-check-circle"></i> Unlimited QR Code</li>
                            <li><i className="fas fa-check-circle"></i> Unlimited Click</li>
                            <li><i className="fas fa-check-circle"></i> Insight & Reports</li>
                            <li><i className="fas fa-check-circle"></i> Team Management</li>
                            </ul>
                        </div>
                        </div>
                        </div>
                            
                    </div>
                </main>
                
            </Fragment>
        );
        
    };

export default UPlan;