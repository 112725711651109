import {CREATE_PAGE,CREATE_LINK, DELETE_LINK, GET_PAGES, DELETE_PAGE, GET_PAGE, UPDATE_DND_LIST} from '../constants/pageConstants';


const INITIAL_STATE = {
    pages: [],
}

const pageReducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_PAGE:
            return {
                pages: [...state.pages, action.payload],
            };
        case CREATE_LINK:
             return {
                //pages: [...state.pages, action.payload],
                pages: action.payload,
            };
        case GET_PAGES:
            return {
                pages: [...action.payload],
            };
            case GET_PAGE:
                return {
                    page: action.payload,
                };    
        case DELETE_PAGE:
            return {
                 pages: state.pages.filter(p => p._id !== action.payload._id)
            };
            case DELETE_LINK:

            return {
                 pages: state.pages ? state.pages.filter(p => p._id !== action.payload._id) : []
            };

            case UPDATE_DND_LIST:

            return {
                 //pages: action.payload,
                 //pages: state.pages ? state.pages.filter(p => p._id !== action.payload._id) : []
                 pages: state.pages,
            };
                
            default:
                return state;
    }
};

export default pageReducer;