import React, {useState, Fragment} from 'react';
import axios from 'axios';
import {showErrorMsg , showSuccessMsg } from '../helpers/message';
import { showLoading } from "../helpers/loading";
import isEmpty from 'validator/lib/isEmpty';
//REDUX
import {useSelector, useDispatch} from 'react-redux';
import {clearMessages} from '../redux/actions/messageActions';
import {addLink} from '../redux/actions/pageActions';


const UserLinkModal = ({pageid}) => {

    // REDUX GLOBAL STATE PROPERTIES
    const {loading} = useSelector(state=>state.loading);
    const {successMsg,errorMsg} = useSelector(state=>state.messages);
    const {categories} = useSelector(state=>state.categories);
    const {page} = useSelector(state=>state.pages);

    const dispatch = useDispatch();

    // COMPONENT STATE PROPERTIES
    const [clientSideError,setClientSideError] = useState('');
    const [showModal, setShowModal] = useState(true);
    //setShowModal(showmodal);

    const closeModal = () => {
        setShowModal(false);
    };
    const openModal = () => {
        setShowModal(true);
    };

    

    const [formData,setFormData] = useState({
        title: '',
        url: '',
        category: '',
        pageId: '',
    });

    const {title,url,category, pageId} =formData;
    const data = {title, url, category, pageId};
    // EVENT HANDLERS

    const handleMessages = (evt) => {
        dispatch(clearMessages());
        setClientSideError('');
    };

    const handleLinkChange = (evt) => {
        setFormData({
            ...formData,
            pageId: page?.map(p => p._id)[0],
            [evt.target.name]: evt.target.value,
        });
    }

    const handleLinkSubmit = (evt) => {
        evt.preventDefault();

        if(isEmpty(title) || isEmpty(url)) {
            setClientSideError('All fields are required!');
        } else if(isEmpty(category)) {
            setClientSideError('Please select a category');
        } else {
            let formData = new FormData();
            formData.append('title', title);
            formData.append('url', url);
            formData.append('category', category);
            formData.append('pageId', pageId);
            dispatch(addLink(data)); 
            // document.getElementById("addLinkModal").classList.remove("modal");
            // document.getElementById("addLinkModal").classList.remove("show", "d-block");

            setFormData({
                title: '',
                url: '',
                category: '', 
            });
        }

    }


    // RENDER

    return (
        <>
            
            <div id='addLinkModal' className='modal' onClick={handleMessages}>
            <div className='modal-dialog modal-dialog-centered modal-lg'>
                <div className='modal-content'>
                <form onSubmit={handleLinkSubmit}>
                    <div className='modal-header'>
                        <h5 className='modal-title'>Add Link</h5>
                    </div>
                    <div className='modal-body my-2'>
                            {clientSideError && showErrorMsg(clientSideError)}
                            {errorMsg && showErrorMsg(errorMsg)}
                            {successMsg && showSuccessMsg(successMsg)}
    
                            {
    
                                loading ? (
                                    <div className='text-center'>{showLoading()}</div>
                                    
                                ) : (
                                    <Fragment>
                                        <div className='form-row'>
                                            <div className='form-group col-md-6'>
                                            <label className='text-secondary'>Platforms</label>
                                                <select className='form-select mr-sm-2' name='category' onChange={handleLinkChange}>
                                                    <option value=''>Choose platform...</option>
                                                    {categories && categories.map(c => (
                                                        <option key={c._id} value={c._id}>
                                                            {c.category}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label className='text-secondary'>Title</label>
                                            <input type='text' className='form-control' name='title' value={title} onChange={handleLinkChange} />
                                        </div>
                                        <div className='form-group'>
                                            <label className='text-secondary'>URL</label>
                                            <input type='text' className='form-control' name='url' value={url} onChange={handleLinkChange} />
                                        </div>
                                        
                                    </Fragment>
                                )
    
                            }
    
                    </div>
                    <div className='modal-footer'>
                        <button type='button' className='btn btn-secondary' data-bs-dismiss='modal' onClick={closeModal}>Close</button>
                        <button type='submit' name="submit" value="submit" id="submit" className='btn btn-success'>Submit</button>
                    </div>
                    </form>
                </div>
            </div>
            </div> 
                 

        </>
);
                    
};

export default UserLinkModal;