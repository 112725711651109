import React from 'react'
import { usePagination, DOTS } from './usePagination';
import classnames from 'classnames';


const Pagination2 = props => {
    const {
      onPageChange,
      totalCount,
      siblingCount = 5,
      currentPage,
      pageSize,
      className
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
      });

    //const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
    

      // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const nPages = Math.ceil(totalCount / pageSize)

  const onNext = () => {
    //onPageChange(currentPage + 1);

    if(currentPage !== nPages) onPageChange(currentPage + 1)
  };

  const onPrevious = () => {
    //onPageChange(currentPage - 1);

    if(currentPage !== 1) onPageChange(currentPage - 1)
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <nav>
        <ul className='pagination justify-content-center'>
                <li className="page-item">
                    <a className="page-link" 
                        onClick={onPrevious} 
                        href='#!'>
                        
                        Previous
                    </a>
                </li>
    
      {paginationRange.map(pageNumber => {
         
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return <li className="page-item disabled"><a class="page-link" href="#!" tabindex="-1" aria-disabled="true">...</a></li>;
        }
		
        // Render our Page Pills
        return (
            <li key={pageNumber} 
                        className= {`page-item ${currentPage == pageNumber ? 'active' : ''} `} >

                        <a onClick={() => onPageChange(pageNumber)}  
                            className='page-link' 
                            href='#!'>
                            
                            {pageNumber}
                        </a>
                    </li>
          
        );
      })}
      {/*  Right Navigation arrow */}

      <li className="page-item">
                    <a className="page-link" 
                        onClick={onNext}
                        href='#!'>
                        
                        Next
                    </a>
                </li>
      
    </ul>
    </nav>
  );
};


export default Pagination2;