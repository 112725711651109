import React, {useState, useEffect, Fragment} from "react";
import { Link, useHistory } from "react-router-dom";
import { customAlphabet } from 'nanoid'
import axios from 'axios';
import {showErrorMsg,showSuccessMsg} from '../helpers/message';
import {showLoading} from '../helpers/loading';
import isEmpty from 'validator/lib/isEmpty';
import isUrl from 'validator/lib/isURL';
import { checkisshorturl,editalias } from "../api/auth";
import { setAuthentication, isAuthenticated } from "../helpers/auth";
import './Dashboard.css';

import { Toast, Form } from 'react-bootstrap'
import ToastContainer from 'react-bootstrap/ToastContainer';
import Tooltip from './Tooltip';

//redux
import {useSelector, useDispatch} from 'react-redux';
import {getLink,getLinks} from '../redux/actions/linkActions';


const UEditAlias = ({match}) => {

const dispatch = useDispatch();

let history = useHistory();


const {link} = useSelector(state=>state.links);

//PARAMS
const linkId = match.params.linkId;

 //COMPONENT STATE PROPERTIES
 const [formData, setFormData] = useState({
    linkId:linkId,
    shortUrl:'',
    longUrl:'',
    title:'',
    userId:isAuthenticated()._id,
    successMsg: false,
    errorMsg: false,
    loading: false  
});

const {longUrl, shortUrl,title, userId, successMsg, errorMsg, loading } = formData;
//const [shortUrl, setShortUrl] = useState('');


 //LIFECYCLE METHODS

 useEffect(() => {
    
    if(!link){
        dispatch(getLink(linkId));
    } 
    else {
        
        setFormData({
            ...formData,
            shortUrl:link.shortUrl,
            longUrl:link.longUrl,
            title:link.title,
            userId:isAuthenticated()._id,
               
        });
        
    }

 }, [dispatch, linkId, link])

 

// useEffect(() => {
//     if(isAuthenticated() && isAuthenticated().role === 1) {
//         history.push('/admin/dashboard/');
//     } else if (isAuthenticated() && isAuthenticated().role === 0){
//         history.push('/user/dashboard/');
//     }
// },[history]);

useEffect(() => {

}, [])

 // COMPONENT GLOBAL STATE PROPERTIES 


const [clientSideErrorMsg,setClientSideErrorMsg] = useState('');
const [clientSideSurlErrorMsg,setClientSideSurlErrorMsg] = useState('');
const [clientSideSurlMsg,setClientSideSurlMsg] = useState('');
const [clientSideSucMsg,setClientSideSucMsg] = useState('');
const [modalState,setModalState] = useState(false);
const [showToast, setToast] = useState(false);


const nanoid = customAlphabet('1234567890abcdefghijklmnouprstuvyzwqx', 5)



        // EVENT HANDLER

        const handleChange = (evt) => {
            //console.log(evt);
    
            

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            }  if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            }  if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            } 
            
        } ;

        const handleShortUrl = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
                
            });
            

            if (isEmpty(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

   

        const handleMessages = (evt) => {
            

            setClientSideSurlErrorMsg('');
            setClientSideSurlMsg('');
            setClientSideErrorMsg('');

            setModalState(false);

        };

        const handleCreateClick = (evt) => {
            

            setModalState(true);

        };


        const handleBlur = (evt) => {
            //console.log(evt.target.value.length);
            // setFormData({
            //     ...formData,
            //     [evt.target.name]: evt.target.value,
            //     errorMsg: '',
            //     successMsg: '',    
            // });

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            } else if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            } else if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            }
    
        } 

        const handleSubmit = async (evt) => {
            evt.preventDefault();

        // client-side validation

        if(isEmpty(shortUrl)) {
            setClientSideSurlMsg('All fields requried');

        } else if(!isEmpty(shortUrl)) {

                const shorturi= shortUrl;
                const suri={shorturi};

                checkisshorturl(suri)
                .then(response => {

                    if(response.data.taken) {
                        setClientSideSurlErrorMsg('false');
                        setClientSideSurlMsg(response.data.errorMessage);

                    } else if(!response.data.taken) {

                                    console.log(userId);

                                    editalias(formData)
                                        .then(response => {
                                            if(response.data.successMessage){
                                                setFormData({
                                                    ...formData,
                                                    successMsg:response.data.successMessage
                                                })
                                                dispatch(getLinks(userId));
                                                setTimeout(() => {

                                                    history.go("/user/dashboard/");
                                                }, 2000);
                                                
                                            } else if(response.data.errorMessage) {
                                                setClientSideSurlErrorMsg(response.data.errorMessage);
                                            }
                                        })
                                      
                                    
                                    setClientSideSurlErrorMsg('');
                                    setClientSideSurlMsg('');
                                    setClientSideErrorMsg('');
                                    
                        
                    

                    }
                
                

                })
                .catch(err => {
                    console.log('axios signup error:' , err);
                    setClientSideSurlMsg(err.response.data.errorMessage);
                    setClientSideSurlErrorMsg('false');
                }) 

                

                

        }

        }

        const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};
        const copyToClipboard = (content) => {
            if (window.isSecureContext && navigator.clipboard) {
              navigator.clipboard.writeText(content);
            } else {
              unsecuredCopyToClipboard(content);
            }
          };

        const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        const baseurl = `${protocol}//${domain}${port? port : ""}`

        
    

        return (
            <Fragment>
            <header class="bg-surface-primary border-bottom pt-6">
            <div class="container-fluid">
                <div class="mb-npx">              
                    <div className='row'>
                    <div class="row mb-2">
                                            <div class=" ml-2">

                                            {isUrl(longUrl) && 
                                            <img src={`https://s2.googleusercontent.com/s2/favicons?domain=${longUrl}`}/>
                                            }
                                            {!isUrl(longUrl) && 
                                            <img src={``}/>
                                            }  
                                            
                                            <b> &nbsp; {title && title} </b>
                                            
                                            
                                            
                                                
                                            </div>
                        </div>
                    <div class="row">
                                            <div class="col ml-2 mb-2">
                                        
                                            
                                             <small>{longUrl && longUrl}</small>
                                                                                
                                            </div>
                        </div>
                    <div class="row">
                                            <div class="col">
                                            <Link to={{pathname: `${baseurl}/${shortUrl}`}} target="_blank" >{baseurl}/{shortUrl}</Link>
                                            <Tooltip content="Copy link" direction="top" delay="0">
                                                
                                                <i className="fas fa-clone ms-2" onClick={() => {copyToClipboard(`${baseurl}/${shortUrl}`);setToast(true)}}></i>
                                                
                                            </Tooltip>
                                            </div>
                                            <div class="col">
                                            </div>
                                    
                        </div>
                    </div>
                    
                    <ul class="nav nav-tabs mt-4 overflow-x border-0">
                        <li class="nav-item ">
                            <a href={`/edit/${linkId}`} class="nav-link font-regular">Title</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/alias/${linkId}`} class="nav-link active">Alias Name</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/target/${linkId}`} class="nav-link font-regular">Target Url</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/mobiletarget/${linkId}`} class="nav-link font-regular">Mobile Redirect</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/password/${linkId}`} class="nav-link font-regular">Password</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/utm/${linkId}`} class="nav-link font-regular">UTM Generator</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/expiration/${linkId}`} class="nav-link font-regular">Expiration</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/qr/${linkId}`} class="nav-link font-regular">QR Code</a>
                        </li>
                    </ul>
                </div>
            </div>
            </header>
            <main class="py-6 bg-surface-secondary">
            <div class="container-fluid max-w-screen-md vstack gap-6">
                
                <div class="card">
                <div class="card-body">
                <div class="mb-1">
                <h4 class="mb-1">Alias Name</h4><p class="text-sm text-muted">Change your shortlink alias name</p>
                </div>

            <div class="modal-body">
                        <form onSubmit={handleSubmit} >
                        
                        <i className="fas fa-magic-wand-sparkles"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp;Short URL:</label>
                        <div class="input-group mb-3">
                        <span class="input-group-text has-validation" id="basic-addon3">{baseurl}</span>
                        {clientSideSurlErrorMsg==='false' ? 
                            ( <input type="text" class="form-control form-control-lg is-invalid" name="shortUrl" value={shortUrl} onChange={handleShortUrl} aria-describedby="basic-addon3" />) 
                            : 
                            clientSideSurlErrorMsg==='true' ?  
                            ( <input type="text" class="form-control form-control-lg is-valid" name="shortUrl" value={shortUrl} onChange={handleShortUrl} aria-describedby="basic-addon3"/>) 
                              : 
                              ( <input type="text" class="form-control" id="basic-url" name="shortUrl" value={shortUrl} onChange={handleShortUrl} aria-describedby="basic-addon3"/>) 
                            }
                        
                        </div>
                        {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                        {successMsg && showSuccessMsg(successMsg)}
                    <div class="modal-footer justify-content-between">
                        <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Save </button>
                    </div>
                    </form>
                    </div>
               
            </div>
            </div>
            


                         {/* // Toast Container */}
            <div>
                        <ToastContainer className="p-3" position='bottom-end'>
                        <Toast
                            onClose={() => setToast(false)}
                            autohide
                            show={showToast}
                            delay={3000}
                        >
                            <Toast.Header>
                            <strong className="mr-auto">muud.ly</strong>
                            
                            </Toast.Header>
                            <Toast.Body>URL has been copied.</Toast.Body>
                        </Toast>
                        </ToastContainer>
                    </div>

                     </div>
                    </main>
                
            </Fragment>
        );
        
    };

export default UEditAlias;
