import React from "react";

export const showErrorMsg2 = (msg) => (
    <div className="alert alert-danger" role="alert">
        {msg}
    </div>
);

export const showSuccessMsg = (msg) => (
    <p className="alert-box success-box">{msg}</p>
);

export const showErrorMsg = (msg) => (
    <p className="alert-box error-box">{msg}</p>
);

export const showSuccessInfo = (msg) => (
            <div className="content-area login-form">
                <div className="information-area text-centered">
                    <i className="far fa-check-circle fa-8x success-color"></i>
                    <h2 className="form-title">Success</h2>
                    <p>{msg}</p>
                </div>
                </div>
);

export const showErrorInfo = (msg) => (
        <div className="content-area login-form">
             <div className="information-area text-centered">
                    <i className="far fa-times-circle fa-8x error-color"></i>
                    <h2 className="form-title">Error</h2>
                    <p>{msg}</p>
                </div>
                </div>
);

export const showUsernameErrorMsg = (msg) => (
<small className='error-color'>{msg}</small>
);

export const showUsernameSuccessMsg = (msg) => (
    <small className='success-color'>{msg}</small>
    );

