import {GET_NEW_USERS,GET_TOTAL_USER_COUNT,GET_CURRENT_PAGE} from '../constants/adminConstants';


const INITIAL_STATE = {
    adminusers: [],
    total_user_count:1,
    current_page: 1,
};

const adminReducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_NEW_USERS:
            return {
                adminusers: [...action.payload],
            };
            case GET_TOTAL_USER_COUNT:
                return {
                    ...state, total_user_count: action.payload
                };
                case GET_CURRENT_PAGE:
                return {
                    ...state, current_page: action.payload
                };
            default:
                return state;
    }
};

export default adminReducer;