import React from 'react';

const AdminMetrics = () => (

    <div className='bg-light py-2'>
        <div className='container'>
            <div className='row pb-3'>

        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
            <div class="inforide">
              <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-4 col-4 rideone">
                <img src="/images/user.png"/>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-8 col-8 fontsty">
                    <h4>Users</h4>
                    <h2>20</h2>
                </div>
              </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
            <div class="inforide">
              <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-4 col-4 ridetwo">
                    <img src="/images/increase.png"/>                
                    </div>
                <div class="col-lg-9 col-md-8 col-sm-8 col-8 fontsty">
                    <h4>Clicks</h4>
                    <h2>120</h2>
                </div>
              </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
            <div class="inforide">
              <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-4 col-4 ridethree">
                <img src="/images/dollar.png"/>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-8 col-8 fontsty">
                    <h4>Revenue</h4>
                    <h2>50.8</h2>
                </div>
              </div>
            </div>
        </div>
            {/* <div className='col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4'>
                Hello
            </div>
            <div className='col-md-3 my-1 border rounded'>
                Mello
            </div>
            <div className='col-md-3 my-1 border rounded'>
                Kello
            </div>
            <div className='col-md-3 my-1 border rounded'>
                Kello
            </div> */}
        </div>
        </div>
    </div>

);

export default AdminMetrics;