import React, {useState, useEffect, Fragment} from "react";
import { Link, useHistory } from "react-router-dom";
import {showErrorMsg,showSuccessMsg} from '../helpers/message';
import {showLoading} from '../helpers/loading';
import isEmpty from 'validator/lib/isEmpty';
import isUrl from 'validator/lib/isURL';
import { edittitle } from "../api/auth";
import { isAuthenticated } from "../helpers/auth";
import './Dashboard.css';

//redux
import {useSelector, useDispatch} from 'react-redux';
import {getLink,getLinks} from '../redux/actions/linkActions';


const UEditTitle = ({match}) => {

const dispatch = useDispatch();

let history = useHistory();

const {link} = useSelector(state=>state.links);

//PARAMS
const linkId = match.params.linkId;

 //COMPONENT STATE PROPERTIES
 const [formData, setFormData] = useState({
    linkId:linkId,
    shortUrl:'',
    longUrl:'',
    title:'',
    userId:isAuthenticated()._id,
    successMsg: false,
    errorMsg: false,
    loading: false  
});

const {longUrl, shortUrl,title, userId, successMsg, errorMsg, loading } = formData;
//const [shortUrl, setShortUrl] = useState('');


 //LIFECYCLE METHODS

 useEffect(() => {
    
    if(!link){
        dispatch(getLink(linkId));
    } 
    else {
        
        setFormData({
            ...formData,
            shortUrl:link.shortUrl,
            longUrl:link.longUrl,
            title:link.title,
               
        });
        
    }

 }, [dispatch, linkId, link])

 

// useEffect(() => {
//     if(isAuthenticated() && isAuthenticated().role === 1) {
//         history.push('/admin/dashboard/');
//     } else if (isAuthenticated() && isAuthenticated().role === 0){
//         history.push('/user/dashboard/');
//     }
// },[history]);

useEffect(() => {

}, [])

 // COMPONENT GLOBAL STATE PROPERTIES 


const [clientSideErrorMsg,setClientSideErrorMsg] = useState('');
const [clientSideSurlErrorMsg,setClientSideSurlErrorMsg] = useState('');
const [clientSideSurlMsg,setClientSideSurlMsg] = useState('');
const [clientSideSucMsg,setClientSideSucMsg] = useState('');
const [modalState,setModalState] = useState(false);



        // EVENT HANDLER

        const handleChange = (evt) => {
            //console.log(evt);
    
            

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            }  if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            }  if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            } 
            
        } ;

        const handleTitle = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            

            if (isEmpty(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

   

        const handleMessages = (evt) => {
            

            setClientSideSurlErrorMsg('');
            setClientSideSurlMsg('');
            setClientSideErrorMsg('');

            setModalState(false);

        };

        const handleCreateClick = (evt) => {
            

            setModalState(true);

        };


        const handleBlur = (evt) => {
            //console.log(evt.target.value.length);
            // setFormData({
            //     ...formData,
            //     [evt.target.name]: evt.target.value,
            //     errorMsg: '',
            //     successMsg: '',    
            // });

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            } else if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            } else if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            }
    
        } 

        const handleSubmit = async (evt) => {
            evt.preventDefault();

        // client-side validation

        if(isEmpty(title)) {
            setClientSideSurlMsg('All fields requried');

        } else if(!isEmpty(title)) {

                    
                edittitle(formData)
                        .then(response => {
                            if(response.data.successMessage){
                                                setFormData({
                                                    ...formData,
                                                    successMsg:response.data.successMessage
                                                })
                                                setTimeout(() => {
                                                    dispatch(getLinks(userId));
                                                    history.goBack();
                                                }, 2000);
                                                
                                            }
                                        setClientSideSurlErrorMsg('');
                                        setClientSideSurlMsg('');
                                        setClientSideErrorMsg('');
                                    })
                                      
                .catch(err => {
                    console.log('edit title error:' , err);
                    setClientSideSurlMsg(err.response.data.errorMessage);
                    setClientSideSurlErrorMsg('false');
                }) 

                

                

        }

        }


        const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        const baseurl = `${protocol}//${domain}${port? port : ""}`

        
    

        return (
            <Fragment>
            <main role="main" className="container">
                <div className="jumbotron">
                    {/* <h1>Navbar example</h1> */}
                    {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#addLinkModal">Create Link</button> */}

                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                        <h1 class="h2">Change link title</h1>
                    </div>
                    <p><b>Short URL:</b> {shortUrl && `${baseurl}/${link.shortUrl}`}</p>
                    <p><b>Target URL:</b> {longUrl && longUrl}</p>
                    {/* <p className="lead">This example is a quick exercise to illustrate how fixed to top navbar works. As you scroll, it will remain fixed to the top of your browser's viewport.</p> */}
                    <div class="form-group">
                   
                    </div>
                </div>
            


                
            <div className='row pb-3'>

            <div class="modal-body">
                        <form onSubmit={handleSubmit} >
                        
                        <i className="fas fa-heading"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp; Title:</label>
                        <div class="input-group mb-3">
                        {clientSideSurlErrorMsg==='false' ? 
                            ( <input type="text" class="form-control form-control-lg is-invalid" name="title" value={title} onChange={handleTitle} aria-describedby="basic-addon3" />) 
                            : 
                            clientSideSurlErrorMsg==='true' ?  
                            ( <input type="text" class="form-control form-control-lg is-valid" name="title" value={title} onChange={handleTitle} aria-describedby="basic-addon3"/>) 
                              : 
                              ( <input type="text" class="form-control" id="basic-url" name="title" value={title} onChange={handleTitle} aria-describedby="basic-addon3"/>) 
                            }
                        
                        </div>
                        {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                        {successMsg && showSuccessMsg(successMsg)}
                    <div class="modal-footer">
                        <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Change </button>
                    </div>
                    </form>
                    </div>
               
            </div>

                            
                  
                    </main>
                
            </Fragment>
        );
        
    };

export default UEditTitle;