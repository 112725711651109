import axios from 'axios';
import { API_URL } from "./be";

axios.defaults.withCredentials = true;

export const signup = async (data) => {

    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/signup`, data, config);
    return response;
}

export const addmember = async (data) => {

    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/addmember`, data, config);
    return response;
}

export const signin = async (data) => {

    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/signin`, data, config);
    return response;
}

export const signurl = async (data) => {

    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/signurl`, data, config);
    return response;
}

export const checkisusername = async (data) => {

    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/checkisusername`, data, config);
    return response;
}

export const checkisshorturl = async (data) => {

    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/checkisshorturl`, data, config);
    return response;
}

export const editalias = async (data) => {

    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/edit/alias`, data, config);
    return response;
}

export const editprofile = async (data) => {

    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/edit/profile`, data, config);
    return response;
}

export const edittitle = async (data) => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/edit/title`, data, config);
    return response;
}

export const saveclick = async (data) => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/saveclick`, data, config);
    return response;
}

export const edittarget = async (data) => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/edit/target`, data, config);
    return response;
}
export const editpassword = async (data) => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/edit/password`, data, config);
    return response;
}

export const editprofilepass = async (data) => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/edit/profilepass`, data, config);
    return response;
}
export const editmobiletarget = async (data) => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/edit/mobiletarget`, data, config);
    return response;
}

export const editexpiration = async (data) => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/edit/expiration`, data, config);
    return response;
}
export const editutm = async (data) => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/edit/utm`, data, config);
    return response;
}

export const getlinkunique = async (data) => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/getlinkunique`, data, config);
    return response;
}

export const getlinkuniqueanalytics = async (data) => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/getlinkuniqueanalytics`, data, config);
    return response;
}

export const getalllinkunique = async (data) => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/getalllinkunique`, data, config);
    return response;
}

export const getallcampaigns = async (data) => {
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.get(`${API_URL}/api/auth/getallcampaigns`, data, config);
    return response;
}

export const bulkCreateLinks = async (links) => {
    try {
      const response = await axios.post(`${API_URL}/api/auth/links/bulk`, { links });
      return response;
    } catch (error) {
      throw new Error(error.response.data.errorMessage || 'Error creating bulk links');
    }
  };
  
  export const createCampaign = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/api/auth/campaigns`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    } catch (error) {
        throw new Error(error.response?.data?.errorMessage || 'Error creating campaign');
    }
};

export const getCampaigns = async (data) => {
    try {
        const config = {
            header: {
                'Content-Type': 'application/json'
            }
        }
        const response = await axios.get(`${API_URL}/api/auth/getallcampaigns`, data, config);
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.errorMessage || 'Error fetching campaigns');
    }
};

export const deleteCampaign = async (campaignId) => {
    try {
        const response = await axios.delete(`${API_URL}/api/auth/campaigns/${campaignId}`);
        return response;
    } catch (error) {
        throw new Error(error.response?.data?.errorMessage || 'Error deleting campaign');
    }
};
  
  export const exportCampaignLinks = async (campaignId) => {
    try {
      const response = await axios.get(`${API_URL}/api/auth/campaigns/${campaignId}/export`, {
        responseType: 'blob',
      });
      return response;
    } catch (error) {
      throw new Error('Error exporting campaign links');
    }
  };

  export const checkUrlExists = async (shortUrl) => {
    return axios.get(`${API_URL}/api/auth/check-url/${shortUrl}`);
};


  export const getCampaignStats = async (campaignId, userId) => {
    try {
        const response = await axios.get(`${API_URL}/api/auth/campaigns/${campaignId}/stats`, {
            params: { userId }, // URL parametresi olarak gönder
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            }
        });
        console.log('API Response:', response.data);
        return response.data;
    } catch (error) {
        if (error.response?.status === 404) {
            throw new Error('Campaign not found');
        }
        throw new Error(error.response?.data?.error || 'Error fetching campaign stats');
    }
};


export const recover = async (data) => {

    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/recover`, data, config);
    return response;
}

export const verify = async (data) => {

    const token =data;
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.get(`${API_URL}/api/auth/verify/`+token+``, config);
    return response;
}

export const getshorturl = async (data) => {

    const short =data;
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.get(`${API_URL}/api/auth/getshorturl/`+short+``, config);
    return response;
}

export const getteam = async (data) => {

    const teamid =data;
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.get(`${API_URL}/api/auth/getteam/`+teamid+``, config);
    return response;
}

export const gettitle = async (data) => {

    const config = {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            "Access-Control-Allow-Origin": "*",
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/gettitle`,data, config);


    return response;
}

export const gettitlealt = async (data) => {

    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/gettitlealt/`,data, config);


    return response;
}

export const getgeo = async (data) => {

    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.get(`${API_URL}/api/auth/getgeo/`, config);


    return response;
}

export const getua = async (data) => {

    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.get(`${API_URL}/api/auth/getua/`, config);


    return response;
}

export const reset = async (data) => {

    const token =data;
    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.get(`${API_URL}/api/auth/reset/`+token+``, config);
    return response;
}

export const resetpassword = async (data, token) => {

    const config = {
        header: {
            'Content-Type': 'application/json'
        }
    }
    const response = await axios.post(`${API_URL}/api/auth/reset/`+token+``, data, config);
    return response;
}