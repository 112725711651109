import React, {useState, useEffect, Fragment} from "react";
import { Link, useHistory } from "react-router-dom";
import { customAlphabet } from 'nanoid'
import axios from 'axios';
import {showErrorMsg,showSuccessMsg} from '../helpers/message';
import {showLoading} from '../helpers/loading';
import isEmpty from 'validator/lib/isEmpty';
import isUrl from 'validator/lib/isURL';
import { gettitle } from "../api/auth";
import { setAuthentication, isAuthenticated } from "../helpers/auth";
import './Dashboard.css';

//redux
import {useSelector, useDispatch} from 'react-redux';
import {createLink, getLink} from '../redux/actions/linkActions';


const UEditLink = ({match}) => {

const dispatch = useDispatch();

let history = useHistory();

const {link} = useSelector(state=>state.links);

//PARAMS
const linkId = match.params.linkId;

 //COMPONENT STATE PROPERTIES
 const [title, setTitle] = useState('');
 const [shortUrl, setShortUrl] = useState('');
 const [longUrl, setLongUrl] = useState('');

 //LIFECYCLE METHODS

 useEffect(() => {
    
    if(!link){
        dispatch(getLink(linkId));
    } 
    else {
        setTitle(link.title);
        setShortUrl(link.shortUrl);
        setLongUrl(link.longUrl);
    }

 }, [dispatch, linkId, link])

// useEffect(() => {
//     if(isAuthenticated() && isAuthenticated().role === 1) {
//         history.push('/admin/dashboard/');
//     } else if (isAuthenticated() && isAuthenticated().role === 0){
//         history.push('/user/dashboard/');
//     }
// },[history]);

useEffect(() => {

}, [])

 // COMPONENT GLOBAL STATE PROPERTIES 


const [clientSideErrorMsg,setClientSideErrorMsg] = useState('');
const [clientSideSurlErrorMsg,setClientSideSurlErrorMsg] = useState('');
const [clientSideSurlMsg,setClientSideSurlMsg] = useState('');
const [clientSideSucMsg,setClientSideSucMsg] = useState('');
const [modalState,setModalState] = useState(false);
const [formList,setFormList] = useState([]);



const nanoid = customAlphabet('1234567890abcdefghijklmnouprstuvyzwqx', 5)



        // EVENT HANDLER

        const handleChange = (evt) => {
            //console.log(evt);
    
            

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            }  if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            }  if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            } 
            
        } ;

        const handleShortUrl = (evt) => {
            //console.log(evt);
    
            

            if (isEmpty(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            if (!isEmpty(evt.target.value)){
                setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
            } 
            
        } ;

        const handlePaste = (evt) => {

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            }  if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            }  if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            } 
            
        } ;

        const handleMessages = (evt) => {
            

            setClientSideSurlErrorMsg('');
            setClientSideSurlMsg('');
            setClientSideErrorMsg('');

            setModalState(false);

        };

        const handleCreateClick = (evt) => {
            

            setModalState(true);

        };


        const handleBlur = (evt) => {
            //console.log(evt.target.value.length);
            // setFormData({
            //     ...formData,
            //     [evt.target.name]: evt.target.value,
            //     errorMsg: '',
            //     successMsg: '',    
            // });

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            } else if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            } else if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            }
    
        } 

        const handleSubmit = async (evt) => {
            evt.preventDefault();

        // client-side validation

        if(isEmpty(longUrl)) {
            setClientSideErrorMsg('false');

        } else if (!isUrl(longUrl)){
            setClientSideErrorMsg('false');  
        } else {

            const uri= longUrl;
            const data_title = {uri};
            //getTitle(uri)

                const sonForm = new FormData();
                sonForm.append('longUrl',longUrl)
                sonForm.append('shortUrl',shortUrl)
                sonForm.append('userId',isAuthenticated()._id)
            
            gettitle(data_title)
                .then(response => {
                    console.log(response.data.baslik);
                    // setFormData({
                    //                 ...formData,
                    //                 title: response.data.baslik,
                    //                 successMsg: response.data.baslik
                    //             });

                                sonForm.append('title',response.data.baslik)
                                //dispatch(createLink(sonForm)); 
                                // setFormData({
                                //     longUrl:'',
                                //     shortUrl:'',
                                //     //successMsg: false,
                                //     errorMsg: false,
                                //     loading: false  
                                // });     

                    
                })
                .catch(err => {
                    console.log('get title api error:', err);
                })

                
                //console.log(sonForm); 

                

                

        }

        }


        // const linkRender = links.filter(member => member.shortUrl)
        // const linkCount = linkRender.length;
        
        // const totalViews = links.map(points => points.views).reduce((acc, curr) => acc + parseInt(curr, 0), 0);

        const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        const baseurl = `${protocol}//${domain}${port? port : ""}`

        
    

        return (
            <Fragment>
            <main role="main" className="container">
                <div className="jumbotron">
                    {/* <h1>Navbar example</h1> */}
                    {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#addLinkModal">Create Link</button> */}

                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                        <h1 class="h2">Edit URL</h1>
                        <div class="btn-toolbar mb-2 mb-md-0">
                        <div class="btn-group mr-2">
                            <button class="btn btn-sm btn-outline-secondary">Share</button>
                            <button class="btn btn-sm btn-outline-secondary">Export</button>
                        </div>
                        <button class="btn btn-sm btn-outline-secondary dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span data-feather="calendar"></span>
                            This week
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a class="dropdown-item" href="#">Action</a></li>
                        <li><a class="dropdown-item" href="#">Another action</a></li>
                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                        </div>
                    </div>
                    {/* <p className="lead">This example is a quick exercise to illustrate how fixed to top navbar works. As you scroll, it will remain fixed to the top of your browser's viewport.</p> */}
                    <div class="form-group">
                   
                    </div>
                </div>

                

            


                
            <div className='row pb-3'>

            <div class="modal-body">
                        <form onSubmit={handleSubmit} >
                        <div class="form-group">
                            <i className="fas fa-heading"></i>
                            <label for="recipient-name" class="col-form-label">&nbsp;Title:</label>
                            {clientSideErrorMsg==='false' ? (
                            <input type="text" class="form-control form-control-lg is-invalid" name="longUrl" value={title} onChange={handleChange} placeholder='Write title' />
                            ) : 
                            clientSideErrorMsg==='true' ?  ( <input type="text" class="form-control form-control-lg is-valid" name="longUrl" value={longUrl}  onChange={handleChange} onBlur={handleBlur} placeholder='Paste URL' />
                             ) 
                              : 
                              ( <input type="text" class="form-control form-control-lg" name="longUrl" value={title} onChange={handleChange}  o placeholder='Write Title' />
                             ) 
                            }
                            
                        </div>
                        <div class="form-group">
                            <i className="fas fa-link"></i>
                            <label for="recipient-name" class="col-form-label">&nbsp;Long URL:</label>
                            {clientSideErrorMsg==='false' ? (
                            <input type="text" class="form-control form-control-lg is-invalid" name="longUrl" value={longUrl} onChange={handleChange} onPaste={handlePaste} onBlur={handleBlur} placeholder='Paste URL' />
                            ) : 
                            clientSideErrorMsg==='true' ?  ( <input type="text" class="form-control form-control-lg is-valid" name="longUrl" value={longUrl} onPaste={handlePaste} onChange={handleChange} onBlur={handleBlur} placeholder='Paste URL' />
                             ) 
                              : 
                              ( <input type="text" class="form-control form-control-lg" name="longUrl" value={longUrl} onChange={handleChange} onBlur={handleBlur} onPaste={handlePaste} placeholder='Paste URL' />
                             ) 
                            }
                            
                        </div>
                        <i className="fas fa-magic-wand-sparkles"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp;Short URL:</label>
                        <div class="input-group mb-3">
                        <span class="input-group-text has-validation" id="basic-addon3">{baseurl}</span>
                        {clientSideSurlErrorMsg==='false' ? 
                            ( <input type="text" class="form-control form-control-lg is-invalid" name="shortUrl" value={shortUrl} onChange={handleShortUrl} aria-describedby="basic-addon3" />) 
                            : 
                            clientSideSurlErrorMsg==='true' ?  
                            ( <input type="text" class="form-control form-control-lg is-valid" name="shortUrl" value={shortUrl} onChange={handleShortUrl} aria-describedby="basic-addon3"/>) 
                              : 
                              ( <input type="text" class="form-control" id="basic-url" name="shortUrl" value={shortUrl} onChange={handleShortUrl} aria-describedby="basic-addon3"/>) 
                            }
                        
                        <button class="btn btn-outline-success" type="button" id="button-addon2"><i class="fas fa-copy"></i>&nbsp;Copy</button>
                        </div>
                        {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                        {/* {successMsg && showSuccessMsg(successMsg)} */}
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-lg" data-bs-dismiss="modal" onClick={handleMessages} >Close</button>
                        <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Create</button>
                    </div>
                    </form>
                    </div>
               
            </div>

                            
                  
                    </main>
                
            </Fragment>
        );
        
    };

export default UEditLink;