import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getCampaignStats,exportCampaignLinks } from '../api/auth';
import { showErrorMsg, showSuccessMsg } from '../helpers/message';
import { showLoading } from '../helpers/loading';
import { isAuthenticated } from "../helpers/auth";

const CampaignDetails = () => {
    const { campaignId } = useParams();
    const [stats, setStats] = useState({
        campaignName: '',
        totalLinks: 0,
        totalClicks: 0
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        if (campaignId) {
            // userId'yi isAuthenticated()'dan al
            const userId = isAuthenticated()._id;
            fetchCampaignStats(campaignId, userId);
        }
    }, [campaignId]);

    const handleExport = async () => {
        try {
            const response = await exportCampaignLinks(campaignId);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Campaign_Links_${stats.campaignName}.csv`);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            //setErrorMsg('Error exporting campaign links');
            console.error('Error exporting campaign links:', error);
        }
    };

    const fetchCampaignStats = async () => {
        try {
            setLoading(true);
            setError('');
    
            const userId = isAuthenticated()._id;
            console.log('Fetching stats with:', { campaignId, userId }); // Debug için
    
            const campaignStats = await getCampaignStats(campaignId, userId);
            console.log('Received stats:', campaignStats); // Debug için
            setStats(campaignStats);
    
        } catch (err) {
            console.error('Error details:', err);
            setError(err.message || 'Error fetching campaign statistics');
        } finally {
            setLoading(false);
        }
    };

    if (loading) return (
        <div className="container mt-5">
            {showLoading()}
        </div>
    );

    if (error) return (
        <div className="container mt-5">
            {showErrorMsg(error)}
            <button 
                className="btn btn-primary mt-3"
                onClick={fetchCampaignStats}
            >
                Retry
            </button>
        </div>
    );

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-12 mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Campaign: {stats.campaignName}</h2>
                        <Link to="/e/campaigns" className="btn btn-secondary">
                            Back to Campaigns
                        </Link>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body text-center">
                            <h5 className="card-title">Total Links</h5>
                            <h2 className="display-4"> {stats.totalLinks.toLocaleString('tr') ? (stats.totalLinks.toLocaleString('tr')): (0)}</h2>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body text-center">
                            <h5 className="card-title">Total Clicks</h5>
                            <h2 className="display-4">{stats.totalClicks.toLocaleString('tr') ? (stats.totalClicks.toLocaleString('tr')): (0)}</h2>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body text-center">
                            <h5 className="card-title">Average CTR</h5>
                            <h2 className="display-4">
                                {stats.totalLinks > 0 
                                    ? `${((stats.totalClicks / stats.totalLinks) * 100).toFixed(1)}%`
                                    : '0%'
                                }
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title mb-4">Actions</h5>
                            <div className="d-flex gap-2">
                                {/* <a 
                                    href={`/api/campaigns/${campaignId}/export`}
                                    className="btn btn-success"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    
                                >
                                    Export Links
                                </a> */}
                                
                                {/* <Link 
                                    to={`/campaigns/${campaignId}/links`}
                                    className="btn btn-primary"
                                >
                                    View All Links
                                </Link> */}
                                <button className="btn btn-outline-success" onClick={handleExport}>
                                <i class="fas fa-cloud-download-alt"></i> Export Links
                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CampaignDetails;