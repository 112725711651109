import React, {useState, useEffect, Fragment} from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import { customAlphabet } from 'nanoid'
import {showErrorMsg,showSuccessMsg} from '../helpers/message';
import {showLoading, showLoadingGif} from '../helpers/loading';
import isEmpty from 'validator/lib/isEmpty';
import isUrl from 'validator/lib/isURL';
import { checkisshorturl, gettitle, gettitlealt, getalllinkunique} from "../api/auth";
import { setAuthentication, isAuthenticated } from "../helpers/auth";
import './Dashboard.css';
import Tooltip from './Tooltip';
import { Toast, Form } from 'react-bootstrap'
import ToastContainer from 'react-bootstrap/ToastContainer';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import QRCode from 'qrcode.react';
import { getLinksByFilter } from "../redux/actions/filterActions";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Pagination from './Pagination';
import PaginationChart from './PaginationChart';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';


import { Chart } from "react-google-charts";

import {useSelector, useDispatch,connect} from 'react-redux';
import {createLink, deleteLink,getLinks} from '../redux/actions/linkActions';
const queryString = require('query-string');


const UDashboard = () => {

let history = useHistory();
const dispatch = useDispatch();

const [data, setData] = useState([])
//const [loading, setLoading] = useState(true);

const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage] = useState(10);


const {links} = useSelector(state=>state.links);

const userId = isAuthenticated()._id;

useEffect(() => {
    if(isAuthenticated() && isAuthenticated().role === 10) {
        history.push('/admin/dashboard/');
    } else if (isAuthenticated() && isAuthenticated().role === 0){
        history.push('/user/dashboard/');
    }
    else if (isAuthenticated() && isAuthenticated().role === 1){
        history.push('/user/dashboard/');
    }
},[history]);


 // COMPONENT GLOBAL STATE PROPERTIES 

    


    

 
 const [show, setShow] = useState(false);
 const handleClose = () => setShow(false);
 const handleShow = () => setShow(true);


const [clientSideErrorMsg,setClientSideErrorMsg] = useState('');
const [clientSideSurlErrorMsg,setClientSideSurlErrorMsg] = useState('');
const [clientSideSurlMsg,setClientSideSurlMsg] = useState('');
const [clientSideSucMsg,setClientSideSucMsg] = useState('');
const [modalState,setModalState] = useState(false);
const [viewState,setViewState] = useState(0);
const [showToast, setToast] = useState(false)

const [qrUrl,setQrUrl] = useState('');
const [qrName,setQrName] = useState('');
const [deleteTitle,setDeleteTitle] = useState('');
const [deleteID,setDeleteID] = useState('');
const [stateDate, setStateDate] = useState(new Date().toISOString().split("T")[0]);

const [text, setText] = useState('');

const [showModal, setShowModal] = useState(false);
const [showDeleteModal, setShowDeleteModal] = useState(false);
const handleQRShow = () => setShowModal(true);
const handleQRClose = () => setShowModal(false);
const handleDeleteClose = () => setShowDeleteModal(false);
const handleDeleteShow = () => setShowDeleteModal(true);

const nanoid = customAlphabet('1234567890abcdefghijklmnouprstuvyzwqx', 5)

const [formData, setFormData] = useState({
    longUrl:'',
    shortUrl:nanoid(5),
    title:'',
    userId:isAuthenticated()._id,
    teamId: isAuthenticated().teamId,
    successMsg: false,
    errorMsg: false,
    loading: true,
    tekil:0,
    total_click:0,
    loading:true
});

const {longUrl, shortUrl, successMsg, teamId, tekil, total_click, errorMsg, loading } = formData;

useEffect(() => {

        if(userId) {
        getalllinkunique(formData)
                        .then(response => {
                                setFormData({
                                    ...formData,
                                    tekil: response.data.tekil,
                                    total_click: response.data.total_click,
                                    loading:false
                                })  
                                                
                                                
                         })
                                      
                .catch(err => {
                    console.log('get all link unique error:' , err);
                    
                }) 

        }
        
}, [userId])





        // EVENT HANDLER

        const handleSearch = e => {
            resetState();
            setText(e.target.value);
            
            dispatch(getLinksByFilter({type: 'text', query: e.target.value, userId:userId}));
            
            
        }

        const resetState = () => {
            setText('');
        }

        const handleViewCount = () => {
           setTimeout(() => {
            dispatch(getLinks(userId));
            if(userId) {
                getalllinkunique(formData)
                                .then(response => {
                                        setFormData({
                                            ...formData,
                                            tekil: response.data.tekil
                                        })  
                                                        
                                                        
                                 })
                                              
                        .catch(err => {
                            console.log('get all link unique error:' , err);
                            
                        }) 
        
                }
        }, 5000);
            
        } ;

        const handleDelete = () => {
            setTimeout(() => {
             dispatch(getLinks(userId));
             if(userId) {
                getalllinkunique(formData)
                                .then(response => {
                                        setFormData({
                                            ...formData,
                                            tekil: response.data.tekil,
                                            total_click:response.data.total_click
                                        })  
                                                        
                                                        
                                 })
                                              
                        .catch(err => {
                            console.log('get all link unique error:' , err);
                            
                        }) 
        
                }
         }, 500);
             
         } ;

         const handleDeleteLink = () => {

            dispatch(deleteLink(deleteID)); 
            handleDeleteClose();
            handleDelete();
            
             
         } ;



        const downloadQR = () => {
            const canvas = document.getElementById("4545");
            const pngUrl = canvas
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `${qrName}-qr-code.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          };
        

        const handleChange = (evt) => {
            //console.log(evt);
    
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
                errorMsg: '',
                   
            });

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            }  if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            }  if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            } 
            
        } ;

        const handleShortUrl = (evt) => {
            //console.log(evt);
    
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
                errorMsg: '',
                   
            });

            if (isEmpty(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            if (!isEmpty(evt.target.value)){
                setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
            } 
            
        } ;

        const handlePaste = (evt) => {

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            }  if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            }  if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            } 
            
        } ;

        const handleMessages = (evt) => {
            setFormData({
                ...formData,
                longUrl:'',
                shortUrl:'',
                successMsg: false,
                errorMsg: false,
                loading: false  
            });

            setClientSideSurlErrorMsg('');
            setClientSideSurlMsg('');
            setClientSideErrorMsg('');

            setModalState(false);
            setShow(false)

        };

        const handleCreateClick = (evt) => {
            setFormData({
                ...formData,
                longUrl:'',
                shortUrl: nanoid(5)
            });

            setModalState(true);
            setShow(true);


        };

        const handleBlur = (evt) => {
            //console.log(evt.target.value.length);
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
                errorMsg: '',
                successMsg: '',    
            });
            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            } else if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            } else if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            }
    
        } 
        
function gettitleWithTimeout(data_title, timeout = 5000) {
    return Promise.race([
        gettitle(data_title),
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Request timed out')), timeout)
        )
    ]);
}

const handleSubmit = async (evt) => {
    evt.preventDefault();

    // client-side validation
    if (isEmpty(longUrl)) {
        setClientSideErrorMsg('false');
    } else if (!isUrl(longUrl)) {
        setClientSideErrorMsg('false');
    } else if (!isEmpty(longUrl) && isUrl(longUrl) && !isEmpty(shortUrl)) {
        const shorturi = shortUrl;
        const suri = { shorturi };

        try {
            const response = await checkisshorturl(suri);
            if (response.data && response.data.taken) {
                setClientSideSurlErrorMsg('false');
                setClientSideSurlMsg(response.data.errorMessage);
            } else if (response.data && !response.data.taken) {
                const uri = longUrl;
                const data_title = { uri };

                const sonForm = new FormData();
                sonForm.append('longUrl', longUrl);
                sonForm.append('shortUrl', shortUrl);
                sonForm.append('userId', isAuthenticated()._id);

                try {
                    const titleResponse = await gettitleWithTimeout(data_title);
                    if (titleResponse.data) {
                        console.log(titleResponse.data.baslik);
                        var gbaslik = titleResponse.data.baslik || `Link-${linkCount + 1}`;
                    } else {
                        var gbaslik = `Link-${linkCount + 1}`;
                    }
                } catch (error) {
                    console.log('get title api error:', error);
                    var gbaslik = `Link-${linkCount + 1}`;
                }

                setFormData({
                    ...formData,
                    title: gbaslik,
                    successMsg: gbaslik
                });

                sonForm.append('title', gbaslik);
                dispatch(createLink(sonForm));

                handleDelete();
                setClientSideSurlErrorMsg('');
                setClientSideSurlMsg('');
                setClientSideErrorMsg('');
                setShow(false);
                setFormData({
                    longUrl: '',
                    shortUrl: '',
                    errorMsg: false,
                    loading: false
                });
            }
        } catch (error) {
            console.log('axios signup error:', error);
            if (error.response && error.response.data) {
                setClientSideSurlMsg(error.response.data.errorMessage);
                setClientSideSurlErrorMsg('false');
            } else {
                setClientSideSurlMsg('An unknown error occurred');
                setClientSideSurlErrorMsg('false');
            }
        }
    }
};



        
        //const linkCount = links.map(team => team.length);

        //const linkRender = links.filter(member => member.shortUrl)
        
        const linkRender = links ? links.filter(member => member.shortUrl) : [];
        

        const linkCount = linkRender.length;
        
        const totalViews = links ? links.map(points => points.views).reduce((acc, curr) => acc + parseInt(curr, 0), 0) : [];

        const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        const baseurl = `${protocol}//${domain}${port? port : ""}`

        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        const currentRecords2 = links.slice().reverse();
        const currentRecords = currentRecords2.slice(indexOfFirstRecord, indexOfLastRecord);
        const nPages = Math.ceil(links.length / recordsPerPage)

        const linkParams = currentRecords ? currentRecords.map(m => m.utmParamaters) : [];

        
        //console.log(linkParams);

        const params = []
        for(let p in linkParams){

            if(linkParams[p].length==0) {
                params.push('')
            }
            linkParams[p].map(t=>{    
                let urlObject = queryString.stringify({source: t.source, medium: t.medium, campaign: t.campaign, term: t.term, content: t.content}, { skipNulls: true, skipEmptyString: true });
                params.push(urlObject)
            })
        }

        const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};
        const copyToClipboard = (content) => {
            if (window.isSecureContext && navigator.clipboard) {
              navigator.clipboard.writeText(content);
            } else {
              unsecuredCopyToClipboard(content);
            }
          };
        
        //console.log(params)

        return (
            <Fragment>
            {/* <main role="main" className="h-screen flex-grow-1 overflow-y-lg-auto"> */}
            <header class="bg-surface-primary border-bottom pt-6">
            <div class="container-fluid">
                <div class="mb-npx">
                    <div class="row align-items-center">
                        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
                            
                            <h1 class="h2 mb-0 ls-tight">Dashboard</h1>
                        </div>
                        
                        <div class="col-sm-6 col-12 text-sm-end">
                            <div class="mx-n1">
                                
                                <a href="#" onClick={handleCreateClick} class="btn d-inline-flex btn-sm btn-primary mx-1">
                                    <span class=" pe-2">
                                        <i class="fa-solid fa-plus"></i>
                                    </span>
                                    <span>Create</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                    <ul class="nav nav-tabs mt-4 overflow-x border-0">
                       
                    </ul>
                </div>
            </div>
            </header>
            <main class="py-6 bg-surface-secondary">
                <div class="container-fluid">
                
                {/* // QR Code Link Modal */}

                <Modal show={showDeleteModal} onHide={handleDeleteClose} 
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                    <Modal.Header closeButton>
                    <Modal.Title>Delete Link</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="text-center">
                        <b>"{deleteTitle}"</b> link will delete, are you sure?
                    </div>
                    
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleDeleteClose}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={handleDeleteLink}>
                        Delete
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showModal} onHide={handleQRClose} 
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                    <Modal.Header closeButton>
                    <Modal.Title>QR Code Generator</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                        <div class="text-center">
                        <QRCode
                        id="4545"
                        value={qrUrl}
                        size={400}
                        level={"H"}
                        includeMargin={true}
                    />
                    </div>
                    <div class="text-center">{qrUrl && qrUrl}</div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleQRClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={downloadQR}>
                        Download QR
                    </Button>
                    </Modal.Footer>
                </Modal>
                
                <Modal show={show} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                    <Modal.Header closeButton>
                    <Modal.Title>Create New Link</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <form onSubmit={handleSubmit} >
                        <div class="form-group">
                            <i className="fas fa-link"></i>
                            <label for="recipient-name" class="col-form-label">&nbsp;Target URL:</label>
                            {clientSideErrorMsg==='false' ? (
                            <input type="text" class="form-control form-control-lg is-invalid" name="longUrl" value={longUrl} onChange={handleChange} onPaste={handlePaste} onBlur={handleBlur} placeholder='Paste URL' />
                            ) : 
                            clientSideErrorMsg==='true' ?  ( <input type="text" class="form-control form-control-lg is-valid" name="longUrl" value={longUrl} onPaste={handlePaste} onChange={handleChange} onBlur={handleBlur} placeholder='Paste URL' />
                             ) 
                              : 
                              ( <input type="text" class="form-control form-control-lg" name="longUrl" value={longUrl} onChange={handleChange} onBlur={handleBlur} onPaste={handlePaste} placeholder='Paste URL' />
                             ) 
                            }
                            
                        </div>
                        
                        <i className="fas fa-magic-wand-sparkles"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp;Short URL:</label>
                        <div class="input-group mb-3">
                        <span class="input-group-text has-validation" id="basic-addon3">muud.ly</span>
                        {clientSideSurlErrorMsg==='false' ? 
                            ( <input type="text" class="form-control form-control-lg is-invalid" name="shortUrl" value={shortUrl} onChange={handleShortUrl} aria-describedby="basic-addon3" />) 
                            : 
                            clientSideSurlErrorMsg==='true' ?  
                            ( <input type="text" class="form-control form-control-lg is-valid" name="shortUrl" value={shortUrl} onChange={handleShortUrl} aria-describedby="basic-addon3"/>) 
                              : 
                              ( <input type="text" class="form-control" id="basic-url" name="shortUrl" value={shortUrl} onChange={handleShortUrl} aria-describedby="basic-addon3"/>) 
                            }
                        
                        </div>
                        {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                        {successMsg && showSuccessMsg(successMsg)}
                        <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-lg" data-bs-dismiss="modal" onClick={handleMessages} >Close</button>
                        <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Create</button>
                    </div>
                    </form>
                    </Modal.Body>
                </Modal>

                <div className='row pb-3'>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
                            <div class="card shadow border-0">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col">
                                                <span class="h6 font-semibold text-muted text-sm d-block mb-2">All Links</span>
                                                <span class="h3 font-bold mb-0">{linkCount}</span>
                                                
                                            </div>
                                        </div>
                                        {/* <div class="mt-2 mb-0 text-sm">
                                        <span class="badge badge-pill bg-soft-success text-success me-2">
                                            <i class="bi bi-arrow-up me-1"></i>13%
                                        </span>
                                        <span class="text-nowrap text-xs text-muted">Since last month</span>
                                    </div> */}
                                    </div>
                                </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
                            <div class="card shadow border-0">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col">
                                                <span class="h6 font-semibold text-muted text-sm d-block mb-2">Unique Clicks</span>
                                                <span class="h3 font-bold mb-0">{tekil ? tekil.toLocaleString('tr') : 'N/A'}</span>
                                            </div>
                                            <div class="col-auto">
                                                <div class="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                                                    <i class="bi bi-credit-card"></i>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="mt-2 mb-0 text-sm">
                                            <span class="badge badge-pill bg-soft-danger text-danger me-2">
                                                <i class="bi bi-arrow-down me-1"></i>13%
                                            </span>
                                            <span class="text-nowrap text-xs text-muted">Since last month</span>
                                        </div> */}
                                    </div>
                                </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-6 col-12 mb-2 mt-4">
                        <div class="card shadow border-0">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col">
                                            <span class="h6 font-semibold text-muted text-sm d-block mb-2">Total Clicks</span>
                                            <span class="h3 font-bold mb-0">{total_click ? total_click.toLocaleString('tr') : 'N/A'}</span>
                                        </div>
                                        <div class="col-auto">
                                            <div class="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                                                <i class="bi bi-credit-card"></i>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="mt-2 mb-0 text-sm">
                                        <span class="badge badge-pill bg-soft-success text-success me-2">
                                            <i class="bi bi-arrow-up me-1"></i>13%
                                        </span>
                                        <span class="text-nowrap text-xs text-muted">Since last month</span>
                                    </div> */}
                                </div>
                            </div>
                    </div>
                </div>

                <div className="row">
                <div class="input-group">
                    <form class="form-control">
                <input type="text" name="search" value={text} onChange={handleSearch} class="form-control" placeholder="Search URL, title etc." aria-label="Search URL, title etc." aria-describedby="basic-addon2"/>
                
                    {/* <button class="btn btn-outline-primary" type="button">Search</button> */}
                    </form>
                </div>
                </div>
                    {/* LINKS TABLE */}
                    
                    {/* <div class="table-responsive ">
                        <table class="table table-hover table-borderless">
                        <thead>
                            <tr class="table-light text-center">
                            <th class="col-sm-6" >Short Links</th>
                            <th class="col-sm-5">Actions</th>
                            <th class="col-sm-2">Analytics</th>
                            </tr>
                        </thead>
                        <tbody class="table-light align-middle table-bordered">
                        {currentRecords && currentRecords.map((link,index) => (
                            
                            <tr key={link._id}>
                            <td>
                                <div class="row mb-2">
                                    <div class="ml-2">

                                    {isUrl(link.longUrl) && 
                                    <img src={`https://s2.googleusercontent.com/s2/favicons?domain=${link.longUrl}`}/>
                                    }
                                    {!isUrl(link.longUrl) && 
                                    <img src={``}/>
                                    }  
                                    
                                        <b> &nbsp; {link.title}</b>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="ml-2 mb-2">
                                   
                                    {params[index]
                                    ? <small>{link.longUrl}?{params[index]}</small>
                                    : <small>{link.longUrl}</small>
                                    }
                                                                         
                                    </div>
                                </div>
                                <div class="row">
                                <Link to={{pathname: `${baseurl}/${link.shortUrl}`}} target="_blank" onClick={handleViewCount}>{baseurl}/{link.shortUrl}</Link>
                                </div>
                                
                            </td>
                            <td>                       
                            <Tooltip content="Copy" direction="top" delay="0">
                            <a className="btn-lfy l_f" >
                            <i className="fas fa-clone" onClick={() => {copyToClipboard(`${baseurl}/${link.shortUrl}`);setToast(true)}}></i>
                            </a>
                            </Tooltip>
                            <Tooltip content="Change url alias/name" direction="top" delay="0">
                            <a href={`/edit/alias/${link._id}`} className="btn-lfy l_f" ><i className="fas fa-fingerprint"></i></a>
                            </Tooltip>
                            <Tooltip content="Change title" direction="top" delay="0">
                            <a href={`/edit/title/${link._id}`} className="btn-lfy l_f"><i className="fas fa-heading"></i></a>
                            </Tooltip>
                            <Tooltip content="Change target url" direction="top" delay="0">
                            <a href={`/edit/target/${link._id}`} className="btn-lfy l_f"><i className="fas fa-link"></i></a>
                            </Tooltip>
                            <Tooltip content="Mobile redirect" direction="top" delay="0">
                            <a href={`/edit/mobiletarget/${link._id}`} className="btn-lfy l_f"><i className="fas fa-mobile-screen-button"></i></a>
                            </Tooltip>
                            <Tooltip content="Set a password" direction="top" delay="0">
                            <a href={`/edit/password/${link._id}`} className="btn-lfy l_f"><i className="fas fa-key"></i></a>
                            </Tooltip>
                            <Tooltip content="UTM Generator / Campaign Tracker" direction="top" delay="0">
                            <a href={`/edit/utm/${link._id}`} className="btn-lfy l_f"><i className="fas fa-code"></i></a>
                            </Tooltip>
                            <Tooltip content="Link expiration" direction="top" delay="0">
                            <a href={`/edit/expiration/${link._id}`} className="btn-lfy l_f"><i className="fas fa-clock"></i></a>
                            </Tooltip>
                            
                            <Tooltip content="QR Code" direction="top" delay="0">
                            <a className="btn-lfy l_f" onClick={() => {handleQRShow(); setQrUrl(`${baseurl}/${link.shortUrl}`); setQrName(link.shortUrl)}}><i className="fas fa-qrcode"></i></a>
                            </Tooltip>
                            <Tooltip content="Remove link" direction="top" delay="0">
                            <a className="btn-lfy l_f" onClick={() => {handleDeleteShow();setDeleteID(link._id);setDeleteTitle(link.title);}}><i className="fas fa-trash-can"></i></a>
                            </Tooltip>
                                
                            </td>
                            
                            <td className="text-center">
                            <a href={`/insight/${link._id}`}><button class="btn"><i class="fas fa-chart-simple"></i> {link.views} Insights</button></a>
                            <span class="badge rounded-pill bg-dark">{link.userId.name}</span>
                            </td>
                            </tr>
                            ))}

                        </tbody>
                        </table>

                        <Pagination
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    </div> */}

                    {/* LINKS TABLE ANALYTICS  */}
                {

            loading ? (<div className='text-center'>{showLoadingGif()}</div>) : 
                
                (currentRecords.length>0) ? 
                (
                    <>
                <div class="card shadow border-0 mb-7">
                <div class="card-header">
                    <h5 class="mb-0">Links</h5>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover table-nowrap">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">Links</th>
                                <th scope="col"></th>
                                <th scope="col">Total Clicks</th>
                                <th scope="col">Date/Creator</th>
                            </tr>
                        </thead>
                        <tbody>
                        {currentRecords && currentRecords.map((link,index) => (
                            <tr key={link._id}>
                                <td>
                                <div class="row mb-2 ms-1">
                                    <div class="ml-2">

                                    {isUrl(link.longUrl) && 
                                    <img src={`https://s2.googleusercontent.com/s2/favicons?domain=${link.longUrl}`}/>
                                    }
                                    {!isUrl(link.longUrl) && 
                                    <img src={``}/>
                                    }  
                                        <b> &nbsp; {link.title}</b>
                                    </div>
                                </div>
                                <div class="row ms-1">
                                    <div class="ml-2 mb-2">
                                
                                    {params[index]
                                    ? <small>{link.longUrl}?{params[index]}</small>
                                    : <small>{link.longUrl}</small>
                                    }
                                                                        
                                    </div>
                                </div>
                                <div class="row ms-1">
                                    <div class="col">
                                    <Link to={{pathname: `${baseurl}/${link.shortUrl}`}} target="_blank" onClick={handleViewCount}>{baseurl}/{link.shortUrl}</Link>
                                    <Tooltip content="Copy link" direction="top" delay="0">
                                        
                                        <i className="fas fa-clone ms-2" onClick={() => {copyToClipboard(`${baseurl}/${link.shortUrl}`);setToast(true)}}></i>
                                        
                                    </Tooltip>
                                    </div>
                                    <div class="col">
                                    </div>
                            
                                </div>
                                    
                                </td>
                                <td>
                                            {/* <a onClick={() => {copyToClipboard(`${baseurl}/${link.shortUrl}`);setToast(true)}} class="btn btn-sm btn-neutral border-base mx-1">
                                                                <i class="fas fa-clone"></i>
                                                    </a> */}
                                            <a href={`/edit/${link._id}`} class="btn d-inline-flex btn-sm btn-neutral border-base mx-1">
                                                <span class=" pe-2">
                                                    <i class="fa-solid fa-pencil"></i>
                                                </span>
                                                <span>Edit</span>
                                            </a>
                                            <a onClick={() => {handleDeleteShow();setDeleteID(link._id);setDeleteTitle(link.title);}} class="btn btn-sm btn-neutral border-base mx-1">
                                                    <i class="fa-solid fa-trash"></i>
                                            </a>
                                </td>
                                <td>
                                    <a href={`/insight/${link._id}`} class="btn btn-sm btn-neutral border-base mx-1"> <i class="fa-solid fa-chart-simple"></i> {link.views.toLocaleString('tr') ? (link.views.toLocaleString('tr')): (0)}
                                    </a>                                        
                                </td>
                                <td>
                                <div class="row mb-2">
                                    <div class="ml-2 text-center">
                                        <Tooltip content={moment(link.createdAt).format('MMMM Do YYYY, h:mm:ss a')} direction="top" delay="0">
                                        {moment(link.createdAt).fromNow()}
                                        </Tooltip>
                                    </div>
                                    </div>
                                        <div class="row mb-2">
                                            <div class="ml-2 text-center">
                                            <span class="badge rounded-pill bg-dark">{link.userId.name}</span> 
                                        </div>
                                    </div>
                                </td>
                                
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div class="card-footer border-0 py-5">
                    <div class="row align-items-center">
                    <div class="col-sm-4">
                    <span class="text-muted text-sm-2">Showing {recordsPerPage} items out of {links.length} results found</span>
                    </div>
                    <div class="col-sm-8">
                        <PaginationChart
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            itemCount= {links.length}
                            recordsPerPage= {recordsPerPage}
                        />
                    </div>
                    
                    </div>
                </div>
            </div>
            </>
            ): 
                (
                    <>
                    <div className="row p-3">
                    <div class="mb-1 px-5">
                        <h4 class="mb-1">You have not yet created any QR Codes and Smart Links</h4><p class="text-sm text-muted">You can use the Create button on the top right to start using this great QR Code and Smart Link solution.</p>
                        </div>
                        <img class="px-5 pr-5" src="../images/content.jpg" alt=""/>
                    </div>
                    </>
                )
                    
                }

                    {/* // Toast Container */}
                    <div>
                        <ToastContainer className="p-3" position='bottom-end'>
                        <Toast
                            onClose={() => setToast(false)}
                            autohide
                            show={showToast}
                            delay={3000}
                        >
                            <Toast.Header>
                            <strong className="mr-auto">muud.ly</strong>
                            
                            </Toast.Header>
                            <Toast.Body>URL has been copied.</Toast.Body>
                        </Toast>
                        </ToastContainer>
                    </div>
                    </div>
                    </main>
                    {/* </main> */}
                
            </Fragment>
        );



        };

  

export default UDashboard;

