import React, {useState, useEffect, Fragment} from "react";
import { Link, useHistory } from "react-router-dom";
import {showErrorMsg,showSuccessMsg} from '../helpers/message';
import {showLoading} from '../helpers/loading';
import isEmpty from 'validator/lib/isEmpty';
import isUrl from 'validator/lib/isURL';
import { editmobiletarget } from "../api/auth";
import { isAuthenticated } from "../helpers/auth";
import Tooltip from './Tooltip';
import './Dashboard.css';
import { Toast, Form } from 'react-bootstrap'
import ToastContainer from 'react-bootstrap/ToastContainer';

//redux
import {useSelector, useDispatch} from 'react-redux';
import {getLink,getLinks} from '../redux/actions/linkActions';


const UEditMobileTarget = ({match}) => {

const dispatch = useDispatch();

let history = useHistory();

const {link} = useSelector(state=>state.links);

const [checkState,setCheckState] = useState(false);

//PARAMS
const linkId = match.params.linkId;

 //COMPONENT STATE PROPERTIES
 const [formData, setFormData] = useState({
    linkId:linkId,
    shortUrl:'',
    longUrl:'',
    title:'',
    redirectPlatforms:[],
    userId:isAuthenticated()._id,
    android:'',
    ios:'',
    web:'',
    deep:'',
    platformid:'',
    successMsg: false,
    errorMsg: false,
    loading: false  
});


const {longUrl, shortUrl,title, userId, android, ios,platformid, web, deep, redirectPlatforms, successMsg, errorMsg, loading } = formData;
//const [shortUrl, setShortUrl] = useState('');


 //LIFECYCLE METHODS

 useEffect(() => {
    
    if(!link){
        dispatch(getLink(linkId));
    } 
    else {
        
        setFormData({
            ...formData,
            shortUrl:link.shortUrl,
            longUrl:link.longUrl,
            title:link.title,
            redirectPlatforms: link.redirectPlatforms,
            
        });
        
    }

 }, [dispatch, linkId, link])

 

// useEffect(() => {
//     if(isAuthenticated() && isAuthenticated().role === 1) {
//         history.push('/admin/dashboard/');
//     } else if (isAuthenticated() && isAuthenticated().role === 0){
//         history.push('/user/dashboard/');
//     }
// },[history]);

useEffect(() => {
    redirectPlatforms.map((p) => {
        const { android, ios, web, deep } = p;
        setFormData({
            ...formData,
            android:android,
            ios:ios,
            web:web,
            deep:deep,
            platformid:platformid,
        });
        
      });
}, [redirectPlatforms])

useEffect(() => {
        
}, [])




 // COMPONENT GLOBAL STATE PROPERTIES 


const [clientSideErrorMsg,setClientSideErrorMsg] = useState('');
const [clientSideIosErrorMsg,setClientSideIosErrorMsg] = useState('');
const [clientSideAndroidErrorMsg,setClientSideAndroidErrorMsg] = useState('');
const [clientSideDeepErrorMsg,setClientSideDeepErrorMsg] = useState('');
const [clientSideWebErrorMsg,setClientSideWebErrorMsg] = useState('');
const [clientSideSurlMsg,setClientSideSurlMsg] = useState('');
const [clientSideSucMsg,setClientSideSucMsg] = useState('');
const [modalState,setModalState] = useState(false);
const [showToast, setToast] = useState(false)



        // EVENT HANDLER

        const handleChange = (evt) => {
            //console.log(evt);
    
            

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            }  if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            }  if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            } 
            
        } ;

        const validUri = new RegExp('([a-zA-Z]{2,30}):\/\/([a-zA-Z]+(?:(?:\.[\w_-]+)?))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?');



        const handleIos = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });

            setClientSideSurlMsg('');
            
            if (!isUrl(evt.target.value)) {
                setClientSideIosErrorMsg('false');
            } 
             if (isEmpty(evt.target.value)){
                setClientSideIosErrorMsg('');
            } 
             if (isUrl(evt.target.value)){
                setClientSideIosErrorMsg('true');
            }
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                // setClientSideSurlMsg('');
                // setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleAndroid = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });

            setClientSideSurlMsg('');
            
            if (!isUrl(evt.target.value)) {
                setClientSideAndroidErrorMsg('false');
            } 
            if (isEmpty(evt.target.value)){
                setClientSideAndroidErrorMsg('');
            } 
             if (isUrl(evt.target.value)){
                setClientSideAndroidErrorMsg('true');
            }
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                // setClientSideSurlMsg('');
                // setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleWeb = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });

            setClientSideSurlMsg('');
            
            if (!isUrl(evt.target.value)) {
                setClientSideWebErrorMsg('false');
            } 
             if (isEmpty(evt.target.value)){
                setClientSideWebErrorMsg('');
            } 
             if (isUrl(evt.target.value)){
                setClientSideWebErrorMsg('true');
            }
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                // setClientSideSurlMsg('');
                // setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleDeep = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });

            setClientSideSurlMsg('');

            console.log(validUri.test(evt.target.value))
            
            if (!validUri.test(evt.target.value)) {
                setClientSideDeepErrorMsg('false');
            } 
             if (isEmpty(evt.target.value)){
                setClientSideDeepErrorMsg('');
            } 
             if (validUri.test(evt.target.value)){
                setClientSideDeepErrorMsg('true');
            }
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                // setClientSideSurlMsg('');
                // setClientSideSurlErrorMsg('');
            } 
            
        } ;


        const handleSubmit = async (evt) => {
            evt.preventDefault();

        // client-side validation

        if(!isEmpty(ios) && !isUrl(ios)) {
            setClientSideSurlMsg('URLs contain invalid characters. Please enter valid URL.');
        } 
        else if (!isEmpty(android) && !isUrl(android)){
            setClientSideSurlMsg('URLs contain invalid characters. Please enter valid URL.');
        }
        else if (!isEmpty(web) && !isUrl(web)){
            setClientSideSurlMsg('URLs contain invalid characters. Please enter valid URL.');
        }
        else if (deep!=undefined && deep!=null && deep!='' && !validUri.test(deep)){
            setClientSideSurlMsg('Deeplink contain invalid characters. Please enter valid Deeplink.');
        }
        
        else  {
                //console.log(formData);

                editmobiletarget(formData)
                        .then(response => {
                            if(response.data.successMessage){
                                                setFormData({
                                                    ...formData,
                                                    successMsg:response.data.successMessage
                                                })
                                                setTimeout(() => {
                                                    dispatch(getLinks(userId));
                                                    history.go("/user/dashboard/");
                                                }, 2000);
                                                
                                            }
                                        setClientSideIosErrorMsg('');
                                        setClientSideAndroidErrorMsg('');
                                        setClientSideWebErrorMsg('');
                                        setClientSideSurlMsg('');
                                        setClientSideErrorMsg('');
                                    })
                                      
                .catch(err => {
                    console.log('edit mobile target error:' , err);
                    setClientSideSurlMsg(err.response.data.errorMessage);
                    //setClientSideIosErrorMsg('false');
                }) 

                

                

        }

        }



        const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};
        const copyToClipboard = (content) => {
            if (window.isSecureContext && navigator.clipboard) {
              navigator.clipboard.writeText(content);
            } else {
              unsecuredCopyToClipboard(content);
            }
          };

        const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        const baseurl = `${protocol}//${domain}${port? port : ""}`

        

        return (
            <Fragment>
            <header class="bg-surface-primary border-bottom pt-6">
            <div class="container-fluid">
                <div class="mb-npx">              
                    <div className='row'>
                    <div class="row mb-2">
                                            <div class=" ml-2">

                                            {isUrl(longUrl) && 
                                            <img src={`https://s2.googleusercontent.com/s2/favicons?domain=${longUrl}`}/>
                                            }
                                            {!isUrl(longUrl) && 
                                            <img src={``}/>
                                            }  
                                            
                                            <b> &nbsp; {title && title} </b>
                                            
                                            
                                            
                                                
                                            </div>
                        </div>
                    <div class="row">
                                            <div class="col ml-2 mb-2">
                                        
                                            
                                             <small>{longUrl && longUrl}</small>
                                                                                
                                            </div>
                        </div>
                    <div class="row">
                                            <div class="col">
                                            <Link to={{pathname: `${baseurl}/${shortUrl}`}} target="_blank" >{baseurl}/{shortUrl}</Link>
                                            <Tooltip content="Copy link" direction="top" delay="0">
                                                
                                                <i className="fas fa-clone ms-2" onClick={() => {copyToClipboard(`${baseurl}/${shortUrl}`);setToast(true)}}></i>
                                                
                                            </Tooltip>
                                            </div>
                                            <div class="col">
                                            </div>
                                    
                        </div>
                    </div>
                    
                    <ul class="nav nav-tabs mt-4 overflow-x border-0">
                    <li class="nav-item ">
                            <a href={`/edit/${linkId}`} class="nav-link font-regular">Title</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/alias/${linkId}`} class="nav-link font-regular">Alias Name</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/target/${linkId}`} class="nav-link font-regular">Target Url</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/mobiletarget/${linkId}`} class="nav-link active">Mobile Redirect</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/password/${linkId}`} class="nav-link font-regular">Password</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/utm/${linkId}`} class="nav-link font-regular">UTM Generator</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/expiration/${linkId}`} class="nav-link font-regular">Expiration</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/qr/${linkId}`} class="nav-link font-regular">QR Code</a>
                        </li>
                    </ul>
                </div>
            </div>
            </header>
            <main class="py-6 bg-surface-secondary">
                <div class="container-fluid max-w-screen-md vstack gap-6">
                
            
            
            <div class="card">
                <div class="card-body">
                <div class="mb-1">
                <h4 class="mb-1">Mobile Redirect & Deeplink</h4><p class="text-sm text-muted">Redirect users to mobile devices and app stores</p>
                </div>
                    <div class="modal-body">
                            <form onSubmit={handleSubmit} >
                            <i className="fab fa-apple"></i>
                            <label for="recipient-name" class="col-form-label">&nbsp; URL for Apple devices (iPhone & iPad):</label>
                            &nbsp;
                            <Tooltip content="E.g https://itunes.apple.com/us/app/your-appfid1234567?mt=8" direction="right" delay="0">
                            <i className="fas fa-circle-info"></i>
                            </Tooltip>
                            
                            <div class="input-group mb-2">
                            {clientSideIosErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="ios" value={ios} onChange={handleIos} aria-describedby="basic-addon3" />) 
                                : 
                                clientSideIosErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="ios" value={ios} onChange={handleIos} aria-describedby="basic-addon3"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="ios" value={ios} onChange={handleIos} aria-describedby="basic-addon3"/>) 
                                }

                            </div>

                            <i className="fab fa-android"></i>
                            <label for="recipient-name" class="col-form-label">&nbsp; URL for Android devices:</label>
                            &nbsp;
                            <Tooltip content="E.g https://play.google.com/store/apps/details?id=your-app" direction="right" delay="0">
                            <i className="fas fa-circle-info"></i>
                            </Tooltip>
                            <div class="input-group mb-2">
                            {clientSideAndroidErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="android" value={android} onChange={handleAndroid} aria-describedby="basic-addon3" />) 
                                : 
                                clientSideAndroidErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="android" value={android} onChange={handleAndroid} aria-describedby="basic-addon3"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="android" value={android} onChange={handleAndroid} aria-describedby="basic-addon3"/>) 
                                }
                            
                            </div>
                            <i className="fas fa-desktop"></i>
                            <label for="recipient-name" class="col-form-label">&nbsp; URL for other devices:</label>
                            &nbsp;
                            <Tooltip content="Alternative URL - Desktop web site etc." direction="right" delay="0">
                            <i className="fas fa-circle-info"></i>
                            </Tooltip>
                            <div class="input-group mb-2">
                            {clientSideWebErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="web" value={web} onChange={handleWeb} aria-describedby="basic-addon3" />) 
                                : 
                                clientSideWebErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="web" value={web} onChange={handleWeb} aria-describedby="basic-addon3"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="web" value={web} onChange={handleWeb} aria-describedby="basic-addon3"/>) 
                                }
                            
                            </div>
                            <i class="fa-solid fa-mobile-screen-button"></i>
                            <label for="recipient-name" class="col-form-label">&nbsp; Deeplink - Universal Link:</label>
                            &nbsp;
                            <Tooltip content="Open direct app if install on device - E.g appname://detail/1234 or fb://profile/1234" direction="right" delay="0">
                            <i className="fas fa-circle-info"></i>
                            </Tooltip>
                            <div class="input-group mb-2">
                            {clientSideDeepErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="deep" value={deep} onChange={handleDeep} aria-describedby="basic-addon3" />) 
                                : 
                                clientSideDeepErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="deep" value={deep} onChange={handleDeep} aria-describedby="basic-addon3"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="deep" value={deep} onChange={handleDeep} aria-describedby="basic-addon3"/>) 
                                }
                            
                            </div>
                            {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                            {successMsg && showSuccessMsg(successMsg)}
                        <div class="modal-footer pt-3 ">
                            <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Save </button>
                        </div>
                        </form>
                    </div>

                </div>
                </div>            
               
            </div>

            {/* // Toast Container */}
            <div>
                        <ToastContainer className="p-3" position='bottom-end'>
                        <Toast
                            onClose={() => setToast(false)}
                            autohide
                            show={showToast}
                            delay={3000}
                        >
                            <Toast.Header>
                            <strong className="mr-auto">muud.ly</strong>
                            </Toast.Header>
                            <Toast.Body>URL has been copied.</Toast.Body>
                        </Toast>
                        </ToastContainer>
                    </div>
                            
                  
                    </main>
                
            </Fragment>
        );
        
    };

export default UEditMobileTarget;