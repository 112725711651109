import React from 'react'

const PaginationChart = ({ nPages, currentPage, setCurrentPage, itemCount, recordsPerPage }) => {

    const pageNumbers = [...Array(nPages + 1).keys()].slice(1)
    

    const nextPage = () => {
            
            if(currentPage !== nPages) setCurrentPage(currentPage + 1)
    }
    const prevPage = () => {
        
        if(currentPage !== 1) setCurrentPage(currentPage - 1)
    }
    return (
        <nav>
            <ul className='pagination justify-content-end'>
                <li className="page-item">
                    <a className="page-link" 
                        onClick={prevPage} 
                        href='#!'>
                        <i class="fas fa-chevron-left"></i>
                    </a>
                </li>
                {/* {pageNumbers.map(pgNumber => (
                    <li key={pgNumber} 
                        className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >

                        <a onClick={() => setCurrentPage(pgNumber)}  
                            className='page-link' 
                            href='#!'>
                            
                            {pgNumber}
                        </a>
                    </li>
                ))} */}
                <li className='page-link'>
                {currentPage===1 && 
                      <>  1-{currentPage*recordsPerPage} / showing {itemCount} records</>
                }

                {currentPage>1 && 
                      <>  {(currentPage-1)*recordsPerPage}-{currentPage*recordsPerPage} / showing {itemCount} records</>
                }
                   
                    
                    
                    </li>
                <li className="page-item">
                    <a className="page-link" 
                        onClick={nextPage}
                        href='#!'>
                        <i class="fas fa-chevron-right"></i>
                    </a>
                </li>
            </ul>
        </nav>
    )
}

export default PaginationChart;