import React, {useState, useEffect, Fragment} from "react";
import { Link, useHistory } from "react-router-dom";
import {showErrorMsg,showSuccessMsg} from '../helpers/message';
import {showLoading} from '../helpers/loading';
import isEmpty from 'validator/lib/isEmpty';
import isUrl from 'validator/lib/isURL';
import { editexpiration } from "../api/auth";
import { isAuthenticated } from "../helpers/auth";
import Tooltip from './Tooltip';
import './Dashboard.css';
import { Toast, Form } from 'react-bootstrap'
import ToastContainer from 'react-bootstrap/ToastContainer';
import QRCode from 'qrcode.react';

//redux
import {useSelector, useDispatch} from 'react-redux';
import {getLink,getLinks} from '../redux/actions/linkActions';


const UEditQR = ({match}) => {

const dispatch = useDispatch();

let history = useHistory();

const {link} = useSelector(state=>state.links);

const [checkState,setCheckState] = useState(false);

//PARAMS
const linkId = match.params.linkId;

 //COMPONENT STATE PROPERTIES
 const [formData, setFormData] = useState({
    linkId:linkId,
    shortUrl:'',
    longUrl:'',
    title:'',
    linkExpires:[],
    userId:isAuthenticated()._id,
    date:new Date().toISOString().split("T")[0],
    link_exp:'',
    views:'',
    views_type:'',
    successMsg: false,
    errorMsg: false,
    loading: false  
});


const {longUrl, shortUrl,title, userId, link_exp, views, date, views_type, linkExpires, successMsg, errorMsg, loading } = formData;
//const [shortUrl, setShortUrl] = useState('');

const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        const baseurl = `${protocol}//${domain}${port? port : ""}`

 //LIFECYCLE METHODS

 useEffect(() => {
    
    if(!link){
        dispatch(getLink(linkId));
    } 
    else {
        
        setFormData({
            ...formData,
            shortUrl:link.shortUrl,
            longUrl:link.longUrl,
            title:link.title,
            linkExpires: link.linkExpires,
            
        });

        setQrUrl(`${baseurl}/${link.shortUrl}`)
        
    }

 }, [dispatch, linkId, link])

 

// useEffect(() => {
//     if(isAuthenticated() && isAuthenticated().role === 1) {
//         history.push('/admin/dashboard/');
//     } else if (isAuthenticated() && isAuthenticated().role === 0){
//         history.push('/user/dashboard/');
//     }
// },[history]);

useEffect(() => {
    
}, [])

useEffect(() => {
        
}, [])




 // COMPONENT GLOBAL STATE PROPERTIES 


const [clientSideErrorMsg,setClientSideErrorMsg] = useState('');
const [clientSideLinkErrorMsg,setClientSideLinkErrorMsg] = useState('');
const [clientSideAndroidErrorMsg,setClientSideAndroidErrorMsg] = useState('');
const [clientSideWebErrorMsg,setClientSideWebErrorMsg] = useState('');
const [clientSideSurlMsg,setClientSideSurlMsg] = useState('');
const [clientSideSucMsg,setClientSideSucMsg] = useState('');
const [modalState,setModalState] = useState(false);
const [showToast, setToast] = useState(false);
const [qrUrl,setQrUrl] = useState('');
const [bgColor,setbgColor] = useState('#ffffff');
const [foreColor,setforeColor] = useState('#000000');

const [previewImage, setPreviewImage] = useState(null);
const [uploadedImage, setUploadedImage] = useState(null);



        // EVENT HANDLER

        const handleChange = (evt) => {

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            }  if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            }  if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            } 
            
        } ;

        const handleLink = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });

            setClientSideSurlMsg('');
            
            if (!isUrl(evt.target.value)) {
                setClientSideLinkErrorMsg('false');
            } 
             if (isEmpty(evt.target.value)){
                setClientSideLinkErrorMsg('');
            } 
             if (isUrl(evt.target.value)){
                setClientSideLinkErrorMsg('true');
            }
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                // setClientSideSurlMsg('');
                // setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleAndroid = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });

            setClientSideSurlMsg('');
            
            if (!isUrl(evt.target.value)) {
                setClientSideAndroidErrorMsg('false');
            } 
            if (isEmpty(evt.target.value)){
                setClientSideAndroidErrorMsg('');
            } 
             if (isUrl(evt.target.value)){
                setClientSideAndroidErrorMsg('true');
            }
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                // setClientSideSurlMsg('');
                // setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleWeb = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });

            setClientSideSurlMsg('');
            
            if (!isUrl(evt.target.value)) {
                setClientSideWebErrorMsg('false');
            } 
             if (isEmpty(evt.target.value)){
                setClientSideWebErrorMsg('');
            } 
             if (isUrl(evt.target.value)){
                setClientSideWebErrorMsg('true');
            }
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                // setClientSideSurlMsg('');
                // setClientSideSurlErrorMsg('');
            } 
            
        } ;


        const handleSubmit = async (evt) => {
            evt.preventDefault();

        // client-side validation

        if(!isEmpty(link_exp) && !isUrl(link_exp)) {
            setClientSideSurlMsg('URLs contain invalid characters. Please enter valid URL.');
        } 
        
        
        else  {

                //console.log(formData);

                editexpiration(formData)
                        .then(response => {
                            if(response.data.successMessage){
                                                setFormData({
                                                    ...formData,
                                                    successMsg:response.data.successMessage
                                                })
                                                setTimeout(() => {
                                                    dispatch(getLinks(userId));
                                                    history.go("/user/dashboard/");
                                                }, 2000);
                                                
                                            }
                                        setClientSideLinkErrorMsg('');
                                        setClientSideAndroidErrorMsg('');
                                        setClientSideWebErrorMsg('');
                                        setClientSideSurlMsg('');
                                        setClientSideErrorMsg('');
                                    })
                                      
                .catch(err => {
                    console.log('edit expiration target error:' , err);
                    setClientSideSurlMsg(err.response.data.errorMessage);
                    //setClientSideIosErrorMsg('false');
                }) 

                

                

        }

        }

        const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};
        const copyToClipboard = (content) => {
            if (window.isSecureContext && navigator.clipboard) {
              navigator.clipboard.writeText(content);
            } else {
              unsecuredCopyToClipboard(content);
            }
          };


          const handleUploadImage = () => {
            const data = new FormData();
            data.append('files[]', previewImage);
        
            fetch('/* server url */', { method: 'POST', body: data }).then(async (response) => {
                const imageResponse = await response.json();
                setUploadedImage(imageResponse);
            }).catch((err) => {
        
            });
        }
        
        const handleSelectImage = (event) => {
            const file = event.target.files[0];
            const fileReader = new FileReader();
            fileReader.addEventListener("load", () => {
                setPreviewImage(fileReader.result);
            });
            fileReader.readAsDataURL(file);
        }

        const handleRemoveImage = () => {
            setPreviewImage(null);
        }

        
          const downloadQR = () => {
            const canvas = document.getElementById("4545");
            const pngUrl = canvas
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `${qrUrl}-qr-code.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          };
        
        

        return (
            <Fragment>
            <header class="bg-surface-primary border-bottom pt-6">
            <div class="container-fluid">
                <div class="mb-npx">              
                    <div className='row'>
                    <div class="row mb-2">
                                            <div class=" ml-2">

                                            {isUrl(longUrl) && 
                                            <img src={`https://s2.googleusercontent.com/s2/favicons?domain=${longUrl}`}/>
                                            }
                                            {!isUrl(longUrl) && 
                                            <img src={``}/>
                                            }  
                                            
                                            <b> &nbsp; {title && title} </b>
                                            
                                            
                                            
                                                
                                            </div>
                        </div>
                    <div class="row">
                                            <div class="col ml-2 mb-2">
                                        
                                            
                                             <small>{longUrl && longUrl}</small>
                                                                                
                                            </div>
                        </div>
                    <div class="row">
                                            <div class="col">
                                            <Link to={{pathname: `${baseurl}/${shortUrl}`}} target="_blank" >{baseurl}/{shortUrl}</Link>
                                            <Tooltip content="Copy link" direction="top" delay="0">
                                                
                                                <i className="fas fa-clone ms-2" onClick={() => {copyToClipboard(`${baseurl}/${shortUrl}`);setToast(true)}}></i>
                                                
                                            </Tooltip>
                                            </div>
                                            <div class="col">
                                            </div>
                                    
                        </div>
                    </div>
                    
                    <ul class="nav nav-tabs mt-4 overflow-x border-0">
                        <li class="nav-item ">
                            <a href={`/edit/${linkId}`} class="nav-link font-regular">Title</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/alias/${linkId}`} class="nav-link font-regular">Alias Name</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/target/${linkId}`} class="nav-link font-regular">Target Url</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/mobiletarget/${linkId}`} class="nav-link font-regular">Mobile Redirect</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/password/${linkId}`} class="nav-link font-regular">Password</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/utm/${linkId}`} class="nav-link font-regular">UTM Generator</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/expiration/${linkId}`} class="nav-link font-regular">Expiration</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/qr/${linkId}`} class="nav-link active">QR Code</a>
                        </li>
                    </ul>
                </div>
            </div>
            </header>
            <main class="py-6 bg-surface-secondary">
            <div class="container-fluid max-w-screen-md vstack gap-6">
                
                <div class="card">
                <div class="card-body">
                <div class="mb-1">
                <h4 class="mb-1">QR Code Generator</h4><p class="text-sm text-muted">Customize your QR Code</p>
                </div>
                <div class="row pt-4">
                <div class="col-6">
                        
                <div class="pb-3">
                <Form.Label htmlFor="exampleColorInput">Select Color</Form.Label>
                <Form.Control
                    type="color"
                    id="exampleColorInput"
                    defaultValue="#000000"
                    title="Choose your color"
                    onChange={(event) => { setforeColor(event.target.value) }}
                />
                </div>

                <div class="pb-3">
                <Form.Label htmlFor="exampleColorInput">Select Background Color</Form.Label>
                <Form.Control
                    type="color"
                    id="exampleColorInput"
                    defaultValue="#ffffff"
                    title="Choose your color"
                    onChange={(event) => { setbgColor(event.target.value) }}
                />
                </div>
                <div>
                <label class="form-label">Select Logo</label>
                <div class="d-flex align-items-center pt-2">
                {
                previewImage ?
                    <a href="#" class="avatar avatar-lg rounded-circle text-white"><img alt="preview-image" src={previewImage} width={60} height={60}/></a>

                :
                    null
            }
            {
                uploadedImage ?
                    
                    <a href="#" class="avatar avatar-lg bg-warning rounded-circle text-white"><img alt="uploaded-image" src={uploadedImage} width={50} height={50}/></a>

                :
                    null
            }
                    <div class="ms-4">
                        <label for="file-upload" class="btn btn-sm btn-neutral"><span>Upload</span> <input type="file" name="file-upload" id="file-upload" onChange={handleSelectImage} class="visually-hidden"/></label> 
                        <a href="#" onClick={handleRemoveImage} class="btn d-inline-flex btn-sm btn-neutral ms-2 text-danger"><span class="pe-2"><i class="fas fa-trash"></i> </span><span>Remove</span></a>
                        </div></div></div>                         

                    

                </div>

            <div class="col-6 text-center">
                        
                    <QRCode
                        id="4545"
                        value={qrUrl}
                        size={300}
                        level={"H"}
                        bgColor={bgColor}
                        fgColor={foreColor}
                        includeMargin={true}
                        imageSettings={{
                            src: previewImage,
                            x: undefined,
                            y: undefined,
                            height: 80,
                            width: 80,
                            excavate: false,
                          }}
                    />

                    <div class="text-center">
                    <button type='submit' name="submit" value="submit" id="submit" onClick={downloadQR} class="btn btn-primary btn-lg">
                    <i class="fa-solid fa-circle-down"></i> Download </button>
                    </div>

                </div>
                </div>
            </div>
            
            </div>
            </div>
                            
                  
                    </main>
                
            </Fragment>
        );
        
    };

export default UEditQR;