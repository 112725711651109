import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
//REDUX
import {useSelector, useDispatch} from 'react-redux';
import {getNewUsers,getNextUsers} from '../redux/actions/adminActions';
import PageItem from './PageItem';

const AdminBody = () => {
    
    const {adminusers} = useSelector(state=>state.adminusers);
    const {total_user_count} = useSelector(state=>state.total_user_count);
    const {current_page} = useSelector(state=>state.current_page);
    let page = 1;
    let limit=1;
    let sortBy= 'desc';
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getNewUsers(sortBy,limit,page));
    }, [dispatch]);

    const pageLinks = [];
    //Start will point to strating page which is multiple of 10 for current page
    //If currentpage is 18 than it will show 10 to 10+11 pages as link to move
    let start=(current_page)-(current_page%1);
    let pages=total_user_count/limit;
    if(start<=0)
        start=1;
    for (let i = start; i <= start+11 && i <= pages; i++) {
        // pageLinks.push(
        //     <li className="page-item" onClick={handlePaginationClick(i)} id={i} ><Link className="page-link" >{i}</Link></li>      
        // )
        pageLinks.push(
                <PageItem limit={limit} pageNumber={i}/>
            )
    }

    // console.log(pageLinks);
    // console.log(current_page);

    return (
        <section className='admin-dashboard m-4'>
            <div className='row'>
                    <div className='col-md-10'>
                    <table className='table'>
                        <thead>
                            <tr>
                            <th scope="col">Username</th>
                            <th scope="col">E-mail</th>
                            <th scope="col">Plan</th>
                            <th scope="col">Verified</th>
                            <th scope="col">Settings</th>
                            </tr>
                        </thead>
                        <tbody>

                            {adminusers && adminusers.map(user => (
                                <tr key={user._id}>
                                <th scope="row">
                                    {' '}
                                    {user.username}
                                </th>
                                <td>
                                {' '}
                                {user.email}
                                </td>
                                <td>
                                {' '}
                                {user.plan}
                                </td>
                                <td>
                                {user.isVerified}
                                </td>
                                <td>
                                <button type='button' 
                                className='btn btn-danger btn-sm'
                                
                                >
                                    <i className='far fa-trash-alt pr-1'></i>
                                    
                                </button>
                                </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                        
                    </div>
                    <div className='col-md-2 border-left pl-4'>
                        <h2>Other</h2>
                        {total_user_count}
                        {current_page}
                    </div>
                    <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        {/* <li className="page-item"><Link className="page-link" to="#">Previous</Link></li> */}
                        {pageLinks}
                        {/* <li className="page-item"><Link className="page-link" to="#">Next</Link></li> */}
                    </ul>
                    </nav>
                </div>
        </section>
    );
};


// Connect the Redux Store (state) to the component props
// const mapStateToProps = (state) => ({
//     current_page: state.current_page
//   });
  
//   // Updated thanks to the comment of Drew Reese below
//   const mapDispatchToProps = (dispatch) => ({
//     getNextUsers: (limit, page) => dispatch(getNextUsers(limit, page))
// });

  
  // Previous version
  // const mapDispatchToProps = dispatch => bindActionCreators({ toCounselor, toStudent }, dispatch);
  
  export default AdminBody;

//export default AdminBody;