import React, {Fragment} from "react";
import { Link, withRouter } from "react-router-dom";
import { isAuthenticated , logout} from "../helpers/auth";
import { useSelector } from "react-redux";


const Header = ( {history} ) => {

    const {cart} = useSelector(state => state.cart);

    const handleLogout = (evt) => {
        logout(() => {
            history.push('/management');
        });
    };

    //views

    const showNavigation = () => (
        <nav class="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg scrollbar" id="sidebar">
        <div class="container-fluid">
            
            <button class="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            
            <a class="navbar-brand d-inline-block py-lg-2 mb-lg-5 px-lg-6 me-0" href="#">
                
                <img src="https://upload.wikimedia.org/wikipedia/tr/thumb/d/d7/Muud.png/640px-Muud.png" width={137} height={43} />
            </a>
            
            <div class="navbar-user d-lg-none">
                
                <div class="dropdown">
                   
                    <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <div class="avatar-parent-child">
                        <div class="avatar bg-soft-warning rounded-circle text-primary border border-2 border-solid border-body">{Array.from(isAuthenticated().name)[0]}</div>
                        </div>
                    </a>
                    
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarAvatar">
                        <a href="/e/settings" class="dropdown-item">Profile</a>
                        <a href="/e/settings" class="dropdown-item">Settings</a>
                        <hr class="dropdown-divider"/>
                        <a href="#" onClick={handleLogout} class="dropdown-item">Logout</a>
                    </div>
                </div>
            </div>
            
            <div class="collapse navbar-collapse" id="sidebarCollapse">
                
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" href="/user/dashboard">
                            <i class="fa-solid fa-house"></i> Dashboard
                        </a>
                    </li>

                    <li class="nav-item">
                        <a class="nav-link" href="/analytics">
                            <i class="fa-solid fa-chart-simple"></i> Analytics
                        </a>
                    </li>
                    

                    
                </ul>
                
                <hr class="navbar-divider my-5 opacity-20"/>
                
               
               
                <div class="mt-auto"></div>
                
                <ul class="navbar-nav">
                <li class="nav-item">
                        <a class="nav-link" href="/e/settings">
                            <i class="fa-solid fa-gear"></i> Settings
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#" onClick={handleLogout}>
                        <i class="fa-solid fa-right-from-bracket"></i> Logout ({isAuthenticated().name})
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    );

    //render
    if(!isAuthenticated()){
        return <div></div>;
    } else {
        return <>{showNavigation()}</>;
    }
    

};

export default withRouter(Header);