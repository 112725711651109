import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { getCampaigns, deleteCampaign } from '../api/auth';
import { showErrorMsg, showSuccessMsg } from '../helpers/message';
import { showLoading } from '../helpers/loading';
import { isAuthenticated } from "../helpers/auth";
import Tooltip from './Tooltip';
import moment from 'moment';

const Campaigns = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    

    useEffect(() => {
        fetchCampaigns();
    }, []);

    const fetchCampaigns = async () => {
        try {
            setLoading(true);
            const campaignsData = await getCampaigns();
            
            if (Array.isArray(campaignsData)) {
                setCampaigns([...campaignsData].reverse());
            } else {
                console.error('Expected an array of campaigns, but got:', campaignsData);
                setError('Unexpected data format received from server');
            }
        } catch (err) {
            console.error('Error fetching campaigns:', err);
            setError(err.message || 'Error fetching campaigns. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (campaignId) => {
        if (window.confirm('Are you sure you want to delete this campaign? This action cannot be undone.')) {
            try {
                await deleteCampaign(campaignId);
                setSuccessMsg('Campaign deleted successfully');
                fetchCampaigns(); // Listeyi yenile
            } catch (err) {
                console.error('Error deleting campaign:', err);
                setError(err.message || 'Error deleting campaign. Please try again.');
            }
        }
    };

    if (loading) return showLoading();

    if (error) return showErrorMsg(error);

    return (
        <Fragment>
        <header class="bg-surface-primary border-bottom pt-6 mb-5">
            <div class="container-fluid">
                <div class="mb-npx">
                    <div class="row align-items-center">
                        <div class="col-sm-6 col-12 mb-4 mb-sm-0">
                            
                            <h1 class="h2 mb-0 ls-tight">Campaigns</h1>
                        </div>
                        
                        <div class="col-sm-6 col-12 text-sm-end">
                            <div class="mx-n1">
                                
                                <a href="/e/bulk" class="btn d-inline-flex btn-sm btn-primary mx-1">
                                    <span class=" pe-2">
                                        <i class="fa-solid fa-plus"></i>
                                    </span>
                                    <span>Create Campaign</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                    <ul class="nav nav-tabs mt-4 overflow-x border-0">
                       
                    </ul>
                </div>
            </div>
            </header>
            <main class="py-6 bg-surface-secondary">
                <div class="container-fluid">
            {successMsg && showSuccessMsg(successMsg)}
            {campaigns.length === 0 ? (
                <>
                <div className="row p-3">
                <div class="mb-1 px-5">
                    <h4 class="mb-1">You have not yet created any campaign.</h4>
                    </div>
                    <img class="px-5 pr-5" src="../images/content.jpg" alt=""/>
                </div>
                </>
            ) : (

                <>
                <div class="card shadow border-0 mb-7 mt-7">
                <div class="card-header">
                    <h5 class="mb-0">Campaigns</h5>
                </div>
                <div class="table-responsive">
                    <table class="table table-hover table-nowrap">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col" class="w-50">Campaigns</th>
                                <th scope="col" class="w-10">Total Links</th>
                                <th scope="col" class="w-10">Total Clicks</th>
                                <th scope="col" class="w-30"></th>
                                <th scope="col" class="w-10">Date/Creator</th>
                            </tr>
                        </thead>
                        <tbody>
                        {campaigns.map(campaign => (
                            <tr key={campaign._id}>
                                <td>
                                <div class="row mb-2 ms-1">
                                    <div class="ml-2">
                                        <b> &nbsp; {campaign.name}</b>
                                    </div>
                                </div>
                                    
                                </td>
                                
                                <td>
                                    <a href={`/campaign/${campaign._id}`} class="btn btn-sm btn-neutral border-base mx-1"> <i class="fas fa-link"></i> {campaign.totalLinks.toLocaleString('tr') ? (campaign.totalLinks.toLocaleString('tr')): (0)}
                                    </a>                                        
                                </td>
                                <td>
                                    <a href={`/campaign/${campaign._id}`} class="btn btn-sm btn-neutral border-base mx-1"> <i class="fa-solid fa-chart-simple"></i> {campaign.totalClicks.toLocaleString('tr') ? (campaign.totalClicks.toLocaleString('tr')): (0)}
                                    </a>                                        
                                </td>
                                <td>
                                            <a href={`/campaign/${campaign._id}`} class="btn d-inline-flex btn-sm btn-neutral border-base mx-1">
                                                <span class=" pe-2">
                                                <i class="fas fa-eye"></i>
                                                </span>
                                                <span>View Detail</span>
                                            </a>
                                            <a onClick={() => handleDelete(campaign._id)} class="btn btn-sm btn-neutral border-base mx-1">
                                                    <i class="fa-solid fa-trash"></i>
                                            </a>
                                </td>
                                <td>
                                <div class="row mb-2">
                                    <div class="ml-2 text-center">
                                        <Tooltip content={moment(campaign.createdAt).format('MMMM Do YYYY, h:mm:ss a')} direction="top" delay="0">
                                        {moment(campaign.createdAt).fromNow()}
                                        </Tooltip>
                                    </div>
                                    </div>
                                        <div class="row mb-2">
                                            <div class="ml-2 text-center">
                                            <span class="badge rounded-pill bg-dark">{campaign.creator}</span> 
                                        </div>
                                    </div>
                                </td>
                                
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div class="card-footer border-0 py-5">
                    <div class="row align-items-center">
                    <div class="col-sm-4">
                    <span class="text-muted text-sm-2">Showing items out of {campaigns.length} results found</span>
                    </div>
                    {/* <div class="col-sm-8">
                        <PaginationChart
                            nPages={nPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            itemCount= {links.length}
                            recordsPerPage= {recordsPerPage}
                        />
                    </div> */}
                    
                    </div>
                </div>
            </div>
                </>
            )}
            </div>
                    </main>
        </Fragment>
    );
};

export default Campaigns;