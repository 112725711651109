import React, {useState, useEffect, Fragment} from "react";
import { Link, useHistory } from "react-router-dom";
import {showErrorMsg,showSuccessMsg} from '../helpers/message';
import {showLoading} from '../helpers/loading';
import isEmpty from 'validator/lib/isEmpty';
import isUrl from 'validator/lib/isURL';
import isEmail from 'validator/lib/isEmail';
import equals from 'validator/lib/equals';
import { addmember, getteam } from "../api/auth";
import { isAuthenticated } from "../helpers/auth";
import './Dashboard.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';



//redux
import {useSelector, useDispatch} from 'react-redux';

const mongoose = require("mongoose");


const USettings = ({match}) => {

const dispatch = useDispatch();

let history = useHistory();

const {link} = useSelector(state=>state.links);

//PARAMS
//const linkId = match.params.linkId;

 //COMPONENT STATE PROPERTIES
 const [formData, setFormData] = useState({
    name:'',
    email:'',
    password:'',
    password2:'',
    title:'',
    role:1,
    isVerified: true,
    _id: new mongoose.Types.ObjectId(),
    userId:isAuthenticated()._id,
    teamId: isAuthenticated().teamId,
    teamid: isAuthenticated().teamId,
    successMsg: false,
    errorMsg: false,
    loading: false  
});

const {name, password, password2,email, userId, _id, successMsg, errorMsg, loading, teamId,role, teamid, isVerified } = formData;
//const [shortUrl, setShortUrl] = useState('');
const [freetext,setFreeText] = useState('');
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);

const [teamData, setTeamData] = useState({
    team:[]
});

const {team } = teamData;

 //LIFECYCLE METHODS

 useEffect(() => {

    if(teamid) {
        getteam(teamid)
            .then(response => {
                            //console.log(response.data.team_array)
                setTeamData({
                    ...teamData,
                    team: response.data.obj_data
                    
                });             
            })
            .catch(err => {
                console.log('get team error:' , err);  
            }) 
    }

 }, [])

 

// useEffect(() => {
//     if(isAuthenticated() && isAuthenticated().role === 1) {
//         history.push('/admin/dashboard/');
//     } else if (isAuthenticated() && isAuthenticated().role === 0){
//         history.push('/user/dashboard/');
//     }
// },[history]);

useEffect(() => {

}, [])

 // COMPONENT GLOBAL STATE PROPERTIES 


const [clientSideErrorMsg,setClientSideErrorMsg] = useState('');
const [clientSideSurlErrorMsg,setClientSideSurlErrorMsg] = useState('');
const [clientSideSurlMsg,setClientSideSurlMsg] = useState('');
const [clientSideSucMsg,setClientSideSucMsg] = useState('');
const [modalState,setModalState] = useState(false);



        // EVENT HANDLER

        const handleTeam = () => {
            if(teamid) {
                getteam(teamid)
                    .then(response => {
                                    //console.log(response.data.team_array)
                        setTeamData({
                            ...teamData,
                            team: response.data.obj_data
                            
                        });             
                    })
                    .catch(err => {
                        console.log('get team error:' , err);  
                    }) 
            }
            
        } ;

        const handleChange = (evt) => {
            //console.log(evt);
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            // if (isEmpty(evt.target.value)){
            //     setClientSideSurlErrorMsg('false');
            // } 
            
        } ;

        const handlePaste = (evt) => {

            setClientSideSurlMsg('');

            if (isUrl(evt.target.value)){
                setClientSideSurlErrorMsg('true');
            }  if (!isUrl(evt.target.value)) {
                setClientSideSurlErrorMsg('false');
            }  if (isEmpty(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            
        } ;
        const handleTarget = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });

            setClientSideSurlMsg('');
            
            if (!isUrl(evt.target.value)) {
                setClientSideSurlErrorMsg('false');
            } 
            else if (isEmpty(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            else if (isUrl(evt.target.value)){
                setClientSideSurlErrorMsg('true');
            }
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                // setClientSideSurlMsg('');
                // setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleMessages = (evt) => {
            

            setClientSideSurlErrorMsg('');
            setClientSideSurlMsg('');
            setClientSideErrorMsg('');

            setModalState(false);
            setShow(false);
            setFormData({
                ...formData,
                name:'',
                email:''
            });

        };

        const handleBlur = (evt) => {

            
    
        } 

        const handleFreeText = (evt) => {

            //setFreeText(evt.target.value);
            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleMail = (evt) => {
            //console.log(evt);
    
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
                errorMsg: '',
                   
            });

            if (isEmpty(evt.target.value) || !isEmail(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            // if(isEmail(evt.target.value)) {
            //     setClientSideSurlErrorMsg('true');
            // }
            
            
        } ;

        const handleCreateClick = (evt) => {
            

            setModalState(true);
            setShow(true);


        };

        const handleSubmit = async (evt) => {
            evt.preventDefault();

        // client-side validation

        if(isEmpty(name) || isEmpty(email) || isEmpty(password) || isEmpty(password2)) {
            
            setFormData({
                ...formData, errorMsg: 'All fields are required',
            });

        } else if (!isEmail(email)){
            setFormData({
                ...formData, errorMsg: 'Invalid email',
            });  
        } else if (!equals(password,password2)) {
            setFormData({
                ...formData, errorMsg: 'Password do not match',
            });
        } else {

            const {name, email, password, _id, teamId, role} = formData;
            const data = {name, email, password, _id, teamId, role};

            setFormData({...formData, loading: true});

            addmember(data)
                .then(response => {
                    console.log('Axios signup success',response);
                    setFormData({
                        email: '',
                        name:'',
                        password: '',
                        password2: '',
                        loading: false,
                        successMsg: response.data.successMessage,
                     });

                     handleTeam();
                })
                .catch(err => {
                    console.log('axios signup error:' , err);
                    setFormData({...formData, loading: false, errorMsg: err.response.data.errorMessage});
                })

        }

        

        }


        const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        const baseurl = `${protocol}//${domain}${port? port : ""}`

        
    

        return (
            <Fragment>
            <main role="main" className="container">
                <div className="jumbotron">
                    {/* <h1>Navbar example</h1> */}
                    {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#addLinkModal">Create Link</button> */}

                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                        <h1 class="h2">Settings</h1>
                    </div>
                    
                    <div class="form-group">
                   
                    </div>
                </div>
                
            <div className='row pb-3'>

            <Tab.Container id="left-tabs-example" defaultActiveKey="user">
                <Row>
                    <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        
                        <Nav.Item>
                        <Nav.Link eventKey="user">User Management</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                        <Nav.Link eventKey="sms">Custom Domain</Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item>
                        <Nav.Link eventKey="wifi">Plan</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Col>
                    <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="user">
                        <h2>User Management</h2>
                        <div class="modal-footer">
                    </div>
                        <div class="col">
                            <table class="table table-hover table-borderless">
                            <thead>
                                <tr class="table-light">
                                <th class="col-sm-4" >Name</th>
                                <th class="col-sm-4" >Email</th>
                                <th class="col-sm-2">Role</th>
                                <th class="col-sm-2">Member Since</th>
                                
                                </tr>
                            </thead>
                            <tbody class="table-light align-middle table-bordered">
                            {team?.map((item,index) => {              
                                return (
                                    <>
                                <tr key={index}>
                                <td>
                                {item.name}
                                </td>
                                <td>
                                {item.email}
                                </td>                           
                                <td> 
                                    {item.role=== 'Member' && 
                                    <span class="badge rounded-pill bg-secondary">{item.role}</span>
                                    }
                                    {item.role=== 'Owner' && 
                                    <span class="badge rounded-pill bg-success">{item.role}</span>
                                    }                     
                                </td>
                                <td>
                                {item.date}
                                </td>
                                
                                </tr>
                                    </>
                                  );
                                
                            })
                            }
                            </tbody>
                    </table>
                        </div>
                         
                        </Tab.Pane>
                        <Tab.Pane eventKey="sms">
                        
                        </Tab.Pane>
                        <Tab.Pane eventKey="wifi">
                        <div class="card mb-4 box-shadow">
                        <div class="card-header">
                            <h4 class="my-0 font-weight-normal">Enterprise Account</h4>
                        </div>
                        <div class="card-body">
                            
                            <ul class="list-unstyled mt-3 mb-4">
                            <li> <i className="fas fa-check-circle"></i> Unlimited Link</li>
                            <li><i className="fas fa-check-circle"></i> Unlimited QR Code</li>
                            <li><i className="fas fa-check-circle"></i> Unlimited Click</li>
                            <li><i className="fas fa-check-circle"></i> Insight & Reports</li>
                            <li><i className="fas fa-check-circle"></i> Team Management</li>
                            </ul>
                        </div>
                        </div>

                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
                </Tab.Container>
               
            </div>

            <Modal show={show} onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                    <Modal.Header closeButton>
                    <Modal.Title>Create New Member</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <form onSubmit={handleSubmit} >
                        <i className="fas fa-user"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp; Name:</label>
                        <div class="input-group mb-3">
                            
                            {clientSideErrorMsg==='false' ? (
                            <input type="text" class="form-control form-control-lg is-invalid" name="name" value={name} onChange={handleChange}  onBlur={handleBlur} placeholder='Member name' />
                            ) : 
                            clientSideErrorMsg==='true' ?  ( <input type="text" class="form-control form-control-lg is-valid" name="name" value={name}  onChange={handleChange} onBlur={handleBlur} placeholder='Member name' />
                             ) 
                              : 
                              ( <input type="text" class="form-control form-control-lg" name="name" value={name} onChange={handleChange} onBlur={handleBlur} placeholder='Member name' />
                             ) 
                            }
                            
                        </div>
                        <i className="fas fa-paper-plane"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp;Email:</label>
                        <div class="input-group mb-3">
                        {clientSideSurlErrorMsg==='false' ? 
                            ( <input type="text" class="form-control form-control-lg is-invalid" name="email" value={email} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Email'/>) 
                            : 
                            clientSideSurlErrorMsg==='true' ?  
                            ( <input type="text" class="form-control form-control-lg is-valid" name="email" value={email} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Email'/>) 
                              : 
                              ( <input type="text" class="form-control" id="basic-url" name="email" value={email} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Email'/>) 
                            }
                        
                        </div>
                        <i className="fas fa-lock"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp;Password:</label>
                        <div class="input-group mb-3">
                        {clientSideSurlErrorMsg==='false' ? 
                            ( <input type="password" class="form-control form-control-lg is-invalid" name="password" value={password} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Password'/>) 
                            : 
                            clientSideSurlErrorMsg==='true' ?  
                            ( <input type="password" class="form-control form-control-lg is-valid" name="password" value={password} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Password'/>) 
                              : 
                              ( <input type="password" class="form-control" id="basic-url" name="password" value={password} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Password'/>) 
                            }
                        
                        </div>
                        <i className="fas fa-lock"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp;Password:</label>
                        <div class="input-group mb-3">
                        {clientSideSurlErrorMsg==='false' ? 
                            ( <input type="password" class="form-control form-control-lg is-invalid" name="password2" value={password2} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Confirm password'/>) 
                            : 
                            clientSideSurlErrorMsg==='true' ?  
                            ( <input type="password" class="form-control form-control-lg is-valid" name="password2" value={password2} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Confirm password'/>) 
                              : 
                              ( <input type="password" class="form-control" id="basic-url" name="password2" value={password2} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Confirm password'/>) 
                            }
                        
                        </div>
                        {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                        {errorMsg && showErrorMsg(errorMsg)}
                        {successMsg && showSuccessMsg(successMsg)}
                        <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-lg" data-bs-dismiss="modal" onClick={handleMessages} >Close</button>
                        <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Create</button>
                    </div>
                    </form>
                    </Modal.Body>
                </Modal>

                            
                  
                    </main>
                
            </Fragment>
        );
        
    };

export default USettings;