import React, {useEffect} from "react";

//COMPONENTS
//import AdminHeader from './AdminHeader';
import AdminActionBtns from './AdminActionBtns';
import AdminCategoryModal from './AdminCategoryModal';
import AdminProductModal from './AdminProductModal';
import AdminBody from './AdminBody';
import AdminMetrics from './AdminMetrics';
//REDUX
import {useDispatch} from 'react-redux';
import {getCategories} from '../redux/actions/categoryActions';
import {getProducts} from '../redux/actions/productActions';



const AdminDashboard = () => {

const dispatch = useDispatch();

useEffect(() => {
    dispatch(getCategories());
}, [dispatch]);

useEffect(() => {
    dispatch(getProducts());
}, [dispatch]);



    //RENDER
    return (
         <section>
            {/* <AdminHeader /> */}
            <AdminActionBtns />
            <AdminCategoryModal />
            <AdminProductModal />
            <AdminMetrics />
            <AdminBody />
        </section>
        );

    };

export default AdminDashboard;