import React, {useEffect} from "react";
import { showLoading } from "../helpers/loading";
import {getNewArrivals} from '../redux/actions/filterActions';
import {getProductsByCount} from '../redux/actions/productActions';
import { useSelector, useDispatch } from "react-redux";



const Ana = () => {

    const goPage = () => {

        window.location.replace('https://www.muud.ly');
    }

    goPage()

    return (

        <>
        </>

    );

}

export default Ana;