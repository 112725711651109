import {CREATE_LINK, GET_LINKS, DELETE_LINK, GET_LINK, CREATE_CLICK,GET_TEKIL, GET_LINKS_BYDATE} from '../constants/linkConstants';


const INITIAL_STATE = {
    links: [],
    clicks: [],
}

const linkReducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_LINK:
            return {
                links: [...state.links, action.payload],
            };
        case CREATE_CLICK:
                return {
                    clicks: [],
                };
        case GET_LINKS:
            return {
                links: [...action.payload],
            };
            case GET_LINKS_BYDATE:
            return {
                links: [...action.payload],
            };
            case GET_LINK:
                return {
                    link: action.payload,
                };
                 
        case DELETE_LINK:
            return {
                 //links: state.links.filter(p => p._id !== action.payload._id)
                 links: state.links ? state.links.filter(p => p._id !== action.payload._id) : []
            };    
            default:
                return state;
    }
};

export default linkReducer;