import axios from 'axios';
import { START_LOADING, STOP_LOADING} from '../constants/loadingConstants';
import {SHOW_ERROR_MESSAGES} from '../constants/messageConstants';
import {GET_NEW_ARRIVALS} from '../constants/filterConstants';
import {GET_PRODUCTS} from '../constants/productConstants';
import {GET_LINKS} from '../constants/linkConstants';



export const getNewArrivals = (sortBy='desc', limit=3) => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.get(`/api/filter?sortBy=${sortBy}&limit=${limit}`);
        dispatch({type: STOP_LOADING});
        dispatch({type: GET_NEW_ARRIVALS, payload: response.data.newArrivals});

    } catch(err) {
        console.log('get new products api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const getProductsByFilter = arg => async dispatch => {
    
    try {
        const response = await axios.post('/api/filter/search', arg);

        dispatch({type: GET_PRODUCTS, payload: response.data.products});

    } catch(err) {
        console.log('getProductsByFilter api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
}; 

export const getLinksByFilter = arg => async dispatch => {
    
    try {
        const response = await axios.post('/api/filter/linksearch', arg);

        dispatch({type: GET_LINKS, payload: response.data.links});

    } catch(err) {
        console.log('getLinksByFilter api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};
