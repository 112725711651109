import React, {useState, useEffect} from "react";
import { Link, useHistory } from "react-router-dom";
import {showErrorMsg} from '../helpers/message';
import {showSuccessMsg} from '../helpers/message';
import {showLoading,showLoadingBtn} from '../helpers/loading';
import isEmpty from 'validator/lib/isEmpty';
import isEmail from 'validator/lib/isEmail';
import { recover } from "../api/auth";
import { isAuthenticated } from "../helpers/auth";

const Recover = () => {

    let history = useHistory();

    useEffect(() => {
        if(isAuthenticated() && isAuthenticated().role === 1) {
            history.push('/admin/dashboard/');
        } else if (isAuthenticated() && isAuthenticated().role === 0){
            history.push('/user/dashboard/');
        }
    },[history]);

    const [formData, setFormData] = useState({
        email:'',
        successMsg: false,
        errorMsg: false,
        loading: false  
    });

    const {email, successMsg, errorMsg, loading } = formData;

        // EVENT HANDLER

        const handleChange = (evt) => {
            //console.log(evt);
    
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
                errorMsg: '',
                successMsg:'',   
            });
        } ;

        const handleSubmit = (evt) => {
            evt.preventDefault();

        // client-side validation
        
        if(isEmpty(email)) {
            
            setFormData({
                ...formData, errorMsg: 'All fields are required',
            });

        } else if (!isEmail(email)){
            setFormData({
                ...formData, errorMsg: 'Invalid email',
            });    
        } else {
            const {email} = formData;
            const data = {email};

            setFormData({...formData, loading: true});
            console.log('recover api error:', data);
            recover(data)
                .then(response => {
                    console.log('Axios Recover success',response);
                    setFormData({
                        loading: false,
                        successMsg: response.data.successMessage,
                     });
                })
                .catch(err => {
                    console.log('recover api error:', err);
                    setFormData({
                        ...formData,
                        loading: false,
                        errorMsg: err.response.data.errorMessage,
                    });
                })


        }
        };


        //WIEWS
        const showRecoverForm = () => (


            <div className="content-area login-form">
            <img src="images/logo.png" alt="" className="login-logo"/>
            <form onSubmit={handleSubmit} noValidate>
                <h2 className="form-title">Recover Password</h2>
                
                <div className="input-group">
                    <label >E-Mail Address</label>
                    <input type="email" name="email" value={email} onChange={handleChange} placeholder="example@mail.com" required="required"/>
                </div>
                {errorMsg && showErrorMsg(errorMsg)}
                {successMsg && showSuccessMsg(successMsg)}
                <div className="button-group">
                    {
                    loading ? (<div className='text-center'>{showLoadingBtn()}</div>) : (
                    <input type="submit" name="" id="" value="Recover" />
                    )
                    }
                    
                </div>
            </form>
            <p class="register-link">Back to <Link to="/signin">Login!</Link></p>
            </div>        

        );

            // RENDER
    return (
        
        <section class="centered">
            {loading && <div className='text-center'>{showLoading()}</div>}
            {showRecoverForm()}
        </section>

    );

}

export default Recover;