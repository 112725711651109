import React, {useState, useEffect, Fragment} from "react";
import { Link, useHistory } from "react-router-dom";
import {showErrorMsg,showSuccessMsg} from '../helpers/message';
import {showLoading} from '../helpers/loading';
import isEmpty from 'validator/lib/isEmpty';
import isUrl from 'validator/lib/isURL';
import isEmail from 'validator/lib/isEmail';
import equals from 'validator/lib/equals';
import { addmember, getteam } from "../api/auth";
import { isAuthenticated } from "../helpers/auth";
import './Dashboard.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';



//redux
import {useSelector, useDispatch} from 'react-redux';

const mongoose = require("mongoose");


const UTeam = ({match}) => {

const dispatch = useDispatch();

let history = useHistory();

const {link} = useSelector(state=>state.links);

//PARAMS
//const linkId = match.params.linkId;

 //COMPONENT STATE PROPERTIES
 const [formData, setFormData] = useState({
    name:'',
    email:'',
    password:'',
    password2:'',
    title:'',
    role:isAuthenticated().role,
    isVerified: true,
    _id: new mongoose.Types.ObjectId(),
    userId:isAuthenticated()._id,
    teamId: isAuthenticated().teamId,
    teamid: isAuthenticated().teamId,
    successMsg: false,
    errorMsg: false,
    loading: false  
});

const {name, password, password2,email, userId, _id, successMsg, errorMsg, loading, teamId,role, teamid, isVerified } = formData;
//const [shortUrl, setShortUrl] = useState('');
const [freetext,setFreeText] = useState('');
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const [showInfo, setShowInfo] = useState(false);
const [showProfile, setShowProfile] = useState(false);

  const handleCloseInfo = () => setShowInfo(false);
  const handleShow = () => setShowInfo(true);
  const handleCloseProfile = () => setShowProfile(false);

const [teamData, setTeamData] = useState({
    team:[]
});

const {team } = teamData;

 //LIFECYCLE METHODS

 useEffect(() => {

    if(teamid) {
        getteam(teamid)
            .then(response => {
                            //console.log(response.data.team_array)
                setTeamData({
                    ...teamData,
                    team: response.data.obj_data
                    
                });             
            })
            .catch(err => {
                console.log('get team error:' , err);  
            }) 
    }

 }, [])

 

// useEffect(() => {
//     if(isAuthenticated() && isAuthenticated().role === 1) {
//         history.push('/admin/dashboard/');
//     } else if (isAuthenticated() && isAuthenticated().role === 0){
//         history.push('/user/dashboard/');
//     }
// },[history]);

useEffect(() => {

}, [])

 // COMPONENT GLOBAL STATE PROPERTIES 


const [clientSideErrorMsg,setClientSideErrorMsg] = useState('');
const [clientSideSurlErrorMsg,setClientSideSurlErrorMsg] = useState('');
const [clientSideSurlMsg,setClientSideSurlMsg] = useState('');
const [clientSideSucMsg,setClientSideSucMsg] = useState('');
const [modalState,setModalState] = useState(false);



        // EVENT HANDLER

        const handleTeam = () => {
            if(teamid) {
                getteam(teamid)
                    .then(response => {
                                    //console.log(response.data.team_array)
                        setTeamData({
                            ...teamData,
                            team: response.data.obj_data
                            
                        });             
                    })
                    .catch(err => {
                        console.log('get team error:' , err);  
                    }) 
            }
            
        } ;

        const handleChange = (evt) => {
            //console.log(evt);
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            // if (isEmpty(evt.target.value)){
            //     setClientSideSurlErrorMsg('false');
            // } 
            
        } ;

        const handlePaste = (evt) => {

            setClientSideSurlMsg('');

            if (isUrl(evt.target.value)){
                setClientSideSurlErrorMsg('true');
            }  if (!isUrl(evt.target.value)) {
                setClientSideSurlErrorMsg('false');
            }  if (isEmpty(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            
        } ;
        const handleTarget = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });

            setClientSideSurlMsg('');
            
            if (!isUrl(evt.target.value)) {
                setClientSideSurlErrorMsg('false');
            } 
            else if (isEmpty(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            else if (isUrl(evt.target.value)){
                setClientSideSurlErrorMsg('true');
            }
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                // setClientSideSurlMsg('');
                // setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleMessages = (evt) => {
            

            setClientSideSurlErrorMsg('');
            setClientSideSurlMsg('');
            setClientSideErrorMsg('');

            setModalState(false);
            setShow(false);
            setFormData({
                ...formData,
                errorMsg:'',
                successMsg:''
                
            });

        };

        const handleBlur = (evt) => {

            
    
        } 

        const handleFreeText = (evt) => {

            //setFreeText(evt.target.value);
            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleMail = (evt) => {
            //console.log(evt);
    
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
                errorMsg: '',
                   
            });

            if (isEmpty(evt.target.value) || !isEmail(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            // if(isEmail(evt.target.value)) {
            //     setClientSideSurlErrorMsg('true');
            // }
            
            
        } ;

        const handleCreateClick = (evt) => {
            

            setModalState(true);
            setShow(true);
            setFormData({
                ...formData,
                name:'',
                email:''
                   
            });


        };

        const handleEdit = (evt) => {
            
            if(role===1) {
                setShowInfo(true);
            } else if (role===0) {
                setShowProfile(true);
            }

        };

        const handlePass = (evt) => {
            
            if(role===1) {
                setShowInfo(true);
            } else if (role===0) {
                setShowProfile(true);
            }

        };
        const handleDelete = (evt) => {
            
            if(role===1) {
                setShowInfo(true);
            } else if (role===0) {
                setShowProfile(true);
            }

        };

        const handleSubmit = async (evt) => {
            evt.preventDefault();

        // client-side validation

        if(isEmpty(name) || isEmpty(email) || isEmpty(password) || isEmpty(password2)) {
            
            setFormData({
                ...formData, errorMsg: 'All fields are required',
            });

        } else if (!isEmail(email)){
            setFormData({
                ...formData, errorMsg: 'Invalid email',
            });  
        } else if (!equals(password,password2)) {
            setFormData({
                ...formData, errorMsg: 'Password do not match',
            });
        } else {

            const {name, email, password, _id, teamId, role} = formData;
            const data = {name, email, password, _id, teamId, role};

            setFormData({...formData, loading: true});

            addmember(data)
                .then(response => {
                    console.log('Axios signup success',response);
                    setFormData({
                        email: '',
                        name:'',
                        password: '',
                        password2: '',
                        loading: false,
                        successMsg: response.data.successMessage,
                     });

                     handleTeam();
                })
                .catch(err => {
                    console.log('axios signup error:' , err);
                    setFormData({...formData, loading: false, errorMsg: err.response.data.errorMessage});
                })

        }

        

        }


        const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        const baseurl = `${protocol}//${domain}${port? port : ""}`

        
    

        return (
            <Fragment>
            <header class="bg-surface-primary border-bottom pt-6">
            <div class="container-fluid">
                <div class="mb-npx">              
                <div class="row align-items-center">
                        <div class="col-sm-9 col-12 mb-4 mb-sm-0">
                            <h1 class="h2 mb-0 ls-tight">Settings</h1>
                        </div>
                        
                    </div>
                    
                    <ul class="nav nav-tabs mt-4 overflow-x border-0">
                        <li class="nav-item ">
                            <a href={`/e/settings`} class="nav-link font-regular">Profile</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/e/team/`} class="nav-link active">Team Management</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/e/plan/`} class="nav-link font-regular">Plan</a>
                        </li>
                       
                    </ul>
                </div>
            </div>
            </header>
            <main class="py-6 bg-surface-secondary">
            <div class="container-fluid max-w-screen-md vstack gap-6">
                
            <div class="card">
            <div class="card-header d-flex align-items-center">
                <h5 class="me-auto">Team Users</h5> 
                <a onClick={handleCreateClick} class="btn d-inline-flex btn-sm btn-primary text-end"><span class=" pe-2"><i class="fa-solid fa-plus"></i></span><span>Add User</span></a> 
            </div>

            <div class="table-responsive">
                <table class="table table-hover table-nowrap">
                    <thead class="table-light">
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Role</th>
                            <th></th>
                        </tr>
                    </thead>
                
                <tbody>
                {team?.map((item,index) => {              
                                return (
                                    <>
                <tr key={index}>
                    <td>
                    <a class="text-heading text-primary-hover font-semibold" href="#" style={{ textDecoration: 'none' }}>{item.name}</a>
                    </td>
                    <td>{item.email}</td>
                    <td>
                        <span class="badge text-uppercase bg-soft-primary text-primary rounded-pill">{item.role}</span>
                    </td>
                    <td class="text-end">
                        <a href="#" onClick={() => {handleEdit();setFormData({...formData, name: item.name, email: item.email})}}class="btn btn-sm btn-square btn-neutral me-1"><i class="fa-solid fa-pencil"></i> </a>
                        <a href="#" onClick={handlePass} class="btn btn-sm btn-square btn-neutral me-1"><i class="fa-solid fa-key"></i> </a>
                    <button type="button" onClick={handleDelete} class="btn btn-sm btn-square btn-neutral text-danger-hover"><i class="fa-solid fa-trash"></i></button></td>
                </tr>
                </>
                    );
                                
                            })
                        }
                </tbody>
                </table>
            </div>
            <div class="card-footer border-0 py-5"><span class="text-muted text-sm"></span></div>


            </div>


            <Modal show={show} onHide={handleMessages}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                    <Modal.Header closeButton>
                    <Modal.Title>Create New Member</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <form onSubmit={handleSubmit} >
                        <i className="fas fa-user"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp; Name:</label>
                        <div class="input-group mb-3">
                            
                            {clientSideErrorMsg==='false' ? (
                            <input type="text" class="form-control form-control-lg is-invalid" name="name" value={name} onChange={handleChange}  onBlur={handleBlur} placeholder='Member name' />
                            ) : 
                            clientSideErrorMsg==='true' ?  ( <input type="text" class="form-control form-control-lg is-valid" name="name" value={name}  onChange={handleChange} onBlur={handleBlur} placeholder='Member name' />
                             ) 
                              : 
                              ( <input type="text" class="form-control form-control-lg" name="name" value={name} onChange={handleChange} onBlur={handleBlur} placeholder='Member name' />
                             ) 
                            }
                            
                        </div>
                        <i className="fas fa-paper-plane"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp;Email:</label>
                        <div class="input-group mb-3">
                        {clientSideSurlErrorMsg==='false' ? 
                            ( <input type="text" class="form-control form-control-lg is-invalid" name="email" value={email} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Email'/>) 
                            : 
                            clientSideSurlErrorMsg==='true' ?  
                            ( <input type="text" class="form-control form-control-lg is-valid" name="email" value={email} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Email'/>) 
                              : 
                              ( <input type="text" class="form-control" id="basic-url" name="email" value={email} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Email'/>) 
                            }
                        
                        </div>
                        <i className="fas fa-lock"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp;Password:</label>
                        <div class="input-group mb-3">
                        {clientSideSurlErrorMsg==='false' ? 
                            ( <input type="password" class="form-control form-control-lg is-invalid" name="password" value={password} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Password'/>) 
                            : 
                            clientSideSurlErrorMsg==='true' ?  
                            ( <input type="password" class="form-control form-control-lg is-valid" name="password" value={password} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Password'/>) 
                              : 
                              ( <input type="password" class="form-control" id="basic-url" name="password" value={password} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Password'/>) 
                            }
                        
                        </div>
                        <i className="fas fa-lock"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp;Password:</label>
                        <div class="input-group mb-3">
                        {clientSideSurlErrorMsg==='false' ? 
                            ( <input type="password" class="form-control form-control-lg is-invalid" name="password2" value={password2} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Confirm password'/>) 
                            : 
                            clientSideSurlErrorMsg==='true' ?  
                            ( <input type="password" class="form-control form-control-lg is-valid" name="password2" value={password2} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Confirm password'/>) 
                              : 
                              ( <input type="password" class="form-control" id="basic-url" name="password2" value={password2} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Confirm password'/>) 
                            }
                        
                        </div>
                        {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                        {errorMsg && showErrorMsg(errorMsg)}
                        {successMsg && showSuccessMsg(successMsg)}
                        <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-lg" data-bs-dismiss="modal" onClick={handleMessages} >Close</button>
                        <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Create</button>
                    </div>
                    </form>
                    </Modal.Body>
                </Modal>

                <Modal show={showInfo} onHide={handleCloseInfo}>
                <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>Only "Owner" can edit password and profile details.</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseInfo}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showProfile} onHide={handleCloseProfile}
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                    <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <form onSubmit={handleSubmit} >
                        <i className="fas fa-user"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp; Name:</label>
                        <div class="input-group mb-3">
                            
                            {clientSideErrorMsg==='false' ? (
                            <input type="text" class="form-control form-control-lg is-invalid" name="name" value={name} onChange={handleChange}  onBlur={handleBlur} placeholder='Member name' />
                            ) : 
                            clientSideErrorMsg==='true' ?  ( <input type="text" class="form-control form-control-lg is-valid" name="name" value={name}  onChange={handleChange} onBlur={handleBlur} placeholder='Member name' />
                             ) 
                              : 
                              ( <input type="text" class="form-control form-control-lg" name="name" value={name} onChange={handleChange} onBlur={handleBlur} placeholder='Member name' />
                             ) 
                            }
                            
                        </div>
                        <i className="fas fa-paper-plane"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp;Email:</label>
                        <div class="input-group mb-3">
                        {clientSideSurlErrorMsg==='false' ? 
                            ( <input type="text" class="form-control form-control-lg is-invalid" name="email" value={email} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Email'/>) 
                            : 
                            clientSideSurlErrorMsg==='true' ?  
                            ( <input type="text" class="form-control form-control-lg is-valid" name="email" value={email} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Email'/>) 
                              : 
                              ( <input type="text" class="form-control" id="basic-url" name="email" value={email} onChange={handleChange} aria-describedby="basic-addon3" placeholder='Email'/>) 
                            }
                        
                        </div>
                    
                        {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                        {errorMsg && showErrorMsg(errorMsg)}
                        {successMsg && showSuccessMsg(successMsg)}
                        <div class="modal-footer">
                        <button type="button" class="btn btn-secondary btn-lg" data-bs-dismiss="modal" onClick={handleCloseProfile} >Close</button>
                        <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Save</button>
                    </div>
                    </form>
                    </Modal.Body>
            </Modal>


                    </div>
                </main>
                
            </Fragment>
        );
        
    };

export default UTeam;