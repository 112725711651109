import React from 'react';
import {Link} from 'react-router-dom';

//redux
import {useDispatch} from 'react-redux';
import {deletePageLink} from '../redux/actions/pageActions';
// imports related to DND
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';

const UserButtonList = ({key, link, adminPage=false, homePage=false, pageid}) => {
        
const dispatch = useDispatch();
const {pageId} = pageid;

const handleAddToCart = () => {
    // dispatch(addToCart(product));
};

const handleClick = (e) => {
    const linkId = e.currentTarget.getAttribute("data-value");
    const pageId2 = e.currentTarget.getAttribute("data-value2");
    console.log("Values1", linkId);
    console.log("pageId", pageId2);
    dispatch(deletePageLink(linkId,pageId2));
  };

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "darkgrey" : "darkgrey",
    width: '30%',
    margin: 'auto',
 });
 
 const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "darkgrey" : "white",
    color: isDragging ? "white" : "black",
    padding: isDragging ? '0%' : '2%',
    paddingLeft: '2%',
    margin: '0%',
    fontSize: '17px',
    borderBottom: '0.5px solid gray',
    // styles we need to apply on draggables
    ...draggableStyle
 });


    return (
    <div className='col-md-4 my-3'>
        <div key={key} className='card h-100'>
            <div className='card-body text-center'>
                <h5>{link.title}</h5>
                <hr />
                <p>
                {link.url}
                </p>
                {adminPage && (
                    <>
                    {/* <Link to={`/admin/edit/product/${link._id}`} type='button' className='btn btn-secondary btn-sm mr-1 my-1'>
                    <i className='far fa-edit pr-1'></i>
                    Edit
                    </Link> */}
                    <button 
                    type='button' 
                    className='btn btn-danger btn-sm' 
                    data-value={link._id}
                    data-value2={pageid}
                    // onClick={() => dispatch(deleteLink(link._id))}
                    onClick={handleClick}
                    >
                        <i className='far fa-trash-alt pr-1'></i>
                        Delete
                    </button>
                    </>
                )}
                
            </div>
        </div>
    </div>
    );





    
    };

export default UserButtonList;