import React, {Fragment} from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Loadable from 'react-loadable';
import Header from "./HeaderNew";
import Ana from "./Ana";
import Recover from "./Recover";
import Verify from "./Verify";
import ResetPassword from "./ResetPassword";
import NotFound from "./NotFound";
import UserDashboard from "./UserDashboard";
import AdminDashboard from "./AdminDashboard";
import AdminRoute from "./AdminRoute";
import UserRoute from "./UserRoute";
import AdminEditProduct from "./AdminEditProduct";
import UEditLink from "./UEditLink";
import UEditAlias from "./UEditAlias";
import Login from "./Login";
import Register from "./Register";
//import ShortUrl from "./ShortUrl";
import UEditTitle from "./UEditTitle";
import UEditTarget from "./UEditTarget";
import UEditPassword from "./UEditPassword";
import UEditMobileTarget from "./UEditMobileTarget";
import UEditExpiration from "./UEditExpiration";
import UEditUtm from "./UEditUtm";
import UEdit from "./UEdit";
import ULogin from "./ULogin";

//import UInsight from "./UInsight";
import UQrCode from "./UQrCode";
import USettings from "./USettings";
import UAnalytics from "./UAnalytics";
import UEditQR from "./UEditQR";
import UPlan from "./UPlan";
import UTeam from "./UTeam";

import BulkOperations from "./BulkOperations";
import Campaigns from "./Campaigns";
import CampaignDetails from "./CampaignDetail";

const LoadableInsight = Loadable({
	loader: () => import('./UInsight'),
	loading() {
		return <div>Loading...</div>
	}
})

const LoadableShortUrl = Loadable({
	loader: () => import('./ShortUrl'),
	loading() {
		return <div>Loading...</div>
	}
})


const App = () => {

  return (
  <BrowserRouter>
  <div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
    <Header />
    <div className="h-screen flex-grow-1 overflow-y-lg-auto">
      <Switch>
          <Route exact path="/" component={Ana} />
          <Route exact path="/management" component={Login} />
          <Route exact path="/regontff" component={Register} />
          <Route exact path="/recoveronmuud" component={Recover} />
          <Route exact path="/loginurl/:shortUrl" component={ULogin} />
          <UserRoute exact path="/user/dashboard" component={UserDashboard} />
          <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} />
          <AdminRoute exact path="/admin/edit/product/:productId" component={AdminEditProduct} />
          <UserRoute exact path="/edit/:linkId" component={UEdit} />
          <UserRoute exact path="/edit/link/:linkId" component={UEditLink} />
          <UserRoute exact path="/edit/alias/:linkId" component={UEditAlias} />
          <UserRoute exact path="/edit/title/:linkId" component={UEditTitle} />
          <UserRoute exact path="/edit/target/:linkId" component={UEditTarget} />
          <UserRoute exact path="/edit/password/:linkId" component={UEditPassword} />
          <UserRoute exact path="/edit/mobiletarget/:linkId" component={UEditMobileTarget} />
          <UserRoute exact path="/edit/expiration/:linkId" component={UEditExpiration} />
          <UserRoute exact path="/edit/utm/:linkId" component={UEditUtm} />
          <UserRoute exact path="/edit/qr/:linkId" component={UEditQR} />
          <UserRoute exact path="/insight/:linkId" component={LoadableInsight} />
          <UserRoute exact path="/analytics" component={UAnalytics} />
          <UserRoute exact path="/e/qr-code" component={UQrCode} />
          <UserRoute exact path="/e/settings" component={USettings} />
          <UserRoute exact path="/e/plan" component={UPlan} />
          <UserRoute exact path="/e/team" component={UTeam} />
           <UserRoute exact path="/e/bulk" component={BulkOperations} />
          <UserRoute exact path="/e/campaigns" component={Campaigns} />
          <UserRoute exact path="/campaign/:campaignId" component={CampaignDetails} />
          <Route path="/verify/:token" component={Verify} />
          <Route path="/:shortUrl" component={LoadableShortUrl} />
          <Route path="/reset/:token" component={ResetPassword} />
          <Route component={NotFound} />
      </Switch>
    </div>
    </div>
  </BrowserRouter>
  );
};

export default App;
