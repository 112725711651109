import axios from 'axios';
import { START_LOADING, STOP_LOADING} from '../constants/loadingConstants';
import {SHOW_ERROR_MESSAGES} from '../constants/messageConstants';
import {GET_NEW_USERS, GET_TOTAL_USER_COUNT, GET_CURRENT_PAGE} from '../constants/adminConstants';


export const getNewUsers = (sortBy='desc', limit=3, page=1) => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.get(`/api/bedashboard?sortBy=${sortBy}&limit=${limit}&page=${page}`);
        dispatch({type: STOP_LOADING});
        dispatch({type: GET_NEW_USERS, payload: response.data.adminusers});
        dispatch({type: GET_TOTAL_USER_COUNT, payload: response.data.total_user_count});
        dispatch({type: GET_CURRENT_PAGE, payload: response.data.current_page});

    } catch(err) {
        console.log('get new admin user api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const getNextUsers = (limit=1, page=1) => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.get(`/api/bedashboard?limit=${limit}&page=${page}`);
        dispatch({type: STOP_LOADING});
        dispatch({type: GET_NEW_USERS, payload: response.data.adminusers});
        dispatch({type: GET_TOTAL_USER_COUNT, payload: response.data.total_user_count});
        dispatch({type: GET_CURRENT_PAGE, payload: response.data.current_page});

    } catch(err) {
        console.log('get new admin user api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};