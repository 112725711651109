import React, {useState, useEffect, Fragment} from "react";
import { Link, useHistory } from "react-router-dom";
import {showErrorMsg,showSuccessMsg} from '../helpers/message';
import {showLoading} from '../helpers/loading';
import isEmpty from 'validator/lib/isEmpty';
import isUrl from 'validator/lib/isURL';
import { editexpiration } from "../api/auth";
import { isAuthenticated } from "../helpers/auth";
import Tooltip from './Tooltip';
import './Dashboard.css';
import { Toast, Form } from 'react-bootstrap'
import ToastContainer from 'react-bootstrap/ToastContainer';


//redux
import {useSelector, useDispatch} from 'react-redux';
import {getLink,getLinks} from '../redux/actions/linkActions';


const UEditExpiration = ({match}) => {

const dispatch = useDispatch();

let history = useHistory();

const {link} = useSelector(state=>state.links);

const [checkState,setCheckState] = useState(false);

//PARAMS
const linkId = match.params.linkId;

 //COMPONENT STATE PROPERTIES
 const [formData, setFormData] = useState({
    linkId:linkId,
    shortUrl:'',
    longUrl:'',
    title:'',
    linkExpires:[],
    userId:isAuthenticated()._id,
    date:new Date().toISOString().split("T")[0],
    link_exp:'',
    views:'',
    views_type:'',
    successMsg: false,
    errorMsg: false,
    loading: false  
});


const {longUrl, shortUrl,title, userId, link_exp, views, date, views_type, linkExpires, successMsg, errorMsg, loading } = formData;
//const [shortUrl, setShortUrl] = useState('');


 //LIFECYCLE METHODS

 useEffect(() => {
    
    if(!link){
        dispatch(getLink(linkId));
    } 
    else {
        
        setFormData({
            ...formData,
            shortUrl:link.shortUrl,
            longUrl:link.longUrl,
            title:link.title,
            linkExpires: link.linkExpires,
            
        });
        
    }

 }, [dispatch, linkId, link])

 

// useEffect(() => {
//     if(isAuthenticated() && isAuthenticated().role === 1) {
//         history.push('/admin/dashboard/');
//     } else if (isAuthenticated() && isAuthenticated().role === 0){
//         history.push('/user/dashboard/');
//     }
// },[history]);

useEffect(() => {
    linkExpires.map((p) => {
        const { link_exp, date, views, views_type } = p;
        setFormData({
            ...formData,
            link_exp:link_exp,
            date:new Date(date).toISOString().split("T")[0],
            views:views,
            views_type:views_type,
        });
        
      });
}, [linkExpires])

useEffect(() => {
        
}, [])




 // COMPONENT GLOBAL STATE PROPERTIES 


const [clientSideErrorMsg,setClientSideErrorMsg] = useState('');
const [clientSideLinkErrorMsg,setClientSideLinkErrorMsg] = useState('');
const [clientSideAndroidErrorMsg,setClientSideAndroidErrorMsg] = useState('');
const [clientSideWebErrorMsg,setClientSideWebErrorMsg] = useState('');
const [clientSideSurlMsg,setClientSideSurlMsg] = useState('');
const [clientSideSucMsg,setClientSideSucMsg] = useState('');
const [modalState,setModalState] = useState(false);
const [showToast, setToast] = useState(false);


        // EVENT HANDLER

        const handleChange = (evt) => {

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            }  if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            }  if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            } 
            
        } ;

        const handleLink = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });

            setClientSideSurlMsg('');
            
            if (!isUrl(evt.target.value)) {
                setClientSideLinkErrorMsg('false');
            } 
             if (isEmpty(evt.target.value)){
                setClientSideLinkErrorMsg('');
            } 
             if (isUrl(evt.target.value)){
                setClientSideLinkErrorMsg('true');
            }
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                // setClientSideSurlMsg('');
                // setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleAndroid = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });

            setClientSideSurlMsg('');
            
            if (!isUrl(evt.target.value)) {
                setClientSideAndroidErrorMsg('false');
            } 
            if (isEmpty(evt.target.value)){
                setClientSideAndroidErrorMsg('');
            } 
             if (isUrl(evt.target.value)){
                setClientSideAndroidErrorMsg('true');
            }
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                // setClientSideSurlMsg('');
                // setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleWeb = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });

            setClientSideSurlMsg('');
            
            if (!isUrl(evt.target.value)) {
                setClientSideWebErrorMsg('false');
            } 
             if (isEmpty(evt.target.value)){
                setClientSideWebErrorMsg('');
            } 
             if (isUrl(evt.target.value)){
                setClientSideWebErrorMsg('true');
            }
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                // setClientSideSurlMsg('');
                // setClientSideSurlErrorMsg('');
            } 
            
        } ;


        const handleSubmit = async (evt) => {
            evt.preventDefault();

        // client-side validation

        if(!isEmpty(link_exp) && !isUrl(link_exp)) {
            setClientSideSurlMsg('URLs contain invalid characters. Please enter valid URL.');
        } 
        
        
        else  {

                //console.log(formData);

                editexpiration(formData)
                        .then(response => {
                            if(response.data.successMessage){
                                                setFormData({
                                                    ...formData,
                                                    successMsg:response.data.successMessage
                                                })
                                                setTimeout(() => {
                                                    dispatch(getLinks(userId));
                                                    history.go("/user/dashboard/");
                                                }, 2000);
                                                
                                            }
                                        setClientSideLinkErrorMsg('');
                                        setClientSideAndroidErrorMsg('');
                                        setClientSideWebErrorMsg('');
                                        setClientSideSurlMsg('');
                                        setClientSideErrorMsg('');
                                    })
                                      
                .catch(err => {
                    console.log('edit expiration target error:' , err);
                    setClientSideSurlMsg(err.response.data.errorMessage);
                    //setClientSideIosErrorMsg('false');
                }) 

                

                

        }

        }

        const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};
        const copyToClipboard = (content) => {
            if (window.isSecureContext && navigator.clipboard) {
              navigator.clipboard.writeText(content);
            } else {
              unsecuredCopyToClipboard(content);
            }
          };

        const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        const baseurl = `${protocol}//${domain}${port? port : ""}`

        

        return (
            <Fragment>
            <header class="bg-surface-primary border-bottom pt-6">
            <div class="container-fluid">
                <div class="mb-npx">              
                    <div className='row'>
                    <div class="row mb-2">
                                            <div class=" ml-2">

                                            {isUrl(longUrl) && 
                                            <img src={`https://s2.googleusercontent.com/s2/favicons?domain=${longUrl}`}/>
                                            }
                                            {!isUrl(longUrl) && 
                                            <img src={``}/>
                                            }  
                                            
                                            <b> &nbsp; {title && title} </b>
                                            
                                            
                                            
                                                
                                            </div>
                        </div>
                    <div class="row">
                                            <div class="col ml-2 mb-2">
                                        
                                            
                                             <small>{longUrl && longUrl}</small>
                                                                                
                                            </div>
                        </div>
                    <div class="row">
                                            <div class="col">
                                            <Link to={{pathname: `${baseurl}/${shortUrl}`}} target="_blank" >{baseurl}/{shortUrl}</Link>
                                            <Tooltip content="Copy link" direction="top" delay="0">
                                                
                                                <i className="fas fa-clone ms-2" onClick={() => {copyToClipboard(`${baseurl}/${shortUrl}`);setToast(true)}}></i>
                                                
                                            </Tooltip>
                                            </div>
                                            <div class="col">
                                            </div>
                                    
                        </div>
                    </div>
                    
                    <ul class="nav nav-tabs mt-4 overflow-x border-0">
                        <li class="nav-item ">
                            <a href={`/edit/${linkId}`} class="nav-link font-regular">Title</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/alias/${linkId}`} class="nav-link font-regular">Alias Name</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/target/${linkId}`} class="nav-link font-regular">Target Url</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/mobiletarget/${linkId}`} class="nav-link font-regular">Mobile Redirect</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/password/${linkId}`} class="nav-link font-regular">Password</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/utm/${linkId}`} class="nav-link font-regular">UTM Generator</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/expiration/${linkId}`} class="nav-link active">Expiration</a>
                        </li>
                        <li class="nav-item">
                            <a href={`/edit/qr/${linkId}`} class="nav-link font-regular">QR Code</a>
                        </li>
                    </ul>
                </div>
            </div>
            </header>
            <main class="py-6 bg-surface-secondary">
            <div class="container-fluid max-w-screen-md vstack gap-6">
                
                <div class="card">
                <div class="card-body">
                <div class="mb-1">
                <h4 class="mb-1">Link Expiration</h4><p class="text-sm text-muted">Set Link Expiration Date</p>
                </div>

            <div class="modal-body">
                        <form onSubmit={handleSubmit} >
                        
                        
                        {/* <i className="fa fa-calender"></i> */}
                        <label for="recipient-name" class="col-form-label">&nbsp; Link Expiration Date:</label>
                        
                        <div class="input-group mb-2">
                        <Form.Group controlId="dob">
                            <Form.Control type="date" name="dob" value={date} onChange={(e) => setFormData({...formData, date:e.target.value})} placeholder="Date of Birth" />
                        </Form.Group>
                        </div>

                        <i className="fas fa-link"></i>
                        <label for="recipient-name" class="col-form-label">&nbsp; Expiration URL:</label>
                        &nbsp;
                        <Tooltip content="Visitors will be redirected here after the link expires." direction="right" delay="0">
                        <i className="fas fa-circle-info"></i>
                        </Tooltip>
                        <div class="input-group mb-2">
                        {clientSideLinkErrorMsg==='false' ? 
                            ( <input type="text" class="form-control form-control-lg is-invalid" name="link_exp" value={link_exp} onChange={handleLink} aria-describedby="basic-addon3" />) 
                            : 
                            clientSideLinkErrorMsg==='true' ?  
                            ( <input type="text" class="form-control form-control-lg is-valid" name="link_exp" value={link_exp} onChange={handleLink} aria-describedby="basic-addon3"/>) 
                              : 
                              ( <input type="text" class="form-control" id="basic-url" name="link_exp" value={link_exp} onChange={handleLink} aria-describedby="basic-addon3"/>) 
                            }
                        
                        </div>

                      
                        {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                        {successMsg && showSuccessMsg(successMsg)}
                    <div class="modal-footer pt-3 justify-content-between">
                        <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Change</button>
                    </div>
                    </form>
                    </div>
               
            </div>
            
            </div>
            </div>
                            
                  
                    </main>
                
            </Fragment>
        );
        
    };

export default UEditExpiration;