import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import {showErrorMsg, showSuccessMsg, showErrorInfo, showSuccessInfo} from '../helpers/message';
import { reset, resetpassword} from "../api/auth";
import { isAuthenticated } from "../helpers/auth";
import { useParams } from "react-router-dom";
import { showLoading, showLoadingBtn} from "../helpers/loading";
import isEmpty from 'validator/lib/isEmpty';
import equals from 'validator/lib/equals';

const ResetPassword = () => {

    let history = useHistory();

    const { token } = useParams();
    //const durum = false; 

    useEffect(() => {
        if(isAuthenticated() && isAuthenticated().role === 1) {
            history.push('/admin/dashboard/');
        } else if (isAuthenticated() && isAuthenticated().role === 0){
            history.push('/user/dashboard/');
        }
    },[history]);

    const [formData, setFormData] = useState({
        password:'',
        password2:'',
        durum:false,
        loading:false,
        successMsg: false,
        errorMsg: false
    });

    const {password,password2,durum,loading,successMsg, errorMsg } = formData;


    useEffect(() => {
        // GET request using axios inside useEffect React hook
        reset(token)
        .then(response => {
            console.log('reset success',response);
            setFormData({
                ...formData,
                successMsg: response.data.successMessage,
                token:token,
                durum:response.data.form_durum,
             });
        })
        .catch(err => {
            console.log('reset api error:', err);
            setFormData({
                ...formData,
                errorMsg: err.response.data.errorMessage,
                durum:err.response.data.form_durum,
            });
        })
    
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);


    console.log('form data', formData);

        // EVENT HANDLER

        const handleChange = (evt) => {
            //console.log(evt);
    
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
                errorMsg: '',
                successMsg: '',    
            });
        } 

        const handleSubmit = (evt) => {
            evt.preventDefault();
    
            // client-side validation
            
            if(isEmpty(password) || isEmpty(password2)) {
                
                setFormData({
                    ...formData, errorMsg: 'All fields are required',
                });
     
            } else if (!equals(password,password2)) {
                setFormData({
                    ...formData, errorMsg: 'Password do not match',
                });
            } else {
    
                const {password, token} = formData;
                const data = {password, token};
    
                setFormData({...formData, loading: true});
                console.log(token);
                resetpassword(data,token)
                    .then(response => {
                        console.log('Axios signup success',response);
                        setFormData({
                            password: '',
                            password2: '',
                            loading: false,
                            successMsg: response.data.successMessage,
                            durum:response.data.form_durum,
                         });
                    })
                    .catch(err => {
                        console.log('axios signup error:' , err);
                        setFormData({...formData, loading: false, durum:err.response.data.form_durum, errorMsg: err.response.data.errorMessage});
                    })
    
            }
    
            //console.log(formData);
        };




            //WIEWS
            const showResetForm = () => (

                <div className="content-area login-form">
                <img src="images/logo.png" alt="" className="login-logo"/>
                <form onSubmit={handleSubmit} noValidate>
                    <h2 className="form-title">Reset Your Password</h2>
                    
                    <div className="input-group">
                        <label for="">Password</label>
                        <input type="password" name="password" value={password} onChange={handleChange} placeholder="New Password" required="required"/>
                    </div>

                    <div className="input-group">
                        <label for="">Password</label>
                        <input type="password" name="password2" value={password2} onChange={handleChange} placeholder="Re-type New Password" required="required"/>
                    </div>

                    {errorMsg && showErrorMsg(errorMsg)}
                    {successMsg && showSuccessMsg(successMsg)}
                    
                    <div className="button-group">
                        {
                        loading ? (<div className='text-center'>{showLoadingBtn()}</div>) : (
                        <input type="submit" name="" id="" value="Reset Password" />
                        )
                        }
                        
                    </div>
                </form>
                </div>
            );

            const showInfoMsg = () => {


            }

    
                // RENDER
                return (
        
                    <section class="centered">
                        {loading && <div className='text-center'>{showLoading()}</div>}
                        {durum && showResetForm()}
                        {!durum && errorMsg && showErrorInfo(errorMsg)}
                        {!durum && successMsg && showSuccessInfo(successMsg)}
                    </section>
            
                );


}


export default ResetPassword;