import {SHOW_ERROR_MESSAGES, SHOW_SUCCESS_MESSAGES, CLEAR_MESSAGES} from '../constants/messageConstants';

const INITIAL_STATE = {
    successMsg:'',
    errorMsg:''
}

const messageReducer = (state=INITIAL_STATE, action) => {

    switch (action.type){
        case SHOW_SUCCESS_MESSAGES:
            return{
                ...state,
                successMsg: action.payload
            };
        case SHOW_ERROR_MESSAGES:
            return {
                ...state,
                errorMsg: action.payload
            };
        case CLEAR_MESSAGES:
            return {
                successMsg:'',
                errorMsg: ''
            };
            default:
                return state;
    }

};

export default messageReducer;