import axios from 'axios';
import { START_LOADING, STOP_LOADING} from '../constants/loadingConstants';
import {SHOW_ERROR_MESSAGES, SHOW_SUCCESS_MESSAGES} from '../constants/messageConstants';
import {CREATE_LINK, GET_LINKS, DELETE_LINK, GET_LINK, CREATE_CLICK,GET_TEKIL, GET_LINKS_BYDATE} from '../constants/linkConstants';


export const createLink = formData => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.post('/api/link', formData);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_SUCCESS_MESSAGES, payload: response.data.successMessage});
        dispatch({type: CREATE_LINK, payload: response.data.link});
        
    } catch(err) {
        console.log('create link api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const createClick = formData => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.post('/api/link/createClick', formData);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_SUCCESS_MESSAGES, payload: response.data.successMessage});
        dispatch({type: CREATE_CLICK, payload: response.data.click});
        
    } catch(err) {
        console.log('create Click api error', err);
        dispatch({type: STOP_LOADING});
        if(err && err.response && err.response.data && err.response.data.errorMessage){
            // todo
            dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
         }
        
    }
};

export const getLinks = (userId) => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.get(`/api/link/all/${userId}`);
        dispatch({type: STOP_LOADING});
        dispatch({type: GET_LINKS, payload: response.data.links});

    } catch(err) {
        console.log('get links api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const getLinksbyDate = linkFormData => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.post('/api/link/allbydate', linkFormData);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_SUCCESS_MESSAGES, payload: response.data.successMessage});
        dispatch({type: GET_LINKS_BYDATE, payload: response.data.links});
        
    } catch(err) {
        console.log('links by date link api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const getLinksByCount = () => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.get('/api/link/count');
        dispatch({type: STOP_LOADING});
        dispatch({type: GET_LINKS, payload: response.data.links});

    } catch(err) {
        console.log('get products api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const getLink = (linkId) => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.get(`/api/link/${linkId}`);
        dispatch({type: STOP_LOADING});
        dispatch({type: GET_LINK, payload: response.data});
    } catch(err) {
        console.log('get links api error', err);
        dispatch({type: STOP_LOADING});
        if(err && err.response && err.response.data && err.response.data.errorMessage){
            // todo
            dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
         }
    }
};

export const getShortLink = (short) => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.get(`/api/link/getshort/${short}`);
        dispatch({type: STOP_LOADING});
        dispatch({type: GET_LINK, payload: response.data});

    } catch(err) {
        console.log('get links api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const deleteLink = linkId => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.delete(`/api/link/${linkId}`);
        dispatch({type: STOP_LOADING});
        console.log(linkId);
        console.log(response);
        dispatch({type: DELETE_LINK, payload: response.data});

    } catch(err) {
        console.log('delete link api error', err);
        dispatch({type: STOP_LOADING});
        if(err && err.response && err.response.data && err.response.data.errorMessage){
            // todo
            dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
         }
        //dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};
