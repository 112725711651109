import { START_LOADING, STOP_LOADING} from '../constants/loadingConstants';
import {SHOW_ERROR_MESSAGES, SHOW_SUCCESS_MESSAGES} from '../constants/messageConstants';
import {GET_CATEGORIES, CREATE_CATEGORY} from '../constants/categoryConstants';
import axios from 'axios';


export const getCategories = () => async dispatch => {

    try {
        dispatch({type: START_LOADING});
        const response = await axios.get('/api/category');
        dispatch({type: STOP_LOADING});
        dispatch({type:GET_CATEGORIES, payload: response.data.categories});
    } catch(err) {
        console.log('get categories api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }

};

export const createCategory = formData => async dispatch => {

    try {
        const config = {
            header: {
                'Content-Type': 'application/json'
            }
        }
        dispatch({type: START_LOADING});
        const response = await axios.post('/api/category', formData, config);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_SUCCESS_MESSAGES, payload: response.data.successMessage});
        dispatch({type:CREATE_CATEGORY, payload: response.data.category});
    }
    catch( err) {
        console.log('create category api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }

};

