import React, {useEffect} from "react";

//COMPONENTS
import UserHeader from './UserHeader';
import UserActionBtns from './UserActionBtns';
import UserLinkModal from './UserLinkModal';
import UDashboard from './UDashboard';
//REDUX
import {useDispatch} from 'react-redux';
//import {getCategories} from '../redux/actions/categoryActions';
import {getLinks} from '../redux/actions/linkActions';

const UserDashboard = () => {


    const dispatch = useDispatch();

    // const [userId, setUserId] = React.useState(
    //     JSON.parse(localStorage.getItem('user'))
    //   );

    const userid = JSON.parse(localStorage.getItem('user'));
    const userId= userid._id;

    // useEffect(() => {
    //     dispatch(getCategories());
    // }, [dispatch]);

    useEffect(() => {
        dispatch(getLinks(userId));
    }, [dispatch]);

    // useEffect(() => {
    //     dispatch(getPages(userId));
    // }, );
    

    return (
        <section>
           {/* <UserHeader />
           <UserLinkModal />
           <UserActionBtns /> */}
           <UDashboard />
       </section>
       );

};

export default UserDashboard;