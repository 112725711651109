import React, {useState, useEffect, Fragment} from "react";
import { Link, useHistory } from "react-router-dom";
import {showErrorMsg,showSuccessMsg} from '../helpers/message';
import {showLoading} from '../helpers/loading';
import isEmpty from 'validator/lib/isEmpty';
import isUrl from 'validator/lib/isURL';
import isEmail from 'validator/lib/isEmail';
import { edittitle } from "../api/auth";
import { isAuthenticated } from "../helpers/auth";
import './Dashboard.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import QRCode from 'qrcode.react';


//redux
import {useSelector, useDispatch} from 'react-redux';
import {getLink,getLinks} from '../redux/actions/linkActions';


const UQrCode = ({match}) => {

const dispatch = useDispatch();

let history = useHistory();

//const {link} = useSelector(state=>state.links);

//PARAMS
//const linkId = match.params.linkId;

 //COMPONENT STATE PROPERTIES
 const [formData, setFormData] = useState({
    name:'',
    surname:'',
    telwork:'',
    gsm:'',
    email:'',
    job:'',
    company:'',
    adres:'',
    city:'',
    country:'',
    state:'',
    web:'',
    str_start:'BEGIN:VCARD\nVERSION:3.0\n',
    str_vcard:'BEGIN:VCARD\nVERSION:3.0\n',
    str_end:'\nEND:VCARD',
    goog_chart:'http://chart.googleapis.com/chart?cht=qr&chs=400x400&chl=',
    successMsg: false,
    errorMsg: false,
    loading: false  
});

const [smsData, setSMSData] = useState({
    smstel:'',
    smstext:''
});

const [mailData, setMailData] = useState({
    mailadres:'',
    mailsubject:'',
    mailmessage:''
});

const {name, surname,telwork, gsm, email, job, company, adres, city, state, country, web, str_start,str_vcard,str_end,goog_chart, successMsg, errorMsg, loading } = formData;

const {smstel, smstext} = smsData;

const {mailadres, mailmessage, mailsubject} = mailData;

//const [shortUrl, setShortUrl] = useState('');


 //LIFECYCLE METHODS
 

// useEffect(() => {
//     if(isAuthenticated() && isAuthenticated().role === 1) {
//         history.push('/admin/dashboard/');
//     } else if (isAuthenticated() && isAuthenticated().role === 0){
//         history.push('/user/dashboard/');
//     }
// },[history]);



 // COMPONENT GLOBAL STATE PROPERTIES 


const [clientSideErrorMsg,setClientSideErrorMsg] = useState('');
const [clientSideSurlErrorMsg,setClientSideSurlErrorMsg] = useState('');
const [clientSideSurlMsg,setClientSideSurlMsg] = useState('');
const [clientSideSucMsg,setClientSideSucMsg] = useState('');
const [modalState,setModalState] = useState(false);
const [str_state,setStrState] = useState(false);
const [submit_state,setSubmitState] = useState(false);
const [showModal, setShowModal] = useState(false);
const handleQRShow = () => setShowModal(true);
const handleQRClose = () => setShowModal(false);

const [freetext,setFreeText] = useState('');
const [phone,setPhone] = useState('');
const [qrstr,setQRStr] = useState('');

useEffect(() => {
    console.log('hello-2')

    // if(str_state) {
    //     setFormData({
    //         ...formData,
    //         goog_chart: goog_chart+str_vcard.replace(/\n/g,'%0A')
    //     })

        //setQRStr(str_vcard)
        //handleQRShow()
        //setSubmitState(false)
        // setFormData({
        //     ...formData,
        //     name:'',
        //     surname:'',
        //     telwork:'',
        //     gsm:'',
        //     email:'',
        //     job:'',
        //     company:'',
        //     adres:'',
        //     city:'',
        //     country:'',
        //     state:'',
        //     web:'',
        // })
        
    //}

}, [submit_state])

useEffect(() => {
    
    setQRStr(str_vcard)
       

}, [str_vcard])


        // EVENT HANDLER

        const downloadQR = () => {
            const canvas = document.getElementById("4545");
            const pngUrl = canvas
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `vcard-qr-code.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          };

        const handleChange = (evt) => {
            //console.log(evt);
    
            

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            }  if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            }  if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            } 
            
        } ;

        const handleName = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });

            
            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleSurname = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            

            if (isEmpty(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;
        const handleGsm = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            

            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleTel = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            

            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleEmail = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            if (!isEmail(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            } 
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleJob = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleCompany = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleAdres = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleCity = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleCountry = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleState = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleWeb = (evt) => {

            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            });
            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleSmsTel = (evt) => {

            setSMSData({
                ...smsData,
                [evt.target.name]: evt.target.value,
            });
            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;
        const handleSmsText = (evt) => {

            setSMSData({
                ...smsData,
                [evt.target.name]: evt.target.value,
            });
            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleMailAdres = (evt) => {

            setMailData({
                ...mailData,
                [evt.target.name]: evt.target.value,
            });
            
            if(!isEmail(evt.target.value)){
                setClientSideSurlErrorMsg('false');
            }

            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleMailSubject = (evt) => {

            setMailData({
                ...mailData,
                [evt.target.name]: evt.target.value,
            });

            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleMailMessage = (evt) => {

            setMailData({
                ...mailData,
                [evt.target.name]: evt.target.value,
            });

            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleFreeText = (evt) => {

            setFreeText(evt.target.value);
            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;
   
        const handlePhone = (evt) => {

            setPhone(evt.target.value);
            
            if (!isEmpty(evt.target.value)){
                //setClientSideSurlErrorMsg('true');
                setClientSideSurlMsg('');
                setClientSideSurlErrorMsg('');
            } 
            
        } ;

        const handleMessages = (evt) => {
            

            setClientSideSurlErrorMsg('');
            setClientSideSurlMsg('');
            setClientSideErrorMsg('');

            setModalState(false);

        };

        const handleCreateClick = (evt) => {
            

            setModalState(true);

        };


        const handleBlur = (evt) => {
            //console.log(evt.target.value.length);
            // setFormData({
            //     ...formData,
            //     [evt.target.name]: evt.target.value,
            //     errorMsg: '',
            //     successMsg: '',    
            // });

            if (isUrl(evt.target.value)){
                setClientSideErrorMsg('true');
            } else if (!isUrl(evt.target.value)) {
                setClientSideErrorMsg('false');
            } else if (isEmpty(evt.target.value)){
                setClientSideErrorMsg('false');
            }
    
        } 

        const handleVcardSubmit = async (evt) => {
            evt.preventDefault();

        // client-side validation

        // if(isEmpty(name)) {
        //     setClientSideSurlMsg('All fields requried');

        // } 
            //console.log(formData)
        setSubmitState(true)
        
        if(!isEmpty(name)) {
                console.log('hello')
            setFormData({
                ...formData,
                str_vcard: str_start + 'N:'+surname+';'+name+'\n'+'FN:'+name+' '+surname + '\nTEL;TYPE=home:'+ gsm + '\nTEL;TYPE=work:'+telwork + '\nEMAIL;TYPE=internet,work:'+email +
                '\nORG:'+company + '\nTITLE:'+job + '\nADR;TYPE=work:;;'+adres+';'+city+';'+state+
                ';;'+country+'\nURL;TYPE=work:'+web+str_end,
                
            })
            //setQRStr(str_vcard)
            handleQRShow()
            setStrState(true);
        }



        }

        const handleFreeTextSubmit = async (evt) => {
            evt.preventDefault();
        
        if(!isEmpty(freetext)) {
                
            setQRStr(freetext)
            handleQRShow()
            
        }



        }

        const handleSmsSubmit = async (evt) => {
            evt.preventDefault();
        
        if(!isEmpty(smstel)) {
                
            setQRStr('sms:'+smstel+'?&body='+smstext)
            handleQRShow()
            
        }



        }

        const handleEmailSubmit = async (evt) => {
            evt.preventDefault();
        
        if(!isEmpty(mailadres) || !isEmpty(mailmessage) || !isEmpty(mailsubject)) {
                
            setQRStr('mailto:'+mailadres+'?subject='+mailsubject+'&body='+mailmessage)
            handleQRShow()
            }
        }

        const handlePhoneSubmit = async (evt) => {
            evt.preventDefault();
        
        if(!isEmpty(phone)) {
            setQRStr('tel:'+phone)
            handleQRShow()
            }
        }


        const protocol = window.location.protocol;
        const domain = window.location.hostname;
        const port = window.location.port;
        const baseurl = `${protocol}//${domain}${port? port : ""}`
    

        return (
            <Fragment>
            <main role="main" className="container">
                <div className="jumbotron">
                    {/* <h1>Navbar example</h1> */}
                    {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#addLinkModal">Create Link</button> */}

                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                        <h1 class="h2">QR Code Generator</h1>
                    </div>
                    
                    {/* <p className="lead">This example is a quick exercise to illustrate how fixed to top navbar works. As you scroll, it will remain fixed to the top of your browser's viewport.</p> */}
                    <div class="form-group">
                   
                    </div>
                </div>

                
            <div className='row pb-3'>

                <Tab.Container id="left-tabs-example" defaultActiveKey="vcard">
                <Row>
                    <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                        <Nav.Link eventKey="vcard">vCard</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="text">Free Text</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="sms">SMS</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="email">E-mail</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="phone">Phone</Nav.Link>
                        </Nav.Item>
                        
                    </Nav>
                    </Col>
                    <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="vcard">
                        <h2>vCard Generator</h2>
                        <div class="modal-body">
                            <form onSubmit={handleVcardSubmit} >
                            <div class="input-group mb-3">
                            {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="name" value={name} onChange={handleName} aria-describedby="basic-addon3" placeholder="First Name"/>) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="name" value={name} onChange={handleName} aria-describedby="basic-addon3" placeholder="First Name"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="name" value={name} onChange={handleName} aria-describedby="basic-addon3" placeholder="First Name"/>) 
                                }
                                {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="surname" value={surname} onChange={handleSurname} aria-describedby="basic-addon3" placeholder="Last Name" />) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="surname" value={surname} onChange={handleSurname} aria-describedby="basic-addon3" placeholder="Last Name"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="surname" value={surname} onChange={handleSurname} aria-describedby="basic-addon3" placeholder="Last Name"/>) 
                                }
                            
                            </div>
                            <div class="input-group mb-3">
                            
                            {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="gsm" value={gsm} onChange={handleGsm} aria-describedby="basic-addon3" placeholder="Mobile Phone" />) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="gsm" value={gsm} onChange={handleGsm} aria-describedby="basic-addon3" placeholder="Mobile Phone"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="gsm" value={gsm} onChange={handleGsm} aria-describedby="basic-addon3" placeholder="Mobile Phone"/>) 
                                }
                                {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="telwork" value={telwork} onChange={handleTel} aria-describedby="basic-addon3" placeholder="Work Phone"/>) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="telwork" value={telwork} onChange={handleTel} aria-describedby="basic-addon3" placeholder="Work Phone"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="telwork" value={telwork} onChange={handleTel} aria-describedby="basic-addon3" placeholder="Work Phone"/>) 
                                }
                            
                            </div>
                            <div class="input-group mb-3">
                            
                            {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="email" value={email} onChange={handleEmail} aria-describedby="basic-addon3" placeholder="E-mail" />) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="email" value={email} onChange={handleEmail} aria-describedby="basic-addon3" placeholder="E-mail"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="email" value={email} onChange={handleEmail} aria-describedby="basic-addon3" placeholder="E-mail"/>) 
                                }
                            
                            </div>
                            <div class="input-group mb-3">
                            {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="company" value={company} onChange={handleCompany} aria-describedby="basic-addon3" placeholder="Company" />) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="company" value={company} onChange={handleCompany} aria-describedby="basic-addon3" placeholder="Company"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="company" value={company} onChange={handleCompany} aria-describedby="basic-addon3" placeholder="Company"/>) 
                                }
                                {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="job" value={job} onChange={handleJob} aria-describedby="basic-addon3" placeholder="Job"/>) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="job" value={job} onChange={handleJob} aria-describedby="basic-addon3" placeholder="Job"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="job" value={job} onChange={handleJob} aria-describedby="basic-addon3" placeholder="Job"/>) 
                                }
                            
                            </div>
                            <div class="input-group mb-3">
                            {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="adres" value={adres} onChange={handleAdres} aria-describedby="basic-addon3" placeholder="Address" />) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="adres" value={adres} onChange={handleAdres} aria-describedby="basic-addon3" placeholder="Address"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="adres" value={adres} onChange={handleAdres} aria-describedby="basic-addon3" placeholder="Address"/>) 
                                }
                            </div>
                            <div class="input-group mb-3">
                            {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="city" value={city} onChange={handleCity} aria-describedby="basic-addon3" placeholder="City" />) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="city" value={city} onChange={handleCity} aria-describedby="basic-addon3" placeholder="City"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="city" value={city} onChange={handleCity} aria-describedby="basic-addon3" placeholder="City"/>) 
                                }
                                {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="state" value={state} onChange={handleState} aria-describedby="basic-addon3" placeholder="State"/>) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="state" value={state} onChange={handleState} aria-describedby="basic-addon3" placeholder="State"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="state" value={state} onChange={handleState} aria-describedby="basic-addon3" placeholder="State"/>) 
                                }

                                {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="country" value={country} onChange={handleCountry} aria-describedby="basic-addon3" placeholder="Country"/>) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="country" value={country} onChange={handleCountry} aria-describedby="basic-addon3" placeholder="Country"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="country" value={country} onChange={handleCountry} aria-describedby="basic-addon3" placeholder="Country"/>) 
                                }
                            
                            </div>
                            <div class="input-group mb-3">
                            {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="web" value={web} onChange={handleWeb} aria-describedby="basic-addon3" placeholder="Website" />) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="web" value={web} onChange={handleWeb} aria-describedby="basic-addon3" placeholder="Website"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="web" value={web} onChange={handleWeb} aria-describedby="basic-addon3" placeholder="Website"/>) 
                                }
                            </div>
                            {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                            {successMsg && showSuccessMsg(successMsg)}
                        <div class="modal-footer">
                            <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Generate</button>
                        </div>
                            </form>
                         </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="text">
                        <h2>Free Text Generator</h2>
                        <div class="modal-body">
                            <form onSubmit={handleFreeTextSubmit} >
                            <div class="input-group mb-3">
                            {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="textarea" class="form-control form-control-lg is-invalid" name="freetext" value={freetext} onChange={handleFreeText} aria-describedby="basic-addon3" placeholder="Type free text"/>) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="textarea" class="form-control form-control-lg is-valid" name="freetext" value={freetext} onChange={handleFreeText} aria-describedby="basic-addon3" placeholder="Type free text"/>) 
                                : 
                                ( <input type="textarea" class="form-control" id="basic-url" name="freetext" value={freetext} onChange={handleFreeText} aria-describedby="basic-addon3" placeholder="Type free text"/>) 
                                }
                            
                            </div>
                            {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                            {successMsg && showSuccessMsg(successMsg)}
                        <div class="modal-footer">
                            <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Generate</button>
                        </div>
                            </form>
                         </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="sms">
                        <h2>SMS Generator</h2>
                        <div class="modal-body">
                            <form onSubmit={handleSmsSubmit} >
                            <div class="input-group mb-3">
                            {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="tel"  class="form-control form-control-lg is-invalid" name="smstel" value={smstel} onChange={handleSmsTel} aria-describedby="basic-addon3" placeholder="Phone number"/>) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="tel"  class="form-control form-control-lg is-valid" name="smstel" value={smstel} onChange={handleSmsTel} aria-describedby="basic-addon3" placeholder="Phone number"/>) 
                                : 
                                ( <input type="tel" class="form-control" id="basic-url" name="smstel" value={smstel} onChange={handleSmsTel} aria-describedby="basic-addon3" placeholder="Phone number"/>) 
                                }
                            
                            </div>
                            <div class="input-group mb-3">
                            {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="smstext" value={smstext} onChange={handleSmsText} aria-describedby="basic-addon3" placeholder="Type message"/>) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="smstext" value={smstext} onChange={handleSmsText} aria-describedby="basic-addon3" placeholder="Type message"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="smstext" value={smstext} onChange={handleSmsText} aria-describedby="basic-addon3" placeholder="Type message"/>) 
                                }
                            
                            </div>
                            {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                            {successMsg && showSuccessMsg(successMsg)}
                        <div class="modal-footer">
                            <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Generate</button>
                        </div>
                            </form>
                         </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="email">
                        <h2>E-mail Generator</h2>
                        <div class="modal-body">
                            <form onSubmit={handleEmailSubmit} >
                            <div class="input-group mb-3">
                            {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="mailadres" value={mailadres} onChange={handleMailAdres} aria-describedby="basic-addon3" placeholder="E-mail address"/>) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="mailadres" value={mailadres} onChange={handleMailAdres} aria-describedby="basic-addon3" placeholder="E-mail address"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="mailadres" value={mailadres} onChange={handleMailAdres} aria-describedby="basic-addon3" placeholder="E-mail address"/>) 
                                }
                            
                            </div>
                            <div class="input-group mb-3">
                            {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="mailsubject" value={mailsubject} onChange={handleMailSubject} aria-describedby="basic-addon3" placeholder="E-mail subject"/>) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="mailsubject" value={mailsubject} onChange={handleMailSubject} aria-describedby="basic-addon3" placeholder="E-mail subject"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="mailsubject" value={mailsubject} onChange={handleMailSubject} aria-describedby="basic-addon3" placeholder="E-mail subject"/>) 
                                }
                            
                            </div>
                            <div class="input-group mb-3">
                            {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="text" class="form-control form-control-lg is-invalid" name="mailmessage" value={mailmessage} onChange={handleMailMessage} aria-describedby="basic-addon3" placeholder="Message"/>) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="text" class="form-control form-control-lg is-valid" name="mailmessage" value={mailmessage} onChange={handleMailMessage} aria-describedby="basic-addon3" placeholder="Message"/>) 
                                : 
                                ( <input type="text" class="form-control" id="basic-url" name="mailmessage" value={mailmessage} onChange={handleMailMessage} aria-describedby="basic-addon3" placeholder="Message"/>) 
                                }
                            
                            </div>
                            {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                            {successMsg && showSuccessMsg(successMsg)}
                        <div class="modal-footer">
                            <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Generate</button>
                        </div>
                            </form>
                         </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="phone">
                        <h2>Phone Generator</h2>
                        <div class="modal-body">
                            <form onSubmit={handlePhoneSubmit} >
                            <div class="input-group mb-3">
                            {clientSideSurlErrorMsg==='false' ? 
                                ( <input type="textarea" class="form-control form-control-lg is-invalid" name="phone" value={phone} onChange={handlePhone} aria-describedby="basic-addon3" placeholder="Phone number"/>) 
                                : 
                                clientSideSurlErrorMsg==='true' ?  
                                ( <input type="textarea" class="form-control form-control-lg is-valid" name="phone" value={phone} onChange={handlePhone} aria-describedby="basic-addon3" placeholder="Phone number"/>) 
                                : 
                                ( <input type="textarea" class="form-control" id="basic-url" name="phone" value={phone} onChange={handlePhone} aria-describedby="basic-addon3" placeholder="Phone number"/>) 
                                }
                            
                            </div>
                            {clientSideSurlMsg && showErrorMsg(clientSideSurlMsg)}
                            {successMsg && showSuccessMsg(successMsg)}
                        <div class="modal-footer">
                            <button type='submit' name="submit" value="submit" id="submit" class="btn btn-primary btn-lg">Generate</button>
                        </div>
                            </form>
                         </div>
                        </Tab.Pane>
                        
                    </Tab.Content>
                    </Col>
                </Row>
                </Tab.Container>
               
            </div>
                
                
                {/* // QR Code Link Modal */}

                <Modal show={showModal} onHide={handleQRClose} 
                aria-labelledby="contained-modal-title-vcenter"
                centered >
                    <Modal.Header closeButton>
                    <Modal.Title>QR Code</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div class="text-center">
                        <QRCode
                        id="4545"
                        value={qrstr}
                        size={400}
                        level={"H"}
                        includeMargin={true}
                    />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleQRClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={downloadQR}>
                        Download QR
                    </Button>
                    </Modal.Footer>
                </Modal>
                  
                    </main>
                
            </Fragment>
        );
        
    };

export default UQrCode;