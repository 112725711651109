import {combineReducers, applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import loadingReducer from './reducers/loadingReducers';
import messageReducer from './reducers/messageReducers';
import categoryReducer from './reducers/categoryReducers';
import productReducer from './reducers/productReducers';
import filterReducer from './reducers/filterReducers';
import cartReducer from './reducers/cartReducers';
import adminReducer from './reducers/adminReducers';
import linkReducer from './reducers/linkReducers';
import pageReducer from './reducers/pageReducers';


const reducer = combineReducers({
    loading: loadingReducer,
    messages: messageReducer,
    categories: categoryReducer,
    products: productReducer,
    links: linkReducer,
    pages: pageReducer,
    filters: filterReducer,
    cart: cartReducer,
    adminusers: adminReducer,
    total_user_count: adminReducer,
    current_page: adminReducer,
});

const initialState  = {} ;

const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
