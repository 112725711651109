import axios from 'axios';
import { START_LOADING, STOP_LOADING} from '../constants/loadingConstants';
import {SHOW_ERROR_MESSAGES, SHOW_SUCCESS_MESSAGES} from '../constants/messageConstants';
import {GET_PRODUCTS, CREATE_PRODUCT, DELETE_PRODUCT, GET_PRODUCT} from '../constants/productConstants';


export const createProduct = formData => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.post('/api/product', formData);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_SUCCESS_MESSAGES, payload: response.data.successMessage});
        dispatch({type: CREATE_PRODUCT, payload: response.data.product});
        
    } catch(err) {
        console.log('create product api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const getProducts = () => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.get('/api/product');
        dispatch({type: STOP_LOADING});
        dispatch({type: GET_PRODUCTS, payload: response.data.products});

    } catch(err) {
        console.log('get products api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const getProductsByCount = () => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.get('/api/product/count');
        dispatch({type: STOP_LOADING});
        dispatch({type: GET_PRODUCTS, payload: response.data.products});

    } catch(err) {
        console.log('get products api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const getProduct = (productId) => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.get(`/api/product/${productId}`);
        dispatch({type: STOP_LOADING});
        dispatch({type: GET_PRODUCT, payload: response.data});

    } catch(err) {
        console.log('get products api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};

export const deleteProduct = productId => async dispatch => {
    
    try {
        dispatch({type:START_LOADING});
        const response = await axios.delete(`/api/product/${productId}`);
        dispatch({type: STOP_LOADING});
        dispatch({type: DELETE_PRODUCT, payload: response.data});

    } catch(err) {
        console.log('delete products api error', err);
        dispatch({type: STOP_LOADING});
        dispatch({type: SHOW_ERROR_MESSAGES, payload: err.response.data.errorMessage});
    }
};