import React from "react";

const NotFound = () => {


    return (

        <p>Inside NotFound component </p>

    );

}

export default NotFound;